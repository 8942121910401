import "./App.css"
import "./utils/utils.css"
import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import { useSession } from "./Hooks/UseSession"
import { useShoppingCart } from "./Hooks/UseShoppingCart"
import { ProtectedRoutes, StaticRoutes } from "./Routes"
import { useColorMode, LIGHT_MODE } from "react-darkmode-hook"
import { useProjectData } from "./Hooks/UseProjectData"
import { Message } from "./Components"

/**
 * SessionContext can be destructured to { session, setSession } with React.useContext()
 */
export const SessionContext = createContext()
export const ShoppingCartContext = createContext()
export const IsMobileContext = createContext()
/**
 * @param counter: an integer
 * @param refreshGridData: a reusable function with no args to trigger a rerender that increments a counter state
 * @param gridData
 * @param setGridData
 * @param projectName: gridData?.project_name
 * @param projectOwner: gridData?.owner || gridData?.email
 */
export const ProjectContext = createContext()

/**
 * @param {string | JSX.Element} globalMessage a React component to be displayed at the top of the page. can be jsx or a string
 */
const App = ({globalMessage}) => {
  // console.log(process.env.NODE_ENV)
  const { session, setSession } = useSession()
  const { shoppingCart, setShoppingCart } = useShoppingCart()
  const { setColorMode } = useColorMode()
  const { projectData, setProjectData } = useProjectData()
  const [isMobile, setIsMobile] = useState(false)
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState({ visible: true })
  const viewportWidth = useRef()

  const resizeHandler = () => {
    const width = window.innerWidth
    setIsMobile(width < 900)
    viewportWidth.current = width
  }

  const setCounter = useCallback(() => {
    setCount((count) => count + 1)
  }, [])

  useEffect(() => {
    setColorMode(LIGHT_MODE)
  })

  useEffect(() => {
    ;/mobile/gi.test(navigator.userAgent) && setIsMobile(true)
    viewportWidth.current = window.innerWidth
    window.addEventListener("resize", resizeHandler)
    return () => window.removeEventListener("resize", resizeHandler)
  }, [])

  return (
    <>
      {globalMessage && <section className="w-full relative" style={{ zIndex: 2 }}>
        <Message
          state={[visible, setVisible]}
          className="w-100 mt-0 py-64 border-radius--none"
          kind="info"
          message={globalMessage}
        />
        <div
          className="w-full bg-true-white"
          style={{
            position: "absolute",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></div>
      </section>}
      <SessionContext.Provider value={{ session, setSession }}>
        <ShoppingCartContext.Provider value={{ shoppingCart, setShoppingCart }}>
          <IsMobileContext.Provider
            value={{ isMobile, width: viewportWidth.current }}
          >
            <ProjectContext.Provider
              value={{
                counter: count,
                refreshGridData: setCounter,
                gridData: projectData,
                setGridData: setProjectData,
                projectName: projectData?.project_name,
                projectOwner: projectData?.owner || projectData?.email,
              }}
            >
              {session?.token ? <ProtectedRoutes /> : <StaticRoutes />}
            </ProjectContext.Provider>
          </IsMobileContext.Provider>
        </ShoppingCartContext.Provider>
      </SessionContext.Provider>
    </>
  )
}

export default App
