import React, { useContext } from "react"
import { Button, DialogBox, NewProjectModal } from "../../../Components"
import { useHistory } from "react-router-dom"
import { ModalContext } from "../../ProjectWorkspace"
import "./DashboardPage.css"
import { useTitle } from "../../../Hooks/UseTitle"

const DashboardPage = ({ name, ...props }) => {
  const { setModalHidden } = useContext(ModalContext)
  let history = useHistory()
  useTitle("Soulfolio Dashboard")
  const newProjectClickHandler = (e) => setModalHidden(false)
  const currentProjectClickHandler = (e) => history.push("/design/my-projects")
  const myImagesClickHandler = (e) => history.push("/design/my-images")
  return (
    <>
      <NewProjectModal />
      <DialogBox header={`Welcome back${name && `, ${name}!`}`}>
        <div className="card bg-true-white p-24 mb-16">
          <h3 className="color-gray--50 regular center-text mb-48">
            Let's get started
          </h3>
          <section className="flex md:flex-direction--row sm:flex-direction--column align-items--center justify-content--space-around">
            <Button onClick={newProjectClickHandler} className="mb-16 sm:w-60">
              New Project
            </Button>
            <Button onClick={currentProjectClickHandler} className="mb-16 sm:w-60">
              Current Projects
            </Button>
            {/* <Button className="mb-16 sm:w-60" onClick={myImagesClickHandler}>
              My Images
            </Button> */}
          </section>
        </div>
        {/* <div className="card bg-true-white p-24 my-16">
          <section className="flex flex-direction--column justify-content--center">
            <h3 className="center-text regular color-gray--50">
              Check out the Founder Video
            </h3>
            <video
              className="App-dashboard--video"
              controls
              src="/Soulfolioco.mp4"
              type="video/mp4"
              poster="/soulfolio-video-poster.png"
            ></video>
          </section>
        </div> */}
      </DialogBox>
    </>
  )
}

export { DashboardPage }
