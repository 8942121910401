import React from 'react'
import { IconContainer } from './IconContainer'

const SuccessIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size} >
      <path fill={ fill } d="M50.6,5.2C25,5.2,4.2,26,4.2,51.7S25,98.1,50.6,98.1s46.5-20.8,46.5-46.5S76.3,5.2,50.6,5.2z M75.2,41L46.8,69.4
	c-1.1,1.1-2.5,1.7-4.1,1.7s-3-0.6-4.1-1.7L26,56.9c-0.9-0.9-1.5-2.2-1.5-3.4c0-1.2,0.4-2.3,1.3-3.1l0.9-0.9c1.8-1.8,4.6-1.7,6.5,0.2
	l9.4,9.4L68,33.8c1.9-1.9,4.7-2,6.5-0.2l0.9,0.9c0.8,0.8,1.3,1.9,1.3,3.1C76.6,38.8,76.1,40,75.2,41z"/>
    </IconContainer>
  )
}

export { SuccessIcon }