import React from 'react'
import { IconContainer } from './IconContainer'

const AddToCartIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <path d="M96.3,4.5h-1.9c-3.6,0-6.7,2.4-7.5,5.9l-1,4.2c-1.1-0.5-2.3-0.7-3.6-0.5L7.4,20.2c-2.3,0.2-4.4,1.4-5.8,3.3
        c-1.3,1.9-1.7,4.3-1,6.6l10,34c1,3.3,4,5.6,7.4,5.6h55c0.5,0,1-0.1,1.5-0.1c0,0,0,0.1,0.1,0.1l2.9,4.6c0.2,0.4,0.1,0.8,0,1
        c-0.1,0.2-0.4,0.5-0.9,0.5H3.4c-1.6,0-3,1.3-3,3v0.8c0,1.6,1.3,3,3,3h8.4c-0.4,1.1-0.6,2.2-0.6,3.4c0,5.3,4.3,9.6,9.6,9.6
        s9.6-4.3,9.6-9.6c0-1.2-0.2-2.4-0.6-3.4h31.6c-0.4,1.1-0.6,2.2-0.6,3.4c0,5.3,4.3,9.6,9.6,9.6s9.6-4.3,9.6-9.6
        c0-1.4-0.3-2.7-0.8-3.9c2.7-0.9,4.9-3.2,5.2-6.5c0.2-1.8-0.4-3.6-1.3-5.2l-2.6-4.3c-0.1-0.2-0.2-0.5-0.1-0.8L93.4,12
        c0.1-0.4,0.5-0.7,1-0.7h1.9c1.9,0,3.4-1.5,3.4-3.4C99.7,6,98.2,4.5,96.3,4.5z M64.6,44.8c0,1-0.8,1.8-1.8,1.8H51.2v11.7
        c0,1-0.8,1.8-1.8,1.8h-4c-1,0-1.8-0.8-1.8-1.8V46.6H32c-1,0-1.8-0.8-1.8-1.8v-4c0-1,0.8-1.8,1.8-1.8h11.7V27.4c0-1,0.8-1.8,1.8-1.8
        h4c1,0,1.8,0.8,1.8,1.8V39h11.7c1,0,1.8,0.8,1.8,1.8V44.8z M20.7,88.8c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8
        S22.3,88.8,20.7,88.8z M70.2,88.8c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S71.8,88.8,70.2,88.8z"/>
    </IconContainer>
  )
}

export { AddToCartIcon }