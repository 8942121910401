import React from "react"

const FontSizeSliderWithReadout = ({
  canEdit,
  textSize,
  defaultTextSize,
  fontSizeHandler,
  rangeVal,
}) => {
  return (
    <section className="flex flex-direction--column">
      <label className="flex" htmlFor="font-size">
        Font Size
      </label>
      <section className="flex flex-direction--row w-full mb-16">
        <input
          disabled={!canEdit}
          name="font-size"
          className="App-image-editor--range box-sizing--border-box"
          type="range"
          value={textSize || ""}
          min={12}
          max={defaultTextSize * 1.55 < 72 ? 72 : defaultTextSize * 1.55}
          step={0.1}
          onChange={fontSizeHandler}
        ></input>
        <input
          style={{
            width: "2rem",
            height: "0.5rem",
            textAlign: "center",
            margin: "0 0 0 8px",
            borderRadius: "var(--border-radius--smaller)",
          }}
          type="number"
          ref={rangeVal}
          value={textSize || ""}
          className="App-number-field"
          disabled
        ></input>
      </section>
    </section>
  )
}

export { FontSizeSliderWithReadout }
