import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { Buffer } from 'buffer';
// This global assignment ensures Buffer is available globally as it would be in Node.js
window.Buffer = Buffer;

// const globalMessage = (
//   <span className="flex flex-wrap font-size--13 sm:font-size--11 bold m-0">
//     We'll be open for business this summer! Please visit our{" "}
//     <a
//       className="faux-link combo-link color-interactive-90"
//       style={{
//         display: "flex",
//         flexWrap: "wrap",
//       }}
//       rel="noreferrer"
//       target="_blank"
//       href="https://www.kickstarter.com/projects/soulfolioco/soulfolio-help-us-spark-joy-one-person-at-a-time"
//     >
//       &nbsp;Kickstarter campaign&nbsp;
//       <LinkIcon
//         className="flex"
//         size="small"
//         style={{ fill: "currentColor" }}
//       />
//       &nbsp;
//     </a>{" "}
//     to learn more!
//   </span>
// )

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
