import React from 'react';

const FontColorPicker = ({fontColor, fontColorHandler}) => {
  return (
    <section className="flex flex-direction--row align-items--center justify-content--space-between mb-16">
      <label
        className="App-image-editor--label"
        htmlFor="font color"
      >
        Font Color
      </label>
      <input
        title="font color"
        value={fontColor.hexValue || "#000000"}
        className="App-image-editor--color-input border-radius--smaller box-sizing--border-box"
        type="color"
        onChange={fontColorHandler}
      />
    </section>
  )
}

export { FontColorPicker }