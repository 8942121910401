import React from "react"
import { IconButton, Spinner } from "../../"
import { SaveIcon } from "../../Icons"

export const SaveController = ({ clickHandler, status, constants }) => {
  return (
    <section className="flex flex-direction--column justify-content--center align-items--center">
      <IconButton
        id="save"
        onClick={clickHandler}
        disabled={status === constants.SAVING}
      >
        {status === constants.SAVING ? (
          <Spinner color="dynamic-text--inverse" size="tiny" text="" />
        ) : (
          <SaveIcon size="medium" />
        )}
      </IconButton>
      <label htmlFor="save" className="mt-8 color-gray--60">
        Save
      </label>
    </section>
  )
}
