import React from "react"
import { IconContainer } from "./IconContainer"

const AddUserIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M32.6,54.7c0-1.4-0.7-2.8-1.8-3.7c-0.8-0.6-1.9-1-3-1l-6.4,0v-6.4c0-2.7-2-4.8-4.6-4.8h-0.8c-2.6,0-4.6,2.1-4.6,4.8v6.4H4.8
	c-2.7,0-4.8,2-4.8,4.6v0.8c0,1.2,0.5,2.4,1.3,3.2c0.9,0.9,2.1,1.4,3.5,1.4h6.4v6.5c0,1.3,0.5,2.6,1.4,3.5c0.9,0.9,2,1.3,3.2,1.3h0.8
	c2.6,0,4.6-2.1,4.6-4.8v-6.5h6.4c2.7,0,4.8-2,4.8-4.6L32.6,54.7z"
      />
      <circle cx="56.3" cy="28.4" r="28.4" />
      <path d="M12.6,100c0-20.4,16.7-37.1,37.1-37.1h13.3c20.4,0,37.1,16.7,37.1,37.1v0L12.6,100L12.6,100z" />
    </IconContainer>
  )
}

export { AddUserIcon }
