import React from "react"
//import { CloseIcon } from '../Icons'
import * as Icon from "../Icons"
import "./InlineMessage.css"
import { Capitalizer } from "../../utils/Capitalizer"

const InlineMessage = ({ kind, message, className, children }) => {
  const IconName = Icon[`${Capitalizer(kind || "info")}Icon`]
  const content = (
    <>
      <section className="App-inline-message--content">
        {/info|error|warning|success|default/gi.test(kind) && (
          <div>
            <IconName size={"small"} />
          </div>
        )}
        <div>
          {message && (
            <p className={`App-inline-message--text ml-8 font-size--10`}>
              {message}
            </p>
          )}
          {children && children}
        </div>
      </section>
    </>
  )
  return (
    <div
      className={`App-inline-message App-inline-message--${
        kind ? kind : "default"
      } p-8 fade-in ${className}`}
    >
      {content}
    </div>
  )
}

export { InlineMessage }
