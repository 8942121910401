import React from "react"

const FontStrokeColorPicker = ({
  strokeColor,
  strokeColorHandler
}) => {
  return (
    <section className="flex flex-direction--row align-items--center justify-content--space-between mb-16">
      <label className="App-image-editor--label" htmlFor="stroke color">
        Stroke Color
      </label>
      <input
        title="stroke color"
        value={strokeColor}
        className="App-image-editor--color-input border-radius--smaller box-sizing--border-box"
        type="color"
        onChange={strokeColorHandler}
      />
    </section>
  )
}

export { FontStrokeColorPicker }
