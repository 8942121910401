import { clsnx } from "@becks256/clsnx"
export const domain =
  process.env.NODE_ENV === "production"
    ? "https://api.soulfolioco.com"
    : "http://localhost:10443"
export const apiRoute = `${domain}/api`
export const authRoute = `${domain}/auth`
export const payRoute = `${domain}/pay`
export const promoRoute = `${domain}/promo`
export const api = {
  login: `${authRoute}/login`,
  logout: `${authRoute}/logout`,
  resetPassword: `${authRoute}/password/reset`,
  setPassword: `${authRoute}/password/set`,
  signup: `${authRoute}/signup`,
  getImageBySize: ({ isPz = false, size = "small", image_name = "" }) =>
    `${apiRoute}/image/get/${isPz ? `pz/` : ""}${size}/${image_name}`, //TODO: encrypt image_name
  getAllImages: ({ page, nPerPage }) =>
    `${apiRoute}/image/get/all/${page}/${nPerPage}`,
  getImagesByUser: ({ user }) => `${apiRoute}/image/get/${user}`,
  addImageToProject: ({ image_name, projectName, projectOwner, email }) =>
    `${apiRoute}/project/add/${image_name}/to/${projectName}/by/${projectOwner}/for/${email}`, //TODO: encrypt image_name, project_name, projectOwner, email
  deleteImageFromProject: ({ email, projectName, imageName, projectOwner }) =>
    `${apiRoute}/project/delete/image/${email}/${projectName}/${imageName}/${projectOwner}`, //TODO: encrypt imageName, projectName, projectOwner, email
  deleteProject: ({ email, projectToDelete }) =>
    `${apiRoute}/project/delete/${email}/${projectToDelete}`, //TODO: encrypt email, projectToDelete
  inviteUserToProject: ({ inviteEmail, projectName, ownerEmail }) =>
    `${apiRoute}/project/invite/user/${inviteEmail}/${projectName}/${ownerEmail}`, //TODO: encrypt projectName, email
  getImageData: `${apiRoute}/image/get/data`,
  saveImage: `${apiRoute}/image/save`,
  addCustomImage: ({ isBack = false, frontImageName }) =>
    `${apiRoute}/project/add/custom/image${clsnx({
      [`?is_back=true`]: isBack,
      [`&front_file=${frontImageName}`]: !!frontImageName,
    }).replace(/\s+/g, "")}`,
  uploadImage: ({ isBack = false }) =>
    `${apiRoute}/image/upload${clsnx({ [`?is_back=true`]: isBack })}`,
  getCoverImages: `${apiRoute}/image/get/covers`,
  newProject: `${apiRoute}/project/new`,
  getProject: `${apiRoute}/project/get`,
  getProjects: `${apiRoute}/project/get/all`,
  updateProject: `${apiRoute}/project/update`,
  revokeUserAccess: ({ email, projectName, owner }) =>
    `${apiRoute}/project/remove/user/${email}/${projectName}/${owner}`, //TODO: encrypt email, projectName, owner
  createPaymentIntent: `${payRoute}/create-payment-intent`,
  updatePaymentIntent: `${payRoute}/update-payment-intent`,
  getShippingRates: `${payRoute}/get-shipping-rates`,
  buyShipment: ({ ship_id, rate_id, owner, project_name }) =>
    `${payRoute}/buy-shipment/${ship_id}/${rate_id}/${owner}/${project_name}`, //TODO: encrypt owner, project_name
  verifyPromoCode: ({ promoCode }) => `${promoRoute}/code/${promoCode}`,
  modifyTileCount: `${apiRoute}/project/edit/tiles`,
  modifyTileCountForUser: `${apiRoute}/project/edit/user/tiles`,
  deleteContributor: ({ email, projectName, projectOwner, redist }) =>
    `${apiRoute}/project/delete/contributor/${email}/${projectName}/${projectOwner}?redistribute=${redist}`,
}
