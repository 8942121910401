import React, { useState } from "react"
import { TextInput, Message, InlineMessage } from "../../../../Components"

const BillingInfo = ({ billingValidation, setBillingValidation, setBillingData, commonShipping, email }) => {

  const [validations, setValidations] = useState()

  const validateInput = (e) => {
    const inputName = /(first|last)Name/gi.test(e.target.id)
      ? "name"
      : e.target.id.split("_")[e.target.id.split("_").length - 1]
    !e.target.value && e.target.classList.add("input-error")
    e.target.value && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !e.target.value,
        message: `Please specify a valid ${inputName}.`,
      },
    })
    return !!e.target.value
  }
  const validateAndSetInput = (e) => {
    validateInput(e) && setBillingData(e)
  }
  const validateStreet = (e) => {
    const addressRegex =
      /^\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+\s(?:Avenue|Lane|Road|Boulevard|Drive|Street|Highway|Court|Circle|Ave|Dr|Rd|Blvd|Ln|St|Hwy|Ct|Cir|\w{2,})\.?$/gi
    const isPOBox = /\s*(\.?p\s?\.?o\.?\s?)?box/gi.test(e.target.value)
    const isValid = addressRegex.test(e.target.value)
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `${
          (isPOBox && /ship/gi.test(e.target.id)) || (isPOBox && commonShipping)
            ? "We are unable to ship to PO Boxes at this time. "
            : ""
        }Please specify a valid street address.`,
      },
    })
    return isValid
  }
  const validateAndSetStreet = (e) => {
    validateStreet(e) && setBillingData(e)
  }
  const validateZip = (e) => {
    const isValid = /^\d{5}(|-\d{4})$/g.test(e.target.value)
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `Please specify a valid zip code.`,
      },
    })
    return isValid
  }
  const validateAndSetZip = (e) => {
    validateZip(e) && setBillingData(e)
  }
  const validateState = (e) => {
    const isValid =
      /AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY/gi.test(
        e.target.value
      )
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `Please specify a valid two-letter state abbr.`,
      },
    })
    return isValid
  }
  const validateAndSetState = (e) => {
    validateState(e) && setBillingData(e)
  }
  const validateAndSetEmail = (e) => {
    const isValid = /^.+?@[\w\W]+?\.[\w\W]{2,3}$/gi.test(e.target.value)
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")

    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: "Please specify a valid email address.",
      },
    })

    isValid && setBillingData(e)
  }

  return (
    <>
      <h2 className="heading heading-s color-brand--primary">
        Billing Information
      </h2>
      <section className="flex w-full flex-wrap justify-content--space-between">
        <TextInput
          autoComplete="new-off"
          label="First Name"
          id="bill_firstName"
          name="bill_firstName"
          placeholder="Cookie"
          type="text"
          onBlur={validateAndSetInput}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
          wrapperClasses="md:w-33 sm:w-full mb-16 box-sizing--border-box md:pr-8"
        >
          {validations?.["bill_firstName"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_firstName"].message}
            />
          )}
        </TextInput>
        <TextInput
          autoComplete="new-off"
          label="Last Name"
          id="bill_lastName"
          name="bill_lastName"
          placeholder="Monster"
          type="text"
          onBlur={validateAndSetInput}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
          wrapperClasses="md:w-33 sm:w-full mb-16 box-sizing--border-box md:px-8"
        >
          {validations?.["bill_lastName"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_lastName"].message}
            />
          )}
        </TextInput>
        <TextInput
          autoComplete="new-off"
          label="Email Address"
          id="bill_email"
          name="bill_email"
          placeholder="cookie.monster@sesamestreet.com"
          type="text"
          onBlur={validateAndSetEmail}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input  w-full box-sizing--border-box"
          wrapperClasses="md:w-33 sm:w-full mb-16 box-sizing--border-box md:pl-8"
          defaultValue={email}
        >
          {validations?.["bill_email"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_email"].message}
            />
          )}
        </TextInput>
      </section>
      <TextInput
        autoComplete="new-off"
        label="Street Address"
        id="bill_street"
        name="bill_street"
        placeholder="123 Sesame St."
        type="text"
        onBlur={validateAndSetStreet}
        labelClasses="App-checkout--label display-block"
        inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box mb-16"
        wrapperClasses="w-full"
      >
        {validations?.["bill_street"]?.visible && (
          <InlineMessage
            className="mb-16"
            kind="error"
            message={validations["bill_street"].message}
          />
        )}
      </TextInput>
      <section className="flex flex-wrap w-full mb-48 justify-content--space-between">
        <TextInput
          autoComplete="new-off"
          label="City"
          id="bill_city"
          name="bill_city"
          placeholder="New York City"
          type="text"
          onBlur={validateAndSetInput}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
          wrapperClasses="md:w-30 sm:w-full sm:mb-16 box-sizing--border-box"
        >
          {validations?.["bill_city"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_city"].message}
            />
          )}
        </TextInput>
        <TextInput
          autoComplete="new-off"
          label="State"
          id="bill_state"
          name="bill_state"
          placeholder="NY"
          type="text"
          onBlur={validateAndSetState}
          maxLength={2}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
          wrapperClasses="md:w-30 sm:w-50 sm:pr-8 box-sizing--border-box"
        >
          {validations?.["bill_state"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_state"].message}
            />
          )}
        </TextInput>
        <TextInput
          autoComplete="new-off"
          label="ZIP"
          id="bill_zip"
          name="bill_zip"
          placeholder="10128"
          type="number"
          onBlur={validateAndSetZip}
          maxLength={10}
          labelClasses="App-checkout--label display-block"
          inputClasses="p-16 font-size-10 App-checkout--input  w-full box-sizing--border-box"
          wrapperClasses="md:w-30 sm:w-50 sm:pl-8 box-sizing--border-box"
        >
          {validations?.["bill_zip"]?.visible && (
            <InlineMessage
              className="mt-16 mb-8"
              kind="error"
              message={validations["bill_zip"].message}
            />
          )}
        </TextInput>
      </section>
      {billingValidation.visible && (
        <section className="w-full">
          <Message
            id="billing-message"
            kind={billingValidation.kind}
            message={billingValidation.message}
            visible={billingValidation.visible}
            state={[billingValidation, setBillingValidation]}
            className="w-full"
          />
        </section>
      )}
    </>
  )
}

export { BillingInfo }
