import React from 'react'
import { IconContainer } from './IconContainer'

const TrashIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <path d="M18.5,21.6L19.6,93c0,3.9,3.1,7,7,7h46.9c3.9,0,7-3.1,7-7l1.1-71.5H18.5z M34,85.9c-1.2,0-2.2-1-2.2-2.2l-1.1-48.1
	c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2l1.1,48.1C36.2,84.9,35.2,85.9,34,85.9z M52.2,83.9c0,1.1-0.9,2-2,2h-0.3c-1.1,0-2-0.9-2-2V35.6
	c0-1.1,0.9-2,2-2h0.3c1.1,0,2,0.9,2,2V83.9z M68.1,83.7c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2l1.1-48.1c0-1.2,1-2.2,2.2-2.2
	s2.2,1,2.2,2.2L68.1,83.7z"/>
      <path d="M78.9,7.9H64.8l-3.2-5.5v0C60.7,0.9,59.1,0,57.4,0H42.8c-1.7,0-3.3,0.9-4.2,2.4l-3.2,5.5H21.1c-2.4,0-4.4,2-4.4,4.4V17h66.5
	v-4.7C83.3,9.9,81.3,7.9,78.9,7.9z M42.5,6.3c0.5-0.9,1.4-1.4,2.4-1.4h10.4c1,0,1.9,0.5,2.4,1.4l0.9,1.6H41.6L42.5,6.3z"/>
    </IconContainer>
  )
}

export { TrashIcon }