import React, { useState, useEffect } from "react"
import {
  Button,
  TextInput,
  Container,
  Message,
  Spinner,
} from "../../Components/"
import { useTitle } from "../../Hooks/UseTitle"
import { api } from "../../utils/api"
import "./ResetPasswordPage.css"

const ResetPasswordPage = () => {
  const [useStatus, setStatus] = useState({
    visible: true,
    kind: "info",
    message:
      "We will send an email to the provided address with additional instructions to set a new password.",
  })
  const [loading, setLoading] = useState(false)

  useTitle("Reset your Password")

  const resetMessage = () => {
    setStatus({
      visible: true,
      kind: "info",
      message:
        "We will send an email to the provided address with additional instructions to set a new password.",
    })
  }

  const clickHandler = () => {
    setLoading(true)
    resetMessage()
    const inputs = document.querySelectorAll("input:not(input[type='checkbox'])")
    const newUser = {}
    for (let i in inputs) {
      let key = inputs[i].id
      let prop = inputs[i].value
      if (prop !== "") {
        if (key !== "password-confirm") {
          newUser[key] = prop
        }
      } else {
        setStatus({
          visible: true,
          kind: "error",
          message: `You must provide your email address to continue`,
        })
        setLoading(false)
        return
      }
    }

    fetch(api.resetPassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...newUser }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { msg, status } = data
        setStatus({
          visible: true,
          kind: status === 200 ? "success" : "warning",
          message: msg,
        })
        setLoading(false)
      })
      .catch((err) => {
        setStatus({
          visible: true,
          kind: "error",
          message: `Something mysterious happened.  Give it a moment and try again.`,
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      e.key === "Enter" && clickHandler()
    })
    return () => {
      window.removeEventListener("keyup", (e) => {
        e.key === "Enter" && clickHandler()
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container className="App-login--container box-shadow--medium flex flex-direction--column align-items--center">
      <h1 className="center-text Login-header">Password Reset</h1>
      <TextInput
        id="email"
        inputClasses="Login-input"
        type="text"
        placeholder="Email Address"
        wrapped={false}
      ></TextInput>
      <Button
        onClick={clickHandler}
        className="Login-button"
        disabled={loading}
      >
        {loading ? (
          <Spinner size="tiny" color="white" text={null} />
        ) : (
          "Send Email"
        )}
      </Button>
      <Message
        kind={useStatus.kind}
        message={useStatus.message}
        state={[useStatus, setStatus]}
        fixed={useStatus.kind === "info"}
      ></Message>
    </Container>
  )
}

export { ResetPasswordPage }
