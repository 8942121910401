import React, { useContext, useEffect, useState } from "react"
import { api } from "../../../../utils/api"
import { PZImageWithDataString, Image, Button } from "../../.."
import { MD5 } from "crypto-js"
import { ProjectContext } from "../../../../App"

const FolioPreview = ({
  setImageToEdit,
  setModalProgress,
  editState,
  editHandler,
}) => {
  const [images, setImages] = useState()
  const { gridData } = useContext(ProjectContext)

  const launchImageEditor = ({
    image_name,
    back_image_data,
    canEdit,
    isUpload,
  }) => {
    editHandler()
    setModalProgress(editState)
    setImageToEdit({ image_name, back_image_data, canEdit, isUpload })
  }

  useEffect(() => {
    const flattenedImagesArray = gridData?.project_array
      .flatMap(({ images }) => images)
      .flat()
      .sort((a, b) => a.sort_order - b.sort_order)

    const imagesCopy = [...flattenedImagesArray]
    for (let [index, item] of imagesCopy.entries()) {
      item.sort_order = item.sort_order === null ? index : item.sort_order
    }
    setImages(imagesCopy)
  }, [gridData])

  const TilePreview = ({
    imageName,
    backImageName,
    side,
    canEdit,
    isUpload,
  }) => {
    const id = MD5(imageName)
    // const isFront = /_front/.test(imageName)
    return (
      <section className="flex flex-direction--column align-items--center mb-8">
        {canEdit || !!backImageName || isUpload ? (
          <PZImageWithDataString
            id={id}
            imageName={imageName}
            backImageName={backImageName}
            side={side}
            className="App-sortable-image"
            boundsSelector=".App-sortable-image"
            containerClass="relative"
            wrapperClasses="m-8"
          />
        ) : (
          <Image
            inert="true"
            className="App-sortable-image"
            containerClass="flex relative m-8"
            src={api.getImageBySize({
              size: "small",
              image_name: imageName || backImageName,
            })}
            alt=""
          />
        )}
        <label htmlFor={id}>{side}</label>
      </section>
    )
  }

  return (
    <section className="App-sortable-image--container md: px-48">
      {images &&
        images.map(
          ({ image_name, back_image_data, canEdit, isUpload }, index) => (
            <section
              key={`page-preview-${index}`}
              id={`preview-item-${index}`}
              className="flex flex-direction--column align-items--center mb-8 border-bottom"
            >
              <p className="bold mb-8 paragraph paragraph-l">{`Page ${
                index + 1
              }`}</p>
              <Button
                className="mb-16"
                compact
                onClick={() => {
                  launchImageEditor({
                    image_name,
                    back_image_data,
                    canEdit,
                    isUpload,
                  })
                }}
              >
                Edit
              </Button>
              <section className="flex flex-direction--row">
                <TilePreview
                  imageName={image_name}
                  canEdit={canEdit}
                  side="front"
                />
                <TilePreview
                  imageName={image_name}
                  backImageName={back_image_data.file_name}
                  side="back"
                  canEdit={canEdit}
                  isUpload={isUpload}
                />
              </section>
            </section>
          )
        )}
    </section>
  )
}

export { FolioPreview }
