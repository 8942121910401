import React, { createContext, useEffect, useState } from "react"
import "./WorkspaceTemplate.css"

export const ModalContext = createContext()

const Workspace = ({ children }) => {
  const [modalHidden, setModalHidden] = useState(true)

  useEffect(() => {
    if (modalHidden) {
      document.body.removeAttribute("style")
    } else if (!modalHidden) {
      document.body.style.overflow = "hidden"
    }
  }, [modalHidden])

  return (
    <ModalContext.Provider value={{ modalHidden, setModalHidden }}>
      <div className="App--dashboardWindow">{children}</div>
    </ModalContext.Provider>
  )
}

export { Workspace }
