import React from "react"
import { IconContainer } from "./IconContainer"

const SadRobotIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M95.7,50.9c-1.8,0-3.4,1.1-4,2.8h-0.3v-5.3c0-1.6-1.6-2.9-3-3.7V34.6c0-2.9-2.4-5.3-5.3-5.3H51.4v-4.2
		l-0.9-0.9l3.6-1.6l-2.7-2.6v-0.6c1.7-0.6,2.8-2.2,2.8-4c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3c0,1.8,1.1,3.4,2.8,4v1.8
		l0.8,0.8l-3.6,1.6l2.8,2.8v3H16.9c-2.9,0-5.3,2.4-5.3,5.3v10.1c-1.4,0.9-3,2.2-3,3.7v5.3H8.3c-0.6-1.7-2.2-2.8-4-2.8
		c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c1.8,0,3.4-1.1,4-2.8h0.3v5.3c0,1.6,1.6,2.9,3,3.7v18.1c0,2.9,2.4,5.3,5.3,5.3h66.1
		c2.9,0,5.3-2.4,5.3-5.3V65.6c1.4-0.9,3-2.2,3-3.7v-5.3h0.3c0.6,1.7,2.2,2.8,4,2.8c2.4,0,4.3-1.9,4.3-4.3
		C100,52.8,98.1,50.9,95.7,50.9z M97,55.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
		C96.4,53.8,97,54.4,97,55.2z M48.6,15.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C49.2,16.7,48.6,16.1,48.6,15.3z M83.1,86H16.9c-1.3,0-2.3-1-2.3-2.3V34.6c0-1.3,1-2.3,2.3-2.3h66.1c1.3,0,2.3,1,2.3,2.3v49.1
		C85.4,85,84.3,86,83.1,86z M5.6,55.2c0,0.7-0.6,1.3-1.3,1.3S3,55.9,3,55.2c0-0.7,0.6-1.3,1.3-1.3S5.6,54.4,5.6,55.2z"
      />
      <path
        d="M73,66.2H27c-2.6,0-4.8,2.1-4.8,4.8v3.6c0,2.6,2.1,4.8,4.8,4.8H73c2.6,0,4.8-2.1,4.8-4.8V71
		C77.8,68.3,75.6,66.2,73,66.2z M73,76.4h-3.1v-7.2H73c1,0,1.8,0.8,1.8,1.8v3.6C74.8,75.6,74,76.4,73,76.4z M66.9,69.2v7.2h-4.4
		v-7.2H66.9z M59.5,69.2v7.2h-4.4v-7.2H59.5z M52.2,69.2v7.2h-4.4v-7.2H52.2z M44.8,69.2v7.2h-4.4v-7.2H44.8z M37.5,69.2v7.2h-4.4
		v-7.2H37.5z M27,69.2h3.1v7.2H27c-1,0-1.8-0.8-1.8-1.8V71C25.2,70,26,69.2,27,69.2z"
      />
      <path
        d="M32.2,57.6H39c3,0,5.5-2.5,5.5-5.5v-6.8c0-3-2.5-5.5-5.5-5.5h-6.8c-3,0-5.5,2.5-5.5,5.5v6.8
		C26.7,55.2,29.2,57.6,32.2,57.6z M32.2,42.8H39c1.4,0,2.5,1.1,2.5,2.5v6.8c0,1.4-1.1,2.6-2.5,2.6h-6.8c-1.4,0-2.6-1.1-2.6-2.6v-6.8
		C29.7,43.9,30.8,42.8,32.2,42.8z"
      />
      <path
        d="M61,57.6h6.8c3,0,5.5-2.5,5.5-5.5v-6.8c0-3-2.5-5.5-5.5-5.5H61c-3,0-5.5,2.5-5.5,5.5v6.8
		C55.5,55.2,58,57.6,61,57.6z M61,42.8h6.8c1.4,0,2.5,1.1,2.5,2.5v6.8c0,1.4-1.1,2.6-2.5,2.6H61c-1.4,0-2.6-1.1-2.6-2.6v-6.8
		C58.4,43.9,59.6,42.8,61,42.8z"
      />
      <path
        d="M60.6,50.7c0,0.5,0.2,0.9,0.5,1.2l0.1,0.1c0.7,0.7,1.8,0.6,2.5,0l0.7-0.7l0.7,0.7c0.3,0.3,0.8,0.5,1.3,0.5
		c0.5,0,0.9-0.2,1.2-0.5l0.1-0.1c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.3L67,48.7l0.7-0.7c0.7-0.7,0.7-1.8,0-2.5l-0.1-0.1
		c-0.4-0.4-0.9-0.6-1.5-0.5c-0.4,0.1-0.7,0.2-1,0.5l-0.7,0.7l-0.7-0.7c-0.7-0.7-1.8-0.7-2.5,0l-0.1,0.1c-0.7,0.7-0.7,1.8,0,2.5
		l0.7,0.7l-0.7,0.7C60.8,49.8,60.6,50.2,60.6,50.7z"
      />
      <path
        d="M31.8,50.7c0,0.5,0.2,0.9,0.5,1.2l0.1,0.1c0.7,0.7,1.8,0.6,2.5,0l0.7-0.7l0.7,0.7c0.3,0.3,0.8,0.5,1.3,0.5
		c0.5,0,0.9-0.2,1.2-0.5l0.1-0.1c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.3l-0.7-0.7l0.7-0.7c0.7-0.7,0.7-1.8,0-2.5l-0.1-0.1
		c-0.4-0.4-0.9-0.6-1.5-0.5c-0.4,0.1-0.7,0.2-1,0.5l-0.7,0.7l-0.7-0.7c-0.7-0.7-1.8-0.7-2.5,0l-0.1,0.1c-0.7,0.7-0.7,1.8,0,2.5
		l0.7,0.7l-0.7,0.7C32,49.8,31.8,50.2,31.8,50.7z"
      />
    </IconContainer>
  )
}

export { SadRobotIcon }
