import React, { useContext, useState, useEffect, memo, useMemo } from "react"
import { ShoppingCartContext } from "../../App"
import { DialogBox, Message, Divider, Spinner } from "../../Components"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { api } from "../../utils/api"
import "./ShoppingCartPage.css"
import { CheckoutForm } from "./CheckoutForm"
import { ShoppingCartList } from "./ShoppingCartList"
import { SessionContext } from "../../App"
import { useHistory } from "react-router-dom"
import { useColorMode, DARK_MODE } from "react-darkmode-hook"
import { useTitle } from "../../Hooks/UseTitle"

const UnmemoizedShoppingCartPage = () => {
  const [clientSecret, setClientSecret] = useState("")
  const { shoppingCart } = useContext(ShoppingCartContext)
  const {
    session: { token },
  } = useContext(SessionContext)
  const { colorMode } = useColorMode()
  const [isMobile, setIsMobile] = useState(false)
  const [shippingRate, setShippingRate] = useState({
    value: 0,
    ship_id: undefined,
    rate_id: undefined,
  })
  const [isTaxable, setIsTaxable] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState()
  const [giftWrap, setGiftWrap] = useState(false)
  const [promo, setPromo] = useState(null)
  const stripePromise = useMemo(
    () =>
      loadStripe(
        process.env.NODE_ENV === "production"
          ? `${process.env.REACT_APP_STRIPE_PROD_PUB_KEY}`
          : `${process.env.REACT_APP_STRIPE_TEST_PUB_KEY}`
      ),
    []
  )
  useTitle("My Cart")

  let history = useHistory()

  const appearance = {
    theme: colorMode === DARK_MODE ? "night" : "flat",
    variables: {
      borderRadius: "12px",
    },
  }

  const options = {
    clientSecret,
    appearance,
  }

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(api.createPaymentIntent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        isTaxable,
        shippingRate,
        items: shoppingCart.reduce(
          (acc, curr) => acc + Number(curr.tile_count),
          0
        ),
      }),
    })
      .then((res) => res.json())
      .then(({ status, stripe }) => {
        // console.log(status)
        // console.log(stripe)
        if (status === 200) {
          setClientSecret(stripe.clientSecret)
          setPaymentIntentId(stripe.id)
          if (status === 401) {
            history.push("/logout?status=session-expired")
          }
        } else {
          console.error(status)
        }
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    ;/mobile/gi.test(navigator.userAgent) && setIsMobile(true)
    window.addEventListener("resize", () => {
      const width = window.innerWidth
      setIsMobile(width < 900)
    })

    return () =>
      window.removeEventListener("resize", () => {
        const width = window.innerWidth
        setIsMobile(width < 900)
      })
  }, [setIsMobile])

  return (
    <DialogBox header="Checkout" className="align-items--center">
      {shoppingCart?.length > 0 ? (
        <section className="card App-checkout--card bg-true-white px-24 py-32 md:px-48 md:py-64 flex sm:flex-direction--column justify-content--space-around w-full">
          <ShoppingCartList
            shippingRate={shippingRate}
            isTaxable={isTaxable}
            giftWrap={giftWrap}
            promoState={[promo, setPromo]}
          />
          {isMobile && <Divider className="mb-32" />}
          {clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                shippingRateState={[shippingRate, setShippingRate]}
                taxableState={[isTaxable, setIsTaxable]}
                setGiftWrap={setGiftWrap}
                paymentIntentId={paymentIntentId}
                promo={promo}
                isProjectOwner={true}
              />
            </Elements>
          ) : (
            <Spinner text="Loading Checkout Form..." size="small" />
          )}
        </section>
      ) : (
        <Message
          className="w-full"
          kind="info"
          message="Your Cart is Empty"
          visible
          fixed
        />
      )}
    </DialogBox>
  )
}

const ShoppingCartPage = memo(UnmemoizedShoppingCartPage)

export { ShoppingCartPage }
