import React, { useContext, memo } from "react"
import { Message, Image, PZImageWithDataString } from "../"
import { SessionContext, ProjectContext } from "../../App"
import { api } from "../../utils/api"

const UnmemoizedAllTilesTab = () => {
  const {
    session: { email },
  } = useContext(SessionContext)
  const { gridData, projectName, projectOwner } = useContext(ProjectContext)
  return (
    <>
      <section className="border-bottom">
        <h2 className="pt-24">See all tiles together</h2>
        <p className="paragraph paragraph--l">
          Below is a preview of all images added by collaborators thus far to
          this Soulfolio.
        </p>
        <section className="pb-24">
          {gridData?.owner !== email && (
            <Message
              message={`The project owner ${
                gridData?.owner ? `( ${gridData.owner} )` : ""
              } is the only one capable of finalizing the purchase and payment of this Soulfolio.`}
              fixed
              kind="info"
              className="md:w-60 sm:w-full"
            />
          )}
        </section>
      </section>
      <section className="flex flex-direction--row flex-wrap justify-content--center pt-24 App-GridTileWrapper">
        {gridData?.project_array
          .flatMap(({ images }) => images)
          .flat()
          .sort((a, b) => a.sort_order < b.sort_order)
          .map(({ image_name, canEdit }, index) =>
            canEdit ? (
              <PZImageWithDataString
                key={`${image_name}-${index}`}
                id={`pzimage-${index}`}
                className="App-project-grid--image outline-solid border-radius--smaller outline-offset--1px"
                boundsSelector=".App-project-grid--image"
                containerClass="App-project-grid--all-tiles-image-container"
                imageName={image_name}
                projectName={projectName}
                projectOwner={projectOwner}
                wrapperClasses="sm:w-100"
                alt=""
              />
            ) : (
              <Image
                key={`${image_name}-${index}`}
                id={image_name}
                className="App-project-grid--image"
                containerClass="App-project-grid--all-tiles-image-container outline-solid border-radius--smaller"
                src={api.getImageBySize({ size: "small", image_name })}
                alt=""
              />
            )
          )}
      </section>
    </>
  )
}

const AllTilesTab = memo(UnmemoizedAllTilesTab)

export { AllTilesTab }
