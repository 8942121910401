import React from 'react'
import { IconContainer } from './IconContainer'

const ShoppingCartIcon = ({ size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <path d="M31.2,66.4C31.2,66.4,31.2,66.3,31.2,66.4c0.5,0,0.9,0,1.3,0h48.8c3,0,5.7-2,6.6-4.9l8.9-30.2c0.6-2,0.2-4.1-0.9-5.8
        c-1.2-1.7-3-2.8-5.1-3L24.3,17c-1.1-0.1-2.2,0.1-3.2,0.5l-0.9-3.7c-0.8-3.1-3.5-5.2-6.7-5.2H9c-1.7,0-3,1.3-3,3s1.3,3,3,3h4.6
        c0.4,0,0.8,0.3,0.9,0.7l11.8,47.3c0.1,0.2,0,0.5-0.1,0.7L18.7,72c-0.8,1.4-1.3,3-1.2,4.6c0.3,2.9,2.2,5,4.6,5.8
        c-0.5,1.1-0.7,2.2-0.7,3.4c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5c0-1.1-0.2-2.1-0.6-3l28,0c-0.4,0.9-0.6,2-0.6,3
        c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5c0-1.1-0.2-2.1-0.6-3h7.4c1.5,0,2.6-1.2,2.6-2.6v-0.7c0-1.5-1.2-2.6-2.6-2.6H24.4
        c-0.4,0-0.7-0.2-0.8-0.4c-0.1-0.2-0.2-0.5,0-0.9L31.2,66.4z M89,28.1l1.4,0.4c0.8,0.2,1.3,1.1,1.1,2l-8.7,29.2
        c-0.2,0.8-1.1,1.3-2,1.1l-1.4-0.4c-0.8-0.2-1.3-1.1-1.1-2L87,29.1C87.3,28.3,88.1,27.8,89,28.1z M79.5,28.1l1.4,0.4
        c0.8,0.2,1.3,1.1,1.1,2l-8.7,29.2c-0.2,0.8-1.1,1.3-2,1.1l-1.4-0.4c-0.8-0.2-1.3-1.1-1.1-2l8.7-29.2C77.8,28.3,78.7,27.8,79.5,28.1z
        M59.4,58.3l8.7-29.2c0.2-0.8,1.1-1.3,2-1.1l1.4,0.4c0.8,0.2,1.3,1.1,1.1,2l-8.7,29.2c-0.2,0.8-1.1,1.3-2,1.1l-1.4-0.4
        C59.6,60,59.2,59.2,59.4,58.3z M73.9,83.4c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5S72.5,83.4,73.9,83.4z M30,83.4
        c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5S28.6,83.4,30,83.4z"/>
    </IconContainer>
  )
}

export { ShoppingCartIcon }