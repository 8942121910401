import React from "react"

const TextEditor = ({
  canEdit,
  text,
  textInputHandler,
  showOverlayHandler,
  showTextSafeArea,
}) => {
  return (
    <>
      <label
        htmlFor="image text"
        className="App-image-editor--label mb-8 sm:mt-16"
      >
        Image Text
      </label>
      <textarea
        disabled={!canEdit}
        title="image text"
        defaultValue={text}
        onChange={textInputHandler}
        className="p-8 border-radius--smaller App-image-editor--input sm:w-full box-sizing--border-box mb-8"
        rows={3}
      />
      <label
        htmlFor="show-overlay"
        className="flex align-items--center mb-16"
      >
        <input
          name="show-overlay"
          type="checkbox"
          className="App-checkout--checkbox mr-8 font-size--10"
          onClick={showOverlayHandler}
          defaultChecked={showTextSafeArea}
        />
        Show cut lines
      </label>
    </>
  )
}

export { TextEditor }
