import React, { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { DialogBox } from "../../Components"
import { SessionContext, ShoppingCartContext } from "../../App"
import { Link } from "react-router-dom"
import { useTitle } from "../../Hooks/UseTitle"

const OrderConfirmationPage = () => {
  const timeout = 10
  const [time, setTime] = useState(timeout)
  const { session } = useContext(SessionContext)
  const { setShoppingCart } = useContext(ShoppingCartContext)

  let history = useHistory()

  useTitle("Order Confirmed!")
  useEffect(() => {
    setShoppingCart([])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setTimeout(() => {
      time > 1 ? setTime(time - 1) : history.push("/app")
    }, 1000)
    // eslint-disable-next-line
  }, [time])

  return (
    <DialogBox header="Order Confirmation" className="align-items--center">
      <section className="flex align-self--center card App-checkout--card bg-true-white p-64">
        <section className="flex flex-direction--column align-self--center">
          <h1 className="heading heading-l my-32">Thank you for your order!</h1>
          <p className="paragraph paragraph-m mb-0">
            An email has been sent to{" "}
            {
              <a className="App-link" href={`mailto:${session.email}`}>
                {session.email}
              </a>
            }{" "}
            with a summary of your purchase.
          </p>
          <p className="paragraph paragraph-m my-0">
            You'll be redirected back to the dashboard in {time} seconds.
          </p>
          <p className="paragraph paragraph-m mt-0">
            {
              <Link
                className="App-link text-decoration--underline"
                to="/design/dashboard"
              >
                Click here
              </Link>
            }{" "}
            to return to the dashboard immediately.
          </p>
        </section>
      </section>
    </DialogBox>
  )
}

export { OrderConfirmationPage }
