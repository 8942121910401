import React from "react"
import * as Icon from "../Icons"
import "./Toast.css"
import { Capitalizer } from "../../utils/Capitalizer"
import { classname } from "../../utils/utils"

const Toast = ({ kind, message, className, children }) => {
  const IconName = Icon[`${Capitalizer(kind || "info")}Icon`]
  const content = (
    <>
      <section className="App-toast--content">
        <div>
          <IconName size={"small"} />
        </div>
        <div>
          {message && (
            <p className={`App-toast--text ml-24 font-size--11`}>{message}</p>
          )}
          {children && children}
        </div>
      </section>
    </>
  )

  return (
    <>
      <div
        className={classname(
          "App-toast p-24",
          `App-toast App-toast--${kind}`,
          className
        )}
      >
        {content}
      </div>
      <div className="App-toast--backdrop"></div>
    </>
  )
}

export { Toast }
