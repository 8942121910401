import React, { useEffect, memo, useCallback } from "react"
import { createPortal } from "react-dom"
import { CloseIcon } from "../Icons"
import "../Modal/Modal.css"

export const InstanceModal = memo(
  ({
    onClose,
    children,
    title,
    contentClassName,
    imageOnly,
    dialogClassName,
    headerTextColor,
    show,
    ...props
  }) => {
    const closeModal = useCallback(() => {
      onClose()
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      if (show) {
        document.body.style.overflow = "hidden"
      } else if (!show) {
        document.body.removeAttribute("style")
      }
      return () => {
        document.body.removeAttribute("style")
      }
    }, [show])

    if (!show) {
      return null
    }

    return (
      <>
        {imageOnly
          ? createPortal(
              show && (
                <div {...props} className="App-image-modal fade-in">
                  <div className="App-image-modal--dialogue">
                    <div
                      className={`App-modal--header color-${
                        headerTextColor || "gray-10"
                      }`}
                    >
                      <span className="App-image-modal--close-btn">
                        <CloseIcon
                          size="large"
                          className="clickable-icon color-static-text--inverse faux-link"
                          onClick={closeModal}
                        />
                      </span>
                    </div>
                    <section>
                      <div
                        className={`App-modal--content fade-in ${
                          contentClassName || ""
                        }`}
                      >
                        {children}
                      </div>
                    </section>
                  </div>
                </div>
              ),
              document.body
            )
          : createPortal(
              show && (
                <div {...props} className="App-modal fade-in">
                  <div
                    className={`App-modal--dialogue ${dialogClassName || ""}`}
                  >
                    <div
                      className={`App-modal--header color-${
                        headerTextColor || "dynamic-text"
                      } mb-16`}
                    >
                      <span className="App-modal--close-btn">
                        <CloseIcon
                          size="medium"
                          className="clickable-icon color-dynamic-text faux-link"
                          onClick={closeModal}
                        />
                      </span>
                      <p>{title}</p>
                    </div>
                    <section>
                      <div
                        className={`App-modal--content fade-in ${
                          contentClassName || ""
                        }`}
                      >
                        {children}
                      </div>
                    </section>
                  </div>
                </div>
              ),
              document.body
            )}
      </>
    )
  }
)
