import React from "react"
import { clsnx } from "@becks256/clsnx"
import "./IconButton.css"

const IconButton = ({
  children,
  className,
  onClick,
  palette = "interactive",
  ...props
}) => {
  return (
    <button
      {...props}
      className={clsnx(
        "App-icon-button",
        {
          [`App-icon-button--${palette}`]: !!palette,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export { IconButton }
