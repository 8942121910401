// import blackFastener from "./SF_fastener_black.jpg"
// import blueFastener from "./SF_fastener_blue.jpg"
// import silverFastener from "./SF_fastener_silver.jpg"
// import whiteFastener from "./SF_fastener_white.jpg"
// import blackFastenerThumb from "./SF_fastener_black_thumb.jpg"
// import blueFastenerThumb from "./SF_fastener_blue_thumb.jpg"
// import silverFastenerThumb from "./SF_fastener_silver_thumb.jpg"
// import whiteFastenerThumb from "./SF_fastener_white_thumb.jpg"

import blackLeather from "./black_leather.jpg"
import blueLeather from "./blue_leather.jpg"
import whiteLeather from "./white_leather.jpg"
import blackWire from "./black_wire.jpg"
import blueWire from "./blue_wire.jpg"
import whiteWire from "./white_wire.jpg"
import goldWire from "./gold_wire.jpg"
import goldMetal from "./gold_metal.jpg"
import blackLeatherThumb from "./black_leather_thumb.jpg"
import blueLeatherThumb from "./blue_leather_thumb.jpg"
import whiteLeatherThumb from "./white_leather_thumb.jpg"
import blackWireThumb from "./black_wire_thumb.jpg"
import blueWireThumb from "./blue_wire_thumb.jpg"
import whiteWireThumb from "./white_wire_thumb.jpg"
import goldWireThumb from "./gold_wire_thumb.jpg"
import goldMetalThumb from "./gold_metal_thumb.jpg"

import giftBag from "./gift_bag.jpg"
import giftBagThumb from "./gift_bag_thumb.jpg"

export const thumbnails = [
  blackLeatherThumb,
  blueLeatherThumb,
  whiteLeatherThumb,
  blackWireThumb,
  blueWireThumb,
  whiteWireThumb,
  goldWireThumb,
  goldMetalThumb,
]

export const hiRes = [
  blackLeather,
  blueLeather,
  whiteLeather,
  blackWire,
  blueWire,
  whiteWire,
  goldWire,
  goldMetal,
]

export {
  blackLeather,
  blueLeather,
  whiteLeather,
  blackWire,
  blueWire,
  whiteWire,
  goldWire,
  goldMetal,
  blackLeatherThumb,
  blueLeatherThumb,
  whiteLeatherThumb,
  blackWireThumb,
  blueWireThumb,
  whiteWireThumb,
  goldWireThumb,
  goldMetalThumb,
  giftBag,
  giftBagThumb,
}
