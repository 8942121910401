import React, { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { SessionContext } from "../../App"
import {
  TextInput,
  Button,
  Container,
  Message,
  Spinner,
} from "../../Components"
import { useTitle } from "../../Hooks/UseTitle"
import { api } from "../../utils/api"

import "./SignupPage.css"

const SignupPage = (props) => {
  const { setSession } = useContext(SessionContext)
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [loading, setLoading] = useState(false)
  let history = useHistory()
  useTitle("Sign up for a Soulfolio Account!")

  const clickHandler = () => {
    setLoading(true)
    class User {
      constructor() {
        this.details = {
          email: undefined,
          firstname: undefined,
          lastname: undefined,
          password: undefined,
        }
        this.validatePassword = (confirmation) => {
          // TODO: validate for complexity minimum 8 characters w/ 1 uppercase, 1 lowercase, 1 number, and 1 special char
          return confirmation === this.details.password
        }
      }
    }
    const inputs = document.querySelectorAll("input:not(input[type='checkbox'])")
    const newUser = new User()

    for (let i in inputs) {
      let key = inputs[i].id
      let prop = inputs[i].value
      if (prop !== "") {
        if (key !== "password-confirm") {
          newUser.details[key] = prop
        }
      } else {
        setStatus({
          visible: true,
          kind: "warning",
          message: `Please fill out all fields`,
        })
        setLoading(false)
        return
      }
    }

    fetch(api.signup, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUser.details),
    })
      .then((res) => res.json())
      .then((data) => {
        const { msg, status, token, first_name, email } = data
        if (status === 200) {
          history.push("/design/dashboard")
          setSession({
            token,
            name: first_name,
            email,
          })
        }
        setLoading(false)
        setStatus({
          visible: true,
          kind: status === 200 ? "success" : "warning",
          message: msg,
        })
      })
      .catch((err) => {
        setLoading(false)
        setStatus({
          visible: true,
          kind: "warning",
          message: "Something went wrong, please try again",
        })
      })
  }

  return (
    <Container className="App-signup--container box-shadow--medium flex flex-direction--column align-items--center justify-content--center">
      <h1 className="center-text Signup-header">Create your Account</h1>
      <TextInput
        inputClasses="Signup-input"
        id="first_name"
        type="text"
        placeholder="First Name"
        wrapped={false}
      ></TextInput>
      <TextInput
        inputClasses="Signup-input"
        id="last_name"
        type="text"
        placeholder="Last Name"
        wrapped={false}
      ></TextInput>
      <TextInput
        inputClasses="Signup-input"
        id="email"
        type="text"
        placeholder="Email Address"
        wrapped={false}
      ></TextInput>
      <TextInput
        inputClasses="Signup-input"
        id="password"
        type="password"
        placeholder="Password"
        wrapped={false}
      ></TextInput>
      <TextInput
        inputClasses="Signup-input"
        id="password-confirm"
        type="password"
        placeholder="Confirm Password"
        wrapped={false}
      ></TextInput>
      <Button
        onClick={clickHandler}
        className="Signup-button"
        disabled={loading}
      >
        {loading ? <Spinner size="tiny" color="white" text={null} /> : "Signup"}
      </Button>
      <Message
        kind={useStatus.kind}
        message={useStatus.message}
        state={[useStatus, setStatus]}
      ></Message>
    </Container>
  )
}

export { SignupPage }
