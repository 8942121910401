import React from "react"
import { IconContainer } from "./IconContainer"

const SendEmailIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <g>
        <path d="M25.2,67H3.2C1.4,67,0,65.6,0,63.8v0c0-1.8,1.4-3.2,3.2-3.2h22.1c1.8,0,3.2,1.4,3.2,3.2v0C28.4,65.6,27,67,25.2,67z" />
        <path d="M25.2,53H10.5c-1.8,0-3.2-1.4-3.2-3.2v0c0-1.8,1.4-3.2,3.2-3.2h14.7c1.8,0,3.2,1.4,3.2,3.2v0C28.4,51.5,27,53,25.2,53z" />
        <path
          d="M25.2,38.9h-6.4c-1.8,0-3.2-1.4-3.2-3.2v0c0-1.8,1.4-3.2,3.2-3.2h6.4c1.8,0,3.2,1.4,3.2,3.2v0C28.4,37.5,27,38.9,25.2,38.9
		z"
        />
        <path
          d="M65.5,51.9c0.2,0.1,0.4,0.1,0.6,0l33-22c0.3-0.2,0.4-0.6,0.2-0.9c-0.9-1.3-2.4-2.2-4.1-2.2H36.4c-1.7,0-3.2,0.9-4.1,2.2
		c-0.2,0.3-0.1,0.7,0.2,0.9L65.5,51.9z"
        />
        <path d="M77.2,49.2c-0.3,0.2-0.3,0.7,0,0.9L99,64.7c0.4,0.3,1,0,1-0.5V35.3c0-0.5-0.6-0.8-1-0.5L77.2,49.2z" />
        <path d="M54.3,50.2c0.3-0.2,0.3-0.7,0-0.9L32.6,34.7c-0.4-0.3-1,0-1,0.5v28.9c0,0.5,0.6,0.8,1,0.5L54.3,50.2z" />
        <path
          d="M73.3,52.4c-0.2-0.1-0.5-0.1-0.7,0l-4.2,2.8c-0.8,0.5-1.6,0.8-2.5,0.8c-0.9,0-1.8-0.3-2.5-0.8L59,52.4
		c-0.2-0.1-0.5-0.1-0.7,0l-26,17.4c-0.3,0.2-0.4,0.6-0.2,0.9c0.8,1.6,2.4,2.7,4.4,2.7h58.8c1.9,0,3.6-1.1,4.4-2.7
		c0.1-0.3,0.1-0.7-0.2-0.9L73.3,52.4z"
        />
      </g>
    </IconContainer>
  )
}

export { SendEmailIcon }
