import React from 'react';
import './SoulfolioLogo.css'

const SoulfolioLogo = ({ ...props }) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 315 97" {...props}>
      <g>
        <path className="st0" d="M170.2,66.7c-0.4,0-0.8,0.2-1.1,0.6c0,0.1-0.1,0.2-0.3,0.4c-0.2,0.2-0.4,0.5-0.7,0.8c-0.3,0.3-0.5,0.7-0.8,1
		c-0.7-0.7-1.6-1-2.5-1c-1,0-2.2,0.4-3.4,1.1c-1.7,1-3.5,3.2-5.3,6.5s-2.8,6.3-2.8,8.8c0,1.4,0.3,2.5,0.9,3.3
		c0.9,1.3,2.4,1.9,4.5,1.9c4.1,0,9.3-2.5,15.5-7.4c-0.3,1.1-0.4,2.2-0.4,3.3c0,2.7,0.8,4.4,2.5,5.4c0.4,0.2,0.8,0.3,1.2,0.3
		c1.6,0,3.3-1.2,5.1-3.7c1.8-2.5,3.1-5.1,3.9-7.8c1.3,0.5,2.7,0.8,4,0.8c2,0,4.5-0.7,7.4-2.1c0.8-0.4,1.3-0.9,1.3-1.5
		c0-0.5-0.3-0.8-0.9-0.8c-0.4,0-0.8,0.1-1.2,0.4c-2.6,1.5-4.8,2.3-6.5,2.3c-1.2,0-2.4-0.3-3.5-0.9c0.3-1.4,0.5-2.8,0.5-4
		c0-1.8-0.3-3.1-1-3.9c-0.7-0.8-1.6-1.3-2.6-1.3c-1.1,0-2.1,0.4-3,1.1c-0.9,0.8-1.3,1.6-1.3,2.6c0,0.1,0,0.3,0.1,0.6
		c-1.3,0.5-2.5,2.1-3.8,4.6c-5.4,6.1-10.7,9.1-15.9,9.1c-0.8,0-1.5-0.1-2.2-0.2c-1.8-0.4-2.7-1.5-2.7-3.2c0-1.4,0.6-3.1,1.7-5.1
		c1.1-1.9,2.4-3.6,3.8-4.9c1.4-1.3,2.6-2,3.5-2c0.4,0,0.7,0.1,0.8,0.3l-1.6,2.1c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.7,0.3,1.4,0.9,2
		c0.6,0.6,1.2,0.9,1.7,0.9l0.3,0c0.2-0.1,0.3-0.4,0.3-0.9c0-0.5,0.3-1.2,0.9-2.1c0.6-0.9,1.5-1.9,2.5-3c1.1-1.1,1.6-1.7,1.7-1.8
		c0.3-0.4,0.5-0.9,0.5-1.4c0-0.6-0.2-1.1-0.5-1.5C171,66.9,170.6,66.7,170.2,66.7 M184,75.8c-0.7-1.2-0.8-2-0.3-2.4
		C183.9,74.1,184,74.9,184,75.8 M178.3,90c-0.2,0-0.3,0-0.4,0c-1.4-0.5-2.1-1.4-2.1-2.9c0-1.5,0.7-3.3,2-5.4
		c1.3-2.1,2.6-3.6,3.7-4.7c0.7,0.7,1.5,1.4,2.4,2c-0.3,2.4-1,4.8-2.1,7.3C180.6,88.8,179.4,90,178.3,90"/>
        <path className="st1" d="M43.5,47.5c0,4.5-3.4,7.7-10,7.7c-3.6,0-6.5-0.9-8.7-2v-3c2.5,1.3,5.4,2.2,8.5,2.2c4.2,0,7.3-1.6,7.3-4.9
		C40.6,39.2,25,44,25,34.2c0-4,2.7-7.8,9.4-7.8c3,0,5.5,0.8,7.5,1.7v2.8c-1.8-0.9-4.2-1.7-7.2-1.7c-3.6,0-6.8,1.4-6.8,5
		C27.8,40.9,43.5,36.7,43.5,47.5"/>
        <rect x="113.8" y="16.2" className="st1" width="2.8" height="34.5" />
        <path className="st2" d="M131.3,68.4c0.8,5.1,0.8,10.6,0.2,16.4c-0.6,5.8-1.6,8.8-2.9,9c-0.6,0.1-1.2-0.5-1.8-1.8
		c-0.5-1-0.8-2.4-1.1-4.1c-0.6-4-0.7-9.4-0.4-16.3c0.3-6.8,0.7-11.2,1-13.1C128.8,59,130.4,62.3,131.3,68.4 M143.7,14.7
		c-0.2,1.9-2.7,8.6-4.8,13.2c-1.4,2.9-3.6,7.2-6,9.8C136,29.9,140.9,17,143.7,14.7 M144.3,46.3c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.5-0.3-1.1-0.4-1.6c-0.1,0.1-0.2,0.2-0.4,0.3c-5.1,3.5-10.1,6.7-15.8,8.7c1.3-5.6,0.7-4.7,2.3-9.5c3.4-2.7,6.6-6.3,9.5-10.9
		c2.9-4.6,6.7-14.7,7.4-18.1c0.2-1,0.2-1.8,0.1-2.5c-0.1-1-0.5-1.8-1.1-2.2c-0.6-0.5-1.3-0.7-2.1-0.5c-0.7,0.1-1.4,0.4-2.1,1
		c-3.5,2.9-7.7,10.5-11.3,23.3c-1.6,5.8-2.2,7.5-2.4,8.3c-4.3,3.6-14.2,8.7-29.7,15.4c-17,7.4-34,13.7-51,19.1
		c-19.3,6.1-32.6,8.9-39.7,8.3c-3.2-0.3-5-1.3-5.3-3c-0.1-0.7,0.7-1.7,2.5-3c1.7-1.3,4-2.7,6.8-4c2.8-1.4,5.6-2.6,8.6-3.6
		s5.8-1.7,8.5-1.9c2.7-0.2,4.7,0.2,5.9,1.3c0.6,0.1,1.1,0,1.5-0.2c0.4-0.2,0.7-0.5,0.9-1c0.2-0.4,0.2-0.9,0.2-1.3
		c0-0.4-0.2-0.9-0.5-1.2s-0.8-0.6-1.3-0.7c-2.9-0.5-7.4,0.1-13.2,1.7c-6.5,1.8-11.8,4.2-16.2,7c-4.5,3-6.4,5.8-5.7,8.3
		c0.2,0.7,0.6,1.3,1.1,1.9c1.1,1.2,3.3,1.8,6.6,1.9c3.3,0,7.3-0.4,12-1.4c4.7-1,10-2.3,15.9-4c5.9-1.7,12-3.6,18.3-5.8
		c6.3-2.2,12.7-4.5,19.1-6.9s12.7-4.8,18.5-7.3c5.9-2.5,11.4-4.8,16.4-7c5.1-2.2,9.4-4.2,12.8-6c2.5-1.3,4.5-2.3,6.1-3.2
		c-0.7,2.7-1.3,5.6-2,8.7c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0.4-1.4,1.1-1.3,2.2
		c0.1,0.6,0.3,1.1,0.8,1.5c0.4,0.4,1,0.6,1.7,0.6c-2.5,13.1-3.2,23.3-2.1,30.6c0.7,4.8,2,7.3,3.9,7.5c0.5,0.1,1,0.1,1.5,0
		c1.6-0.2,3-1.3,4.1-3c1.1-1.8,1.8-4,2.3-6.7c0.4-2.7,0.6-5.5,0.6-8.6c0-3-0.3-6.1-0.7-9.1c-1.1-7.2-2.7-11.7-4.8-13.6
		c3.9-1.9,8-4.4,12.2-7.3C142.1,48,143.2,47.2,144.3,46.3"/>
        <path className="st2" d="M236.3,19.9c-6,2.3-10,3.4-12,3.2c2.7-2.9,12.3-6.4,28.6-10.7C247.8,15.1,242.3,17.6,236.3,19.9 M314.7,0.3
		c-0.2-0.2-0.5-0.3-0.8-0.3c-3.2,0-10,0.8-20.3,2.6c-10.3,1.8-20.8,3.9-31.5,6.3c1.6-1.4,2.4-2.6,2.3-3.5c-0.1-2.1-3-3.1-8.6-2.8
		c-5.3,0.3-12.5,1.5-21.5,3.7c-9,2.2-18,4.7-26.8,7.6c-4,1.3-8.6,2.8-13.3,4.5c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.2,0.6-0.2,0.9
		c2.1-0.7,4.1-1.4,5.8-2c10.7-3.7,21.4-6.9,32.2-9.8c10.8-2.8,18.8-4.4,24-4.5c2.1-0.1,3.6,0,4.7,0.3c1.1,0.3,1.6,0.8,1.6,1.6
		c-0.1,1.1-1.8,2.6-4.9,4.5c-10,2.4-18.3,4.7-24.8,7c-6.5,2.3-10,4.3-10.7,6l-0.1,0.3c-0.2,1.2,0.4,1.9,1.8,2.1
		c2.5,0.3,7.9-1.2,16.4-4.6c8.5-3.4,15.1-6.7,19.9-10.1c12.3-3,24-5.3,35.2-7.1c11.1-1.8,17.5-2.2,19-1.3c0.3,0,0.6-0.1,0.8-0.4
		c0.2-0.2,0.3-0.5,0.4-0.9C315,0.8,314.9,0.6,314.7,0.3"/>
        <path className="st1" d="M72.1,40.8c0-8.8-4-11.8-10.1-11.8c-6,0-10.1,3-10.1,11.8c0,8.8,4.1,11.8,10.1,11.8
		C68.1,52.6,72.1,49.6,72.1,40.8 M74.9,40.8c0,11.8-7,14.4-12.9,14.4c-5.9,0-12.9-2.6-12.9-14.4c0-11.8,7-14.4,12.9-14.4
		C67.9,26.4,74.9,29,74.9,40.8"/>
        <path className="st1" d="M102.1,26.9h2.8v16.3c0,7.7-4.2,11.9-11.5,11.9c-7.3,0-11.5-4.3-11.5-11.9V26.9h2.8v16.5
		c0,6.1,2.8,9.2,8.7,9.2c5.7,0,8.7-3,8.7-9.2V26.9z"/>
        <path className="st1" d="M166.4,40.8c0-8.8-4-11.8-10.1-11.8c-6,0-10.1,3-10.1,11.8c0,8.8,4.1,11.8,10.1,11.8
		C162.4,52.6,166.4,49.6,166.4,40.8 M169.2,40.8c0,11.8-7,14.4-12.9,14.4s-12.9-2.6-12.9-14.4c0-11.8,7-14.4,12.9-14.4
		S169.2,29,169.2,40.8"/>
        <rect x="176.9" y="16.2" className="st1" width="2.8" height="38.6" />
        <path className="st1" d="M191.8,17.1c-0.5-0.3-1-0.3-1.6-0.1c-0.6,0.2-1,0.5-1.3,1l-0.6,1.1c-0.3,0.5-0.3,1-0.1,1.6
		c0.2,0.6,0.5,1,1,1.3c0.5,0.3,1,0.3,1.6,0.2c0.6-0.2,1-0.5,1.3-1l0.6-1.1c0.3-0.5,0.3-1.1,0.1-1.6
		C192.6,17.8,192.3,17.4,191.8,17.1"/>
        <path className="st1" d="M222,40.8c0-8.8-4-11.8-10.1-11.8c-6,0-10.1,3-10.1,11.8c0,8.8,4.1,11.8,10.1,11.8
		C217.9,52.6,222,49.6,222,40.8 M224.8,40.8c0,11.8-7,14.4-12.9,14.4c-5.9,0-12.9-2.6-12.9-14.4c0-11.8,7-14.4,12.9-14.4
		C217.8,26.4,224.8,29,224.8,40.8"/>
        <rect x="188.8" y="26.9" className="st1" width="2.8" height="27.9" />
      </g>
    </svg>
  )
}

export { SoulfolioLogo }