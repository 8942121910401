import React from "react"
import { DialogBox } from "../../Components"
import { useQuery } from "../../utils/useQuery"
import { SadRobotIcon } from "../../Components/Icons"
import { decrypt } from "../../utils/utils"
import { useTitle } from "../../Hooks/UseTitle"

const OrderErrorPage = () => {
  const query = useQuery()
  const errorMsg = query.get("error")
  useTitle("Order Error")

  return (
    <DialogBox header="Order Error" className="align-items--center">
      <section className="flex align-self--center card App-checkout--card bg-true-white p-64">
        <section className="flex flex-direction--column align-self--center">
          <section className="flex align-items--center">
            <SadRobotIcon size="3x-large mr-24" />{" "}
            <h1 className="heading heading-l my-32">Uh Oh!</h1>
          </section>
          <p className="paragraph paragraph-m mb-0">
            An error was encountered during checkout and the order process has
            been terminated. Below is a summary of what went wrong:
          </p>
          <p className="paragraph paragraph-m mt-16">{errorMsg && decrypt(errorMsg)}</p>
        </section>
      </section>
    </DialogBox>
  )
}

export { OrderErrorPage }
