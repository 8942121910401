import React from 'react'
import { IconContainer } from './IconContainer'

const ZoomIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <path d="M98.5,88L74.4,65.9c5.1-6.9,8.1-15.4,8.1-24.6C82.5,18.5,64,0,41.3,0C18.5,0,0,18.5,0,41.3
        C0,64,18.5,82.5,41.3,82.5c9.2,0,17.7-3,24.6-8.1L88,98.5c1,1,2.3,1.5,3.6,1.5c1.3,0,2.6-0.5,3.6-1.5l3.2-3.2
        C100.5,93.2,100.5,90,98.5,88z M41.3,73.5C23.5,73.5,9,59,9,41.3S23.5,9,41.3,9C59,9,73.5,23.5,73.5,41.3S59,73.5,41.3,73.5z"/>
      <path d="M47.5,35V20.6c0-2.6-2.1-4.7-4.7-4.7h-3c-2.6,0-4.7,2.1-4.7,4.7V35H20.6c-2.6,0-4.7,2.1-4.7,4.7v3
        c0,2.6,2.1,4.7,4.7,4.7H35V62c0,2.6,2.1,4.7,4.7,4.7h3c2.6,0,4.7-2.1,4.7-4.7V47.5H62c2.6,0,4.7-2.1,4.7-4.7v-3
        c0-2.6-2.1-4.7-4.7-4.7H47.5z"/>
    </IconContainer>
  )
}

export { ZoomIcon }