import React, { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { SessionContext } from "../../App"
import {
  Button,
  TextInput,
  Container,
  Message,
  Spinner,
} from "../../Components"
import { useTitle } from "../../Hooks/UseTitle"
import { api } from "../../utils/api"
import { base64ToString } from "../../utils/base64"
import { useQuery } from "../../utils/useQuery"
import "./SetNewPasswordPage.css"

const SetNewPasswordPage = ({ ...props }) => {
  const { setSession } = useContext(SessionContext)
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [loading, setLoading] = useState(false)

  let query = useQuery()
  let ssrToken = query.get("token")
  let ssrEmail = query.get("email")
  let history = useHistory()
  useTitle("Set a new Password")

  const clickHandler = () => {
    setLoading(true)
    const inputs = document.querySelectorAll("input:not(input[type='checkbox'])")
    const newUser = { token: props?.location?.search?.split("=")[1] }
    for (let i in inputs) {
      let key = inputs[i].id
      let prop = inputs[i].value
      if (prop !== "") {
        newUser[key] = prop
      } else {
        setStatus({
          visible: true,
          kind: "error",
          message: `Both fields must be filled in to continue`,
        })
        setLoading(false)
        return
      }
    }

    if (newUser.password === newUser["password-confirm"]) {
      fetch(api.setPassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newUser,
          email: base64ToString(ssrEmail),
          token: ssrToken,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const { msg, status, token, first_name, email } = data
          if (status === 200) {
            history.push("/design/dashboard")
            setSession({
              token,
              name: first_name,
              email,
            })
          }
          setLoading(false)
          setStatus({
            visible: true,
            kind: status === 200 ? "success" : "warning",
            message: msg,
          })
        })
        .catch((err) => {
          setLoading(false)
          setStatus({
            visible: true,
            kind: "warning",
            message: "Something went wrong, please try again",
          })
        })
    } else {
      setLoading(false)
      setStatus({
        visible: true,
        kind: "error",
        message: `Both passwords must match to continue`,
      })
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      e.key === "Enter" && clickHandler()
    })
    return () => {
      window.removeEventListener("keyup", (e) => {
        e.key === "Enter" && clickHandler()
      })
    }
    // eslint-disable-next-line
  }, [])

  return ssrEmail && ssrToken ? (
    <Container className="App-login--container box-shadow--medium flex flex-direction--column align-items--center">
      <h1 className="center-text Login-header">Set New Password</h1>
      <TextInput
        id="password"
        inputClasses="Login-input"
        type="password"
        placeholder="Password"
        wrapped={false}
      ></TextInput>
      <TextInput
        id="password-confirm"
        inputClasses="Login-input"
        type="password"
        placeholder="Confirm Password"
        wrapped={false}
      ></TextInput>
      <Button
        onClick={clickHandler}
        className="Login-button"
        disabled={loading}
      >
        {loading ? (
          <Spinner size="tiny" color="white" text={null} />
        ) : (
          "Reset and Login"
        )}
      </Button>
      <Message
        kind={useStatus.kind}
        message={useStatus.message}
        state={[useStatus, setStatus]}
      ></Message>
    </Container>
  ) : (
    <section className="flex align-items--center justify-content--center">
      <Message kind="warning" fixed>
        <p className={`App-message--text ml-24 font-size--11`}>
          Please request a <Link to="/reset-password">password reset</Link>{" "}
          first and follow the instructions in the password reset email.
        </p>
      </Message>
    </section>
  )
}

export { SetNewPasswordPage }
