import React from "react"
import { IconContainer } from "./IconContainer"

const MinusIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path d="M9.7,59.9c-2.6,0-4.7-2.1-4.7-4.7V44.8c0-2.6,2.1-4.7,4.7-4.7h80.7c2.6,0,4.7,2.1,4.7,4.7v10.4c0,2.6-2.1,4.7-4.7,4.7H9.7z" />
    </IconContainer>
  )
}

export { MinusIcon }
