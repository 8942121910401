import React from "react"

const BaseListItem = ({ children, containerClass, ...props }) => {
  return (
    <li {...props}>
      <div
        className={`${
          containerClass ||
          `flex flex-direction--row justify-content--space-between font-size--10`
        }`}
      >
        {children}
      </div>
    </li>
  )
}

export { BaseListItem }
