import React from 'react'
import { LeftArrowIcon, RightArrowIcon } from '../Icons'
import './CarouselButton.css'

const CarouselButton = (props) => {

  const { position, fill, className, size, ...other } = props
  return (
    <div {...other } className="App--carousel-button">
      { position === "left" && <span><LeftArrowIcon size={ size } className={ className } /></span> }
      { position === "right" && <span><RightArrowIcon size={ size } className={ className } /></span>}
    </div>
  )
}

export { CarouselButton }