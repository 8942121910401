import { clsnx } from "@becks256/clsnx"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { DialogBox, IconButton } from "../../Components"
import { ReturnIcon } from "../../Components/Icons"
import { useQuery } from "../../utils/useQuery"
import { Workspace } from "../ProjectWorkspace"
import {
  dashboardOverview,
  createProjectOverview,
  currentProjectsOverview,
  gridOverview,
  editStaticImage,
  editCustomImage,
  editDynamicImage,
  addImageOverview,
  addImageFromUpload,
  addImageFromGallery,
  manageTabOverview,
  revokeUserAccess,
} from "./assets"

import "./HelpPage.css"

const ReturnButton = ({ onClick }) => {
  return (
    <div className="App-HelpButton">
      <IconButton onClick={onClick} palette="special">
        <ReturnIcon size="medium" />
      </IconButton>
    </div>
  )
}

export const HelpPage = () => {
  const [helpSection, setHelpSection] = useState()
  const [activeVideo, setActiveVideo] = useState()
  const query = useQuery()
  const path = useRef(query.get("path"))
  const extraParams = useRef(query.has("params") ? query.get("params") : "")
  const history = useHistory()

  const sharedProjectVideos = [
    { src: gridOverview, label: "Project Grid Overview" },
    { src: editCustomImage, label: "How to Edit a Custom Image" },
    { src: editDynamicImage, label: "How to Edit a Dynamic Image" },
    { src: editStaticImage, label: "How to Edit a Static Image" },
    { src: addImageOverview, label: "Add an Image Basics" },
    { src: addImageFromUpload, label: "How to Upload an Image" },
    {
      src: addImageFromGallery,
      label: "How to Add an Image from the Gallery",
    },
  ]

  const COMPONENT_DATA = {
    dashboard: {
      id: "dashboard",
      headerText: "Dashboard",
      videos: [
        { src: dashboardOverview, label: "Dashboard Overview" },
        { src: createProjectOverview, label: "Create Project Overview" },
        { src: currentProjectsOverview, label: "Current Projects Overview" },
      ],
    },
    myProjects: {
      id: "my-projects",
      headerText: "Projects Overview",
      videos: [{ src: currentProjectsOverview, label: "Projects Overview" }],
    },
    project: {
      id: "projects",
      headerText: "Current Project",
      videos: [...sharedProjectVideos],
    },
    myTiles: {
      id: "my-tiles",
      headerText: "My Tiles",
      videos: [...sharedProjectVideos],
    },
    allTiles: {
      id: "all-tiles",
      headerText: "All Tiles",
      videos: [...sharedProjectVideos],
    },
    manageProject: {
      id: "manage-project",
      headerText: "Manage Project",
      videos: [
        { src: manageTabOverview, label: "Manage Project Overview" },
        { src: revokeUserAccess, label: "Revoke User Access" },
        ...sharedProjectVideos,
      ],
    },
  }

  const { dashboard, myProjects, project, myTiles, allTiles, manageProject } =
    COMPONENT_DATA

  useEffect(() => {
    switch (true) {
      case /\/dashboard/.test(path.current):
        setHelpSection(dashboard)
        setActiveVideo(dashboard.videos[0])
        break
      case /\/my-projects/.test(path.current):
        setHelpSection(myProjects)
        setActiveVideo(myProjects.videos[0])
        break
      case /\/projects/.test(path.current) && !!!extraParams.current:
        setHelpSection(project)
        setActiveVideo(project.videos[0])
        break
      case /\/projects/.test(path.current) &&
        /my-tiles/gi.test(extraParams.current):
        setHelpSection(myTiles)
        setActiveVideo(myTiles.videos[0])
        break
      case /\/projects/.test(path.current) &&
        /all-tiles/gi.test(extraParams.current):
        setHelpSection(allTiles)
        setActiveVideo(allTiles.videos[0])
        break
      case /\/projects/.test(path.current) &&
        /manage-project/gi.test(extraParams.current):
        setHelpSection(manageProject)
        setActiveVideo(manageProject.videos[0])
        break
      default:
        setHelpSection(dashboard)
        setActiveVideo(dashboard.videos[0])
        break
    }
    //eslint-disable-next-line
  }, [])

  const clickHandler = () => {
    const returnPath = path.current?.replace(/"|\/app\//gi, "")
    history.push(`${returnPath}`)
  }

  const radioClickHandler = (video) => setActiveVideo(video)

  return (
    <Workspace>
      {activeVideo && helpSection && (
        <DialogBox header={`Learning Center: ${helpSection.headerText}`}>
          <section className="flex flex-direction--column align-items--center">
            <div className="card bg-true-white p-24 mb-16">
              <h2 className="color-gray--50 regular center-text mb-48">
                {activeVideo.label}
              </h2>
              <video
                className="App-dashboard--video"
                style={{ width: "100%" }}
                controls
                src={activeVideo?.src}
                type="video/mp4"
              ></video>
            </div>
            <div className="card bg-true-white p-24 mb-16">
              <h2 className="color-gray--50 regular center-text mb-48">
                Additional Videos
              </h2>
              <section className="flex flex-direction--row my-16 justify-content--space-evenly flex-wrap">
                {helpSection?.videos?.map((video, index) => (
                  <label
                    htmlFor={`video-${index}`}
                    className="flex flex-direction--column align-items--center"
                  >
                    <input
                      key={video.label}
                      type="radio"
                      name="videos"
                      id={`video-${index}`}
                      defaultChecked={activeVideo.src === video.src}
                      onClick={() => {
                        radioClickHandler(video)
                      }}
                      className="App-helpPage--radio"
                    />
                    <span
                      className={clsnx(
                        "App-helpPage--video-link p-8 border-radius--smaller mb-16",
                        // { "active-link": activeVideo.src === video.src }
                      )}
                    >
                      {video.label}
                    </span>
                  </label>
                ))}
              </section>
            </div>
          </section>
        </DialogBox>
      )}
      <ReturnButton onClick={clickHandler} />
    </Workspace>
  )
}
