export const messages = {
  "session-expired": {
    visible: true,
    kind: "warning",
    message: "Your session has expired. Please login again to continue.",
  },
  success: {
    visible: true,
    kind: "success",
    message: "You have successfully logged out!",
  },
}
