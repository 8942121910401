import React, {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import { clsnx } from "@becks256/clsnx"
import { Message, Tabs, DynamicImage, DragAndDropUploader } from "../.."
import { IsMobileContext, SessionContext, ProjectContext } from "../../../App"
import { fontData } from "../../../fonts/fontData.js"
import { getImageData } from "../../../services/ImageServices"
import { api } from "../../../utils/api"
import {
  blankSvgDataString,
  calcInitialScaledTextSize,
} from "../../../utils/utils"

import {
  TextEditor,
  StatusMessage,
  ResetPositionButton,
  CloseButton,
  FontSelector,
  FontColorPicker,
  FontSizeSliderWithReadout,
  FontStrokeColorPicker,
  FontStrokeWidthSlider,
  LineHeightSlider,
} from "./Components"

const UnmemoizedImageEditorTab = ({
  dataString,
  setDataString,
  imageName,
  frontImageName = "",
  closeHandler,
  setIsBusy,
  isBusy,
  canEdit,
  isUpload,
}) => {
  const { session } = useContext(SessionContext)
  const { isMobile } = useContext(IsMobileContext)
  const { gridData, setGridData, projectName, projectOwner } =
    useContext(ProjectContext)

  const defaultStatus = { visible: false, kind: undefined, message: undefined }
  const [useStatus, setStatus] = useState(defaultStatus)
  const [projectFileName, setProjectFileName] = useState(null)
  const [text, setText] = useState(null)
  const [font, setFont] = useState(null)
  const [fontColor, setFontColor] = useState({
    name: undefined,
    hexValue: undefined,
  })
  const [textSize, setTextSize] = useState(null)
  const [defaultTextSize, setDefaultTextSize] = useState(null)
  const [scaleFactor, setScaleFactor] = useState(null)
  const [imageSize, setImageSize] = useState(null)
  const [saveStatus, setSaveStatus] = useState("Loading...")
  const [textStyles, setTextStyles] = useState({})
  const [autoSave, setAutoSave] = useState(0)
  const [controlledPos, setControlledPos] = useState({
    x: 0,
    y: 0,
  })
  const [strokeSize, setStrokeSize] = useState(0)
  const [strokeColor, setStrokeColor] = useState()
  const [isRearImage, setIsRearImage] = useState(!/_front/gi.test(imageName))
  const [isBlank, setIsBlank] = useState(dataString === blankSvgDataString)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [showTextSafeArea, setShowTextSafeArea] = useState(true)
  //const [uploadedImageName, setUploadedImageName] = useState(null)
  const [lineHeightScaleFactor, setLineHeightScaleFactor] = useState(1.8125)
  const textOverlay = useRef(null)
  const imageRef = useRef(null)
  const rangeVal = useRef(null)
  const initialRender = useRef(true)
  const timeoutID = useRef(null)
  const strokeWidthRef = useRef(null)

  const backImageName = useRef(
    /_back/gi.test(imageName) ? imageName : undefined
  )
  const setBackImageName = (name) => {
    backImageName.current = name
  }

  // use the getImageData service to get the image data from the server
  // and set the state variables
  const setImageData = useCallback(() => {
    const controller = new AbortController()
    const { signal } = controller
    getImageData({
      imageName: backImageName.current || imageName || frontImageName,
      projectName,
      projectOwner,
      projectFileName,
      session,
      email: false,
      signal, // pass the signal to the API call
    })
      .then(({ data, status, msg }) => {
        if (status === 200) {
          const { image_data, back_image_data } = data
          const isFront = /_front/gi.test(imageName)
          const imageData = isFront ? image_data : back_image_data
          setIsRearImage(!isFront)
          const scaledImageSize = imageRef.current.getBoundingClientRect().width
          const initialTextSize = calcInitialScaledTextSize(imageData.text_size)
          const initialScaleFactor = scaledImageSize / (2.75 * 96)
          setText(imageData.text_value || "")
          setFont(imageData.font_name || fontData[0]["font-family"])
          setFontColor({
            hexValue: imageData.font_color,
          })
          setTextSize(initialTextSize)
          setDefaultTextSize(initialTextSize)
          setScaleFactor(initialScaleFactor)
          setImageSize(scaledImageSize)
          setProjectFileName(imageData.file_name)
          setControlledPos({
            ...imageData.text_layer_bounds,
            x: imageData?.text_layer_bounds?.x * initialScaleFactor || 0,
            y: imageData?.text_layer_bounds?.y * initialScaleFactor || 0,
          })
          setStrokeSize(imageData.text_stroke_width)
          setStrokeColor(imageData.text_stroke_color)
          imageData.lineheight_scale &&
            setLineHeightScaleFactor(imageData.lineheight_scale)
        } else {
          setStatus({
            visible: true,
            kind: "error",
            message: msg,
          })
        }
      })
      .catch((err) => console.error(err))

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line
  }, [imageName])

  const updateGridData = ({ ...data }) => {
    const tempGridData = gridData
    const isBack = /_back/gi.test(imageName)
    const targetObj = isBack ? "back_image_data" : "image_data"
    const imageNameRE = new RegExp(imageName, "gi")
    let userIndex
    let imageIndex
    if (!backImageName.current || /_back/gi.test(backImageName.current)) {
      // eslint-disable-next-line array-callback-return
      tempGridData.project_array.map((obj, index) => {
        // eslint-disable-next-line array-callback-return
        obj.images.map((imgObj, imgIndex) => {
          const match = isBack
            ? imageNameRE.test(imgObj.back_image_data.file_name)
            : imageNameRE.test(imgObj.image_name)
          if (match) {
            imageIndex = imgIndex
            userIndex = index
          }
        })
      })
      if (userIndex > -1 && imageIndex > -1) {
        tempGridData.project_array[userIndex].images[imageIndex][
          `${targetObj}`
        ] = { ...data }
      }
      setGridData(tempGridData)
    } else {
      const fullBackName = backImageName.current.replace(
        /(.*?)(\.[a-z]+$)/gi,
        "$1_back$2"
      )
      // eslint-disable-next-line array-callback-return
      tempGridData.project_array.map((obj, index) => {
        // eslint-disable-next-line array-callback-return
        obj.images.map((imgObj, imgIndex) => {
          const match = new RegExp(frontImageName, "gi").test(imgObj.image_name)
          if (match) {
            imageIndex = imgIndex
            userIndex = index
          }
        })
      })
      if (userIndex > -1 && imageIndex > -1) {
        console.log({ file_name: fullBackName, ...data })
        tempGridData.project_array[userIndex].images[
          imageIndex
        ].back_image_data = { file_name: fullBackName, ...data }
      }
    }
  }

  useLayoutEffect(() => {
    //eslint-disable-next-line
    let isCancelled = false
    if (canEdit) {
      setIsBusy(true)
      setImageData()
    } else {
      setIsBusy(false)
    }

    return () => {
      isCancelled = true
    }
    // eslint-disable-next-line
  }, [dataString, isMobile, forceUpdate])

  useLayoutEffect(() => {
    if (canEdit) {
      let calculatedFontSize = textSize * scaleFactor
      let calculatedStrokeSize = strokeSize * scaleFactor
      setTextStyles({
        position: "absolute",
        display: "block",
        whiteSpace: "break-spaces",
        zIndex: 2,
        width: "auto",
        maxWidth: imageRef.current?.getBoundingClientRect().width,
        height: "auto",
        top: `${
          imageRef?.current?.getBoundingClientRect().width / 2 -
          textOverlay?.current?.getBoundingClientRect().height / 2
        }px`,
        left: `${
          imageRef?.current?.getBoundingClientRect().width / 2 -
          textOverlay?.current?.getBoundingClientRect().width / 2
        }px`,
        fontFamily: font,
        fontSize: `${calculatedFontSize}pt`,
        lineHeight: `${
          Math.ceil(textSize * lineHeightScaleFactor) * scaleFactor
        }px`,
        color: fontColor.hexValue,
        cursor: text ? "move" : "none",
        WebkitTextStroke:
          strokeSize && strokeColor
            ? `${calculatedStrokeSize}px ${strokeColor}`
            : undefined,
        MozTextStroke:
          strokeSize && strokeColor
            ? `${calculatedStrokeSize}px ${strokeColor}`
            : undefined,
        paintOrder: "stroke fill",
      })
      forceUpdate < 3 && setForceUpdate(forceUpdate + 1)

      if (initialRender.current) {
        initialRender.current = false
        return
      }
      save()
    } else {
      setIsBusy(false)
    }
    // eslint-disable-next-line
  }, [
    text,
    font,
    fontColor,
    textSize,
    imageSize,
    scaleFactor,
    controlledPos,
    strokeSize,
    strokeColor,
    lineHeightScaleFactor,
    isMobile,
    isBusy,
  ])

  useLayoutEffect(() => {}, [autoSave])

  const textInputHandler = (e) => {
    let counter = autoSave + 1
    setText(e.target.value)
    setAutoSave(counter)
  }
  const fontSelectHandler = (e) => {
    let counter = autoSave + 1
    setFont(e.target.value)
    setAutoSave(counter)
  }
  const fontSizeHandler = (e) => {
    let counter = autoSave + 1
    setTextSize(+e.target.value)
    setAutoSave(counter)
  }
  const strokeWidthHandler = (e) => {
    let counter = autoSave + 1
    setStrokeSize(+e.target.value)
    setAutoSave(counter)
  }
  const strokeColorHandler = (e) => {
    let counter = autoSave + 1
    setStrokeColor(e.target.value)
    setAutoSave(counter)
  }
  const fontColorHandler = (e) => {
    if (/^#[A-F0-9]{6}/gi.test(e.target.value)) {
      let counter = autoSave + 1
      setFontColor({ name: e.target.innerText, hexValue: e.target.value })
      setAutoSave(counter)
    }
  }
  const lineHeightHandler = (e) => {
    let counter = autoSave + 1
    setLineHeightScaleFactor(+e.target.value)
    setAutoSave(counter)
  }

  const submitHandler = (e) => {
    setSaveStatus("Saving...")
    const imageData = {
      text_value: text,
      text_size: textSize,
      text_stroke_width: strokeSize,
      text_stroke_color: strokeColor,
      lineheight_scale: lineHeightScaleFactor,
      font_name: font || fontData[0]["font-family"],
      font_color: fontColor.hexValue || "#FFFFFF",
      image_size: imageSize,
      text_layer_bounds: {
        ...controlledPos,
        x: controlledPos.x / scaleFactor,
        y: controlledPos.y / scaleFactor,
      },
    }

    const isBackImage = !/_front/gi.test(imageName)
    const isBlankImage = /^data:image\/svg\+xml/g.test(dataString)
    fetch(api.saveImage, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image_name: isBlankImage
          ? frontImageName
          : isBackImage
          ? backImageName.current || imageName
          : imageName,
        is_back_image: isBackImage,
        is_blank_image: isBlankImage,
        file_name: projectFileName,
        email: session.email,
        owner: projectOwner,
        project_name: projectName,
        token: session.token,
        image_data: {
          ...imageData,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSaveStatus(data.status === 200 && data.msg)
        setIsBusy(false)
        updateGridData({
          ...imageData,
        })
      })
  }

  const save = (delay = 700) => {
    timeoutID.current && clearTimeout(timeoutID.current)

    timeoutID.current = setTimeout(() => {
      submitHandler()
    }, delay)
  }

  const onControlledDrag = (e, position) => {
    const { x, y } = position
    setControlledPos({ x, y })
  }

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position)
  }

  //a handler to show an overlay when indicating the text safe area
  const showOverlayHandler = () => {
    setShowTextSafeArea(!showTextSafeArea)
  }

  const cutoutStyle = React.useMemo(() => {
    const factor = scaleFactor || 0
    const size = 0.1875 * 96 * factor - 4
    const placement = 0.25 * 96 * factor

    const side = isRearImage ? "right" : "left"

    return {
      width: size + "px",
      height: size + "px",
      [side]: placement + "px",
      top: placement + "px",
      borderRadius: "625rem",
      backgroundColor: "var(--color-gray-50)",
      border: "dashed 2px var(--color-error-90)",
      zIndex: 6,
    }
    // eslint-disable-next-line
  }, [scaleFactor])

  return (
    <>
      <section className="App-image-editor--container flex sm:flex-direction--column flex-direction--row p-32 bg-true-white">
        <div
          ref={imageRef}
          className={clsnx(
            "md:mr-48 relative box-sizing--border-box overflow--hidden h-min-content App-image-editor--imageWindow",
            {
              "text-safe-area":
                isBlank && showTextSafeArea && text
                  ? true
                  : !isBlank && showTextSafeArea
                  ? true
                  : false,
            }
          )}
        >
          {showTextSafeArea && (
            <div className="absolute" style={{ ...cutoutStyle }}></div>
          )}
          {!text && isBlank && isUpload && isRearImage && (
            <section className="absolute w-100 h-100 box-sizing--border-box">
              <DragAndDropUploader
                accept=".jpeg, .jpg, .png, .tif"
                className="w-100 h-100"
                imageName={frontImageName}
                setDataString={setDataString}
                setIsBlank={setIsBlank}
                imageSize={imageRef?.current?.getBoundingClientRect()?.width}
                setBackImageName={setBackImageName}
              />
            </section>
          )}
          <DynamicImage
            id="image"
            dataString={dataString}
            className="App-image-editor--image"
            containerClass={clsnx("flex outline-solid outline-offset--1px", {
              "App-image-editor--disabled-img": !canEdit,
            })}
            boundsSelector=".App-image-editor--image"
            onDrag={onControlledDrag}
            onStop={onControlledDragStop}
            editable
            text={text}
            controlledPos={controlledPos}
            textOverlay={textOverlay}
            textStyles={textStyles}
          />
        </div>
        <section className="App-image-editor--tabbed-section sm:mt-16">
          <Tabs
            status={saveStatus}
            tabs={[
              {
                name: "image text",
                content: (
                  <div>
                    <TextEditor
                      {...{
                        canEdit,
                        text,
                        textInputHandler,
                        showOverlayHandler,
                        showTextSafeArea,
                      }}
                    />
                    <FontSelector
                      fontSelectHandler={fontSelectHandler}
                      canEdit={canEdit}
                      font={font}
                      fontData={fontData}
                    />
                    <FontSizeSliderWithReadout
                      canEdit={canEdit}
                      textSize={textSize}
                      defaultTextSize={defaultTextSize}
                      fontSizeHandler={fontSizeHandler}
                      rangeVal={rangeVal}
                    />
                    <StatusMessage canEdit={canEdit} saveStatus={saveStatus} />
                    <section className="flex flex-direction--row align-items--center w-100 box-sizing--border-box">
                      <ResetPositionButton
                        clickHandler={() => setControlledPos({ x: 0, y: 0 })}
                        isBusy={isBusy}
                      />
                      <CloseButton
                        closeHandler={closeHandler}
                        isBusy={isBusy}
                      />
                    </section>
                  </div>
                ),
              },
              {
                name: "text styles",
                content: (
                  <>
                    <FontColorPicker
                      fontColor={fontColor}
                      fontColorHandler={fontColorHandler}
                    />
                    <FontStrokeColorPicker
                      strokeColor={strokeColor}
                      strokeColorHandler={strokeColorHandler}
                    />
                    <FontStrokeWidthSlider
                      canEdit={canEdit}
                      strokeWidth={strokeSize}
                      strokeWidthHandler={strokeWidthHandler}
                      strokeWidthRef={strokeWidthRef}
                      fontSize={textSize}
                    />
                    <LineHeightSlider
                      minValue={1}
                      maxValue={5}
                      step={0.01}
                      value={lineHeightScaleFactor}
                      displayValue={Math.floor(
                        Math.ceil(textSize * lineHeightScaleFactor) *
                          scaleFactor
                      )}
                      onChange={lineHeightHandler}
                      disabled={!canEdit}
                    />
                    <StatusMessage canEdit={canEdit} saveStatus={saveStatus} />
                    <section className="flex flex-direction--row align-items--center w-100 box-sizing--border-box">
                      <ResetPositionButton
                        clickHandler={() => setControlledPos({ x: 0, y: 0 })}
                        isBusy={isBusy}
                      />
                      <CloseButton
                        closeHandler={closeHandler}
                        isBusy={isBusy}
                      />
                    </section>
                  </>
                ),
              },
            ]}
          />
        </section>
      </section>
      {useStatus.visible && (
        <Message
          kind={useStatus.kind}
          message={useStatus.message}
          state={[useStatus, setStatus]}
        ></Message>
      )}
    </>
  )
}

//const ImageEditorTab = memo(UnmemoizedImageEditorTab)

export { UnmemoizedImageEditorTab as ImageEditorTab }
