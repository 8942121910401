import React, { useContext, useState, useEffect } from "react"
import { ShoppingCartContext } from "../../../App"
import { InlineMessage, Spinner, TextInput } from "../../../Components"
import { SoulfolioIcon, TrashIcon } from "../../../Components/Icons"
import { api } from "../../../utils/api"
import {
  calculateDiscount,
  calculateGrandTotal,
  getBasePrice,
} from "../../../utils/utils"
import { BaseListItem, PromoListItem, ListItem } from "./Components"
import "./ShoppingCartList.css"

const ShoppingCartList = ({
  shippingRate = { value: 0 },
  isTaxable,
  giftWrap,
  promoState,
}) => {
  const defaultPricing = {
    shipping: 0,
    taxes: 0,
    total: 0,
    grandTotal: 0,
  }
  const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext)
  const [pricing, setPricing] = useState({ ...defaultPricing })
  const [promo, setPromo] = promoState
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [pricePerUnit, setPricePerUnit] = useState(0)
  const ohTax = 0.07

  const removeFromCartHandler = (project_name) => (e) => {
    setPricing({ ...defaultPricing })
    const filteredArray = shoppingCart.filter(
      (obj, index) => obj.project_name !== project_name
    )
    setShoppingCart(filteredArray)
  }

  const inputHandler = (e) => {
    const v = e.target.value.toUpperCase()
    setInputValue(v)
    if (e.target.value?.length >= 10) {
      e.target.disabled = true
      setLoading(true)
      fetch(api.verifyPromoCode({ promoCode: v }), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => setPromo(data))
        .then(() => {
          e.target.disabled = false
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    const giftWrapRate = giftWrap ? 8.99 : 0
    const unitCount = shoppingCart.reduce(
      (acc, curr) => acc + Number(curr.tile_count),
      0
    )
    const baseUnitPrice = getBasePrice(+unitCount)
    setPricePerUnit(baseUnitPrice)
    const basePrice = baseUnitPrice * unitCount
    const total = calculateGrandTotal({
      isTaxable,
      basePrice,
      ohTax,
      shippingRate,
      giftWrapRate,
    })
    const grandTotal = promo ? calculateDiscount({ promo, total }) : total
    shoppingCart.length > 0 &&
      setPricing({
        shipping: +shippingRate.value,
        taxes: isTaxable ? +basePrice * +ohTax : 0,
        total: +basePrice,
        grandTotal: grandTotal,
        giftWrap: giftWrapRate,
      })
  }, [shoppingCart, shippingRate, isTaxable, giftWrap, promo])

  return (
    shoppingCart.length > 0 && (
      <ul className="App-checkout--ul md:mr-24 mt-0 sm:w-full w-40">
        <li className="App-checkout--header-row">
          <div className="flex flex-direction--row justify-content--space-between align-items--flex-end">
            <p className="paragraph paragraph-l mt-0 color-gray--50">
              Item Description
            </p>
            <p className="paragraph paragraph-l mt-0 color-gray--50">Price</p>
          </div>
        </li>
        {shoppingCart.map(({ project_name, tile_count }, index) => (
          <li
            key={`${index}-${project_name}`}
            className="App-checkout--item-rows"
          >
            <div className="flex flex-direction--row justify-content--space-between align-items--flex-end">
              <div className="flex flex-direction--row align-items--flex-start">
                <span className="mr-16 mt-16">
                  <TrashIcon
                    size="medium"
                    className="App-checkout--delete-btn"
                    onClick={(e) => {
                      removeFromCartHandler(project_name)(e)
                    }}
                  />
                </span>
                <div>
                  <p className="font-size--14 my-16">{project_name}</p>
                  <div className="flex flex-direction--row align-items--center">
                    <SoulfolioIcon
                      size="medium"
                      fill="currentColor"
                      className="mr-16 color-dynamic-text"
                    />
                    <p className="font-size--10 w-70">
                      Soulfolio with {tile_count} tiles
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p>$ {(+tile_count * pricePerUnit).toFixed(2)}</p>
              </div>
            </div>
          </li>
        ))}
        <BaseListItem className="App-checkout--footer-row">
          <div>
            <p className="paragraph paragraph-m pr-16">Promo Code: </p>
          </div>
          <div className="flex align-items--center">
            <div>
              {loading ? (
                <Spinner size="micro" text={undefined} />
              ) : (
                <>
                  <TextInput
                    type="text"
                    title="promo-code"
                    onChange={inputHandler}
                    compact
                    value={inputValue}
                    maxLength={12}
                    wrapperClasses="py-8"
                    placeholder="Enter code here"
                    inputClasses="App-checkout--input"
                  />
                  {promo?.status && (
                    <InlineMessage
                      kind={promo.status === 400 ? "error" : "success"}
                      message={
                        promo.status === 400
                          ? "That code is invalid."
                          : "Discount applied!"
                      }
                      className="mb-8"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </BaseListItem>
        {promo?.discount && (
          <PromoListItem
            label="Discount:"
            value={`${promo.discount}${promo.fixed_amount ? "" : "%"}`}
            identifier={promo.fixed_amount && "$"}
          />
        )}
        {giftWrap && (
          <ListItem
            label="Add gift wrap"
            value={pricing?.giftWrap > 0 ? pricing.giftWrap.toFixed(2) : "-"}
          />
        )}
        <ListItem
          label="Shipping &amp; handling:"
          value={pricing?.shipping > 0 ? pricing.shipping.toFixed(2) : "-"}
        />
        <ListItem
          label="Total before tax:"
          value={
            pricing?.total > 0
              ? promo?.fixed_amount || promo?.discount
                ? calculateDiscount({ promo, total: pricing.total }).toFixed(2)
                : pricing.total.toFixed(2)
              : "-"
          }
        />
        <ListItem
          label="Estimated tax:"
          value={pricing?.taxes > 0 ? pricing.taxes.toFixed(2) : "-"}
        />
        <BaseListItem containerClass="App-order-total-container flex flex-direction--row justify-content--space-between font-size--18 px-16 mt-32">
          <div>
            <p className="pr-16">Order total:</p>
          </div>
          <div className="flex justify-content--flex-end">
            <div>
              <p className="pr-8">$</p>
            </div>
            <div className="App-shopping-cart--total">
              <p className="relative" style={{ zIndex: "1" }}>
                {pricing?.grandTotal > 0 ? pricing.grandTotal.toFixed(2) : "-"}
              </p>
            </div>
          </div>
        </BaseListItem>
      </ul>
    )
  )
}

export { ShoppingCartList }
