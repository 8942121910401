import React, {
  useState,
  useCallback,
  useContext,
} from "react"
import { Spinner, Message } from "../.."
import { InstanceModal as Modal } from "../../InstanceModal/InstanceModal"
import { ImageEditorTab } from "./ImageEditorTab"
import { fetchDataString } from "../../../services/ImageServices"
import "./EditIconWithEditorModal.css"
import { blankSvgDataString } from "../../../utils/utils"
import { EditIcon } from "../../Icons"
import { ProjectContext } from "../../../App"

export const EditIconWithEditorModal = ({
  tileData,
  imageName,
  backImageName,
  isUpload,
  canEdit,
}) => {
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [tileFront, setTileFront] = useState(null)
  const [tileBack, setTileBack] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState("front")
  const [isBusy, setIsBusy] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const { refreshGridData } = useContext(ProjectContext)

  const frontId = "front",
    backId = "back"

  // useLayoutEffect(() => {
  //   let ignore = false

  //   !loading && setLoading(true)

  //   return () => {
  //     ignore = true
  //   }
  //   // eslint-disable-next-line
  // }, [imageName, backImageName])

  const fetchTileData = useCallback(() => {
    let ignore = false
    const frontImagePromise = fetchDataString({
      side: "front",
      imageName,
      backImageName,
      size: "large",
    }).then(
      (data) => !ignore && setTileFront(data === "data:" || !data ? null : data)
    )

    let backImagePromise

    if (backImageName) {
      backImagePromise = fetchDataString({
        side: "back",
        imageName,
        backImageName,
        size: "large",
      }).then(
        (data) =>
          !ignore &&
          setTileBack(data === "data:" || !data ? blankSvgDataString : data)
      )
    } else {
      backImagePromise = Promise.resolve(
        !ignore && setTileBack(blankSvgDataString)
      )
    }

    !ignore && setLoading(false)
    return Promise.all([frontImagePromise, backImagePromise])
  }, [imageName, backImageName])

  const closeHandler = useCallback(() => {
    setCurrentTab(frontId)
    setIsVisible(false)
    refreshGridData()
    // eslint-disable-next-line
  }, [])

  const openHandler = useCallback(async () => {
    setIsVisible(true)
    await fetchTileData()
    // eslint-disable-next-line
  }, [])

  const tabClickHandler = useCallback((e) => {
    !isBusy && setCurrentTab(e.target.id)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <EditIcon
        bordered
        className="faux-link image-tool m-8"
        size="large"
        onClick={openHandler}
      />
      {isVisible && (
        <Modal
          title="Image Editor"
          contentClassName="px-0"
          dialogClassName="App-image-editor--modal bg-gray--10--static"
          headerTextColor="gray--90"
          onClose={closeHandler}
          show={isVisible}
        >
          {loading ? (
            <section
              className="flex align-items--center justify-content--center"
              style={{ width: "100%", height: "65vh" }}
            >
              <Spinner />
            </section>
          ) : (
            <>
              <div className="mx-24">
                <Message
                  kind={useStatus.kind}
                  message={useStatus.message}
                  state={[useStatus, setStatus]}
                ></Message>
              </div>
              <section className="App-image-editor--tab-bar">
                <span
                  id={frontId}
                  onClick={tabClickHandler}
                  className={`App-image-editor--tab ${
                    currentTab === frontId ? "active" : ""
                  } ${isBusy ? "button-disabled" : ""}`}
                >
                  Tile Front
                </span>
                <span
                  id={backId}
                  onClick={tabClickHandler}
                  className={`App-image-editor--tab ${
                    currentTab === backId ? "active" : ""
                  } ${isBusy ? "button-disabled" : ""}`}
                >
                  Tile Back
                </span>
              </section>
              {currentTab === frontId && tileFront && (
                <ImageEditorTab
                  tileData={tileData}
                  dataString={tileFront}
                  setDataString={setTileFront}
                  imageName={imageName}
                  closeHandler={closeHandler}
                  setIsBusy={setIsBusy}
                  isBusy={isBusy}
                  canEdit={canEdit}
                />
              )}
              {currentTab === backId && tileBack && (
                <ImageEditorTab
                  tileData={tileData}
                  dataString={tileBack}
                  setDataString={setTileBack}
                  imageName={backImageName}
                  frontImageName={imageName}
                  closeHandler={closeHandler}
                  setIsBusy={setIsBusy}
                  isBusy={isBusy}
                  canEdit={true}
                  isUpload={isUpload}
                />
              )}
            </>
          )}
        </Modal>
      )}
    </>
  )
}
