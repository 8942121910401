import { clsnx } from "@becks256/clsnx"
import React from "react"

const IconContainer = ({ size = "small", children, className='', ...props }) => {
  return (
    <i {...props} className={clsnx(className, `icon-${size}`)}>
      <svg x="0px" y="0px" viewBox="0 0 100 100">
        {children}
      </svg>
    </i>
  )
}

export { IconContainer }
