import { clsnx } from "@becks256/clsnx"
import React, { memo } from "react"
import { AddImageIcon, DisabledImageIcon } from "../../Icons"

const UnmemoizedEmptyTileMapper = ({
  tiles,
  images,
  parentIndex,
  mapToParent = false,
  isMyTileView = false,
  noImageClickHandler,
}) => {
  return new Array(tiles - images.length).fill("").map((a, childIndex) => (
    <div
      key={`tile-${parentIndex}-${childIndex}`}
      className={clsnx(
        "flex justify-content--center align-items--center",
        `App-project-grid--tile${
          parentIndex === 0 && mapToParent ? "-active" : ""
        }`,
        {
          "faux-link": parentIndex === 0 && mapToParent,
          "App-project-grid--my-tile": isMyTileView,
        }
      )}
      onClick={parentIndex === 0 && mapToParent ? noImageClickHandler : null}
    >
      {parentIndex === 0 && mapToParent ? (
        <AddImageIcon
          key={`add-image-icon-${childIndex}`}
          size="xtra-large"
          alt="add image"
        />
      ) : (
        <DisabledImageIcon
          key={`disabled-add-image-icon-${childIndex}`}
          size="xtra-large"
          alt="add image disabled"
        />
      )}
    </div>
  ))
}

const EmptyTileMapper = memo(UnmemoizedEmptyTileMapper)
export { EmptyTileMapper }
