import React from 'react'
import { IconContainer } from './IconContainer'

const HeartIcon = ({ strokeFill, size, className, ...props }) => {

  return (
    <IconContainer {...props} className={className} size={size} >
      <path id="Shape" d="M73.3,3.3c-9.8,0-18.7,5.4-23.3,14.2C45.5,8.7,36.5,3.3,26.7,3.3C12.3,3.3,0.5,15,0.5,29.5l0,1.4
				c0.1,3.2,1.4,14.4,11,25.1c11.4,12.7,30.9,33.4,36.6,39.4l0,0c1,1,2.6,1,3.6,0l0,0c5.7-6.1,25.3-26.8,36.6-39.4
				c9.2-10.2,10.8-20.7,11-24.7l0-1.7C99.5,15.1,87.7,3.3,73.3,3.3z"/>
      <path id="Stroke" fill={ strokeFill } d="M73.3,8.3c11.7,0,21.2,9.5,21.2,21.1c0,0.1,0,0.2,0,0.3l0,1.1c-0.1,2.8-1.3,12.4-9.7,21.8
				c-9.8,10.9-25.6,27.8-33.6,36.2c-0.6,0.7-1.7,0.7-2.4,0c-7.9-8.4-23.7-25.2-33.6-36.2C6.9,43.3,5.7,34.1,5.5,31l0-1.3
				c0-0.1,0-0.2,0-0.3C5.6,17.8,15.1,8.3,26.7,8.3c7.9,0,15.2,4.4,18.8,11.5l2.3,4.3c0.9,1.8,3.4,1.8,4.4,0l2.3-4.3
				C58.1,12.7,65.3,8.3,73.3,8.3 M73.3,3.3C63.1,3.3,54.4,9.1,50,17.5C45.6,9.1,36.9,3.3,26.7,3.3C12.2,3.3,0.5,15.1,0.5,29.5
				c0,0.1,0,0.1,0,0.2h0c0,0-0.6,13.3,11,26.2C23,68.7,42.4,89.2,48.3,95.4c1,1,2.5,1,3.5,0c5.9-6.2,25.2-26.8,36.7-39.5
				c11.6-12.9,11-26.2,11-26.2h0c0-0.1,0-0.1,0-0.2C99.5,15.1,87.8,3.3,73.3,3.3L73.3,3.3z"/>
    </IconContainer>
  )
}

export { HeartIcon }