import React from "react"
import { Button } from "../../../"

const CloseButton = ({ closeHandler, isBusy }) => {
  return (
    <Button
      onClick={closeHandler}
      className="mt-8 ml-8 box-sizing--border-box w-unset w-50"
      disabled={isBusy}
      compact
    >
      Close
    </Button>
  )
}

export { CloseButton }
