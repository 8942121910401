import React from 'react'
import { getCurrentYear } from '../../utils/utils'
import './Footer.css'

const Footer = ({linkGroupOne, linkGroupTwo, linkGroupThree}) => {
  const currentYear = getCurrentYear()
  return (
    <footer className={`App-footer`}>
      <div className="grid-3c">
        <ul className="justify-content--center">
          {linkGroupOne?.length > 0 && linkGroupOne.map((link, index) => <li key={`li-groupOne-${index}`}><a href={link.href}>{link.text}</a></li>)}
        </ul>
        <ul>
          {linkGroupTwo?.length > 0 && linkGroupTwo.map((link, index) => <li key={`li-groupTwo-${index}`}><a href={link.href}>{link.text}</a></li>)}
        </ul>
        <ul>
          {linkGroupThree?.length > 0 && linkGroupThree.map((link, index) => <li key={`li-groupThree-${index}`}><a href={link.href}>{link.text}</a></li>)}
        </ul>
      </div>
      <section className="flex align-items--center justify-content--center">
        <p className="paragraph paragraph-s">&copy; 2022{currentYear > 2022 ? ` - ${currentYear}` : ''} Soulfolio Co. All Rights Reserved.</p>
      </section>
    </footer>
  )
}

export { Footer }