import React from "react"

const ShippingRates = ({ componentShippingRates, clickHandler, promo }) => {
  return (
    <>
      {componentShippingRates.rates.map(
        ({ service, carrier, rate, est_delivery_days, id }, index) => (
          <label
            className="flex align-items--center my-16"
            htmlFor={`shipping-rate-${index}`}
            key={`shipping-rate-label-key-${index}`}
          >
            <input
              key={`shipping-rate-input-key-${index}`}
              className="App-checkout--radio-button"
              type="radio"
              name="shipping-rates"
              id={`shipping-rate-${index}`}
              onChange={(e) => {
                clickHandler(e)({ rate_id: id })
              }}
              value={rate}
            ></input>
            {
              <span className="font-size--14 ml-8 md:ml-16 bold">{`${
                promo?.free_shipping
                  ? `FREE`
                  : `$${rate}`
              }`}</span>
            }
            {
              <span className="color-gray--60 ml-8 md:ml-16 font-size--14">
                {carrier} - {service}: {est_delivery_days} day
              </span>
            }
          </label>
        )
      )}
    </>
  )
}

export { ShippingRates }
