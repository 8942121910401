import React from "react"
import { Select } from "../../../"

const FontSelector = ({ fontSelectHandler, canEdit, font, fontData }) => {
  return (
    <Select
      disabled={!canEdit}
      title="font name"
      label="Font Name"
      wrapperClasses="mb-16"
      labelClasses="App-image-editor--label mb-8"
      inputClasses="border-radius--smaller App-image-editor--input sm:w-full box-sizing--border-box"
      onChange={fontSelectHandler}
      value={font || fontData[0]["font-family"]}
      size="3"
    >
      {fontData &&
        fontData.map((obj, index) => (
          <option
            key={`font-option-${index}`}
            value={obj["font-family"]}
            className="sm:py-16 md:py-8"
            style={{
              fontFamily: `"${obj["font-family"]}"`,
              fontSize: "12pt",
            }}
          >
            {obj["font-family"]}
          </option>
        ))}
    </Select>
  )
}

export { FontSelector }
