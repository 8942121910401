import React, { useContext, useState } from "react"
import { ManageTileCountStepper } from "./"
import { ProjectContext } from "../../../App"
import { TileMapper } from "../TileComponents/TileMapper"
import { EmptyTileMapper } from "../TileComponents/EmptyTileMapper"

export const UserTab = ({ index, tiles, email, images, deleteHandler }) => {
  const [offset, setOffset] = useState(0)

  const { projectName } = useContext(ProjectContext)
  return (
    <>
      <div className="flex flex-direction--column align-items--center justify-content--center w-full">
        <h4 className="my-8">Modify User Tile Count:</h4>
        <ManageTileCountStepper
          key={`stepper-${index}`}
          tiles={tiles}
          contributorEmail={email}
          projectName={projectName}
          onChange={setOffset}
          disabled={!email}
        />
      </div>
      <div
        key={`tile-wrapper-${index}`}
        className="flex flex-direction--row flex-wrap justify-content--center pt-24 App-GridTileWrapper"
      >
        <TileMapper images={images} deleteHandler={deleteHandler} />
        <EmptyTileMapper tiles={(tiles + offset)} images={images} parentIndex={index} />
        {/* {Children.toArray(
          images.map((item, i) => {
            const { image_name, canEdit, back_image_data, isUpload } = item
            return (
              <GridTile
                tileData={item}
                imageName={image_name}
                backImageName={back_image_data.file_name}
                canEdit={canEdit}
                deleteHandler={deleteHandler}
                isUpload={isUpload}
                id={`dynamic-image--${i}`}
                className="App-project-grid--image"
                boundsSelector=".App-project-grid--image"
                containerClass="App-project-grid--image-container"
                alt=""
                wrapperClasses="mx-16 mt-16 md:mb-16 outline-solid border-radius--smaller"
              />
            )
          })
        )} */}
        {/* {new Array((tiles + offset) - images.length).fill(0).map((item, i) => (
          <div
            key={`tile-${index}-${i}`}
            className={`App-project-grid--tile flex justify-content--center align-items--center m-16`}
          >
            <DisabledImageIcon
              key={`disabled-add-image-icon-${i}`}
              size="xtra-large"
              alt="add image disabled"
            />
          </div>
        ))} */}
      </div>
    </>
  )
}
