import React from "react"

const LineHeightSlider = ({
  minValue,
  maxValue,
  step,
  value,
  displayValue,
  onChange,
  disabled,
}) => {
  return (
    <section className="flex flex-direction--column">
      <label className="flex" htmlFor="font-size">
        Line Height
      </label>
      <section className="flex flex-direction--row w-full mb-16">
        <input
          disabled={disabled}
          name="font-size"
          className="App-image-editor--range box-sizing--border-box"
          type="range"
          value={value}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={onChange}
        ></input>
        <input
          style={{
            width: "2rem",
            height: "0.5rem",
            textAlign: "center",
            margin: "0 0 0 8px",
            borderRadius: "var(--border-radius--smaller)",
          }}
          type="number"
          value={displayValue || ""}
          className="App-number-field"
          disabled
        ></input>
      </section>
    </section>
  )
}

export { LineHeightSlider }
