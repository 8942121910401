import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { SessionContext } from "../../App"

const PageNotFound = () => {
  const { session } = useContext(SessionContext)
  let history = useHistory()

  useEffect(() => {
    history.push(session?.token ? "/design/dashboard" : "/")
  })

  return (
    <>
      <h1 className="font-size--36">404</h1>
      <p className="paragrah">
        Oops. Looks like you stumbled across something that's missing...or
        perhaps you were poking around where you shouldn't?
      </p>
    </>
  )
}

export { PageNotFound }
