import React from "react"
import { IconContainer } from "./IconContainer"

const EditIcon = (props) => {
  const { fill, size, className, bordered, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size}>
      {bordered ? (
        <>
          <path
            d="M89.7,37.9l-4,4c-0.4,0.4-0.6,0.8-0.6,1.3v42c0,4.1-3.3,7.4-7.4,7.4h-63c-4.1,0-7.4-3.3-7.4-7.4v-63c0-4.1,3.3-7.4,7.4-7.4
              h42c0.5,0,0.8-0.2,1.2-0.5l4-4c1.1-1.1,0.4-3-1.1-3H14.8C6.7,7.4,0,14.1,0,22.2v63C0,93.3,6.7,100,14.8,100h63
              c8.1,0,14.8-6.7,14.8-14.8V39.1C92.6,37.6,90.7,36.9,89.7,37.9z"
          />
          <path
            d="M32.3,55.7l-2.9,10.7c-0.7,2.5,1.6,4.8,4.1,4.1l10.7-2.9c0.3-0.1,0.8-0.5,0.9-0.6l45.2-45.1v-0.1L78.1,9.7H78L32.9,55
              C32.8,55,32.4,55.4,32.3,55.7z"
          />
          <path d="M99.3,9.7l-9-9c-0.9-0.9-2.4-0.9-3.3,0l-6.8,6.8l12.3,12.3l6.8-6.8C100.2,12.1,100.2,10.6,99.3,9.7z" />
        </>
      ) : (
        <>
          <path
            d="M6.3,75.3l-3.7,14c-0.9,3.2,4.8,9,8,8l14-3.7c0.4-0.1,1-0.6,1.2-0.8L82,36.7v-0.1L63.4,18h-0.1L7.1,74.3
		C7,74.3,6.5,74.9,6.3,75.3z"
          />
          <path d="M87.8,31.1l8.9-8.9c1.2-1.2,1.2-3.1,0-4.3L82.1,3.4c-1.2-1.2-3.1-1.2-4.3,0L69,12.3L87.8,31.1z" />
        </>
      )}
    </IconContainer>
  )
}

export { EditIcon }
