import React, { useRef, useState, useContext, useEffect } from "react"
import { api } from "../../../utils/api"
import { Message, Button } from "../../"
import { EditableField } from "./"
import { ProjectContext } from "../../../App"

const ManageTileCountField = () => {
  const { gridData, refreshGridData } = useContext(ProjectContext)
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(0)
  const [status, setStatus] = useState({
    message: "",
    kind: undefined,
    visible: false,
  })

  useEffect(() => {
    setInputValue(gridData.tile_count)
  }, [gridData.tile_count])

  const forceOverride = useRef(false)
  const initialValue = useRef(gridData.tile_count)

  const editHandler = () => {
    setIsEditing(true)
  }

  const saveHandler = () => {
    // console.log(+inputValue, +initialValue.current)
    if (+inputValue < +initialValue.current && !forceOverride.current) {
      setStatus({
        message: (
          <>
            <p className="App-message--text ml-16 font-size--11">
              <b>Warning!</b> The number of tiles specified is less than the
              current number of tiles assigned. Clicking continue will
              recalculate the total number of tiles for each contributor and may
              cause some images to be lost.
              <br />
              <br />
              {/* To remove tiles on a user by user basis, scroll down to the Images
              Selections by Contributor section below.
              <br />
              <br /> */}
            </p>
            <Button border className="float-right" onClick={forceSaveHandler}>
              Continue
            </Button>
          </>
        ),
        kind: "warning",
        visible: true,
      })
      return
    }
    const { project_name, owner, email } = gridData
    setIsEditing(false)
    fetch(api.modifyTileCount, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tile_count: +inputValue,
        project_name: project_name,
        owner: owner || email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        initialValue.current = inputValue
        forceOverride.current = false
        refreshGridData()
      })
  }

  const changeHandler = (e) => {
    setInputValue(e.currentTarget.value)
  }

  const cancelHandler = () => {
    setIsEditing(false)
    setStatus({
      message: "",
      kind: undefined,
      visible: false,
    })
    setInputValue(initialValue.current)
  }

  const forceSaveHandler = () => {
    setStatus({
      message: "",
      kind: undefined,
      visible: false,
    })
    forceOverride.current = true
    saveHandler()
  }

  return (
    <>
      <EditableField
        label="Tile Count: "
        title="tile_count"
        defaultValue={inputValue}
        isEditing={isEditing}
        onEdit={editHandler}
        onSave={saveHandler}
        onCancel={cancelHandler}
        onChange={changeHandler}
      />
      {status.visible && (
        <Message kind={status.kind} fixed>
          {status.message}
        </Message>
      )}
    </>
  )
}

export { ManageTileCountField }
