import React, { useContext, memo } from "react"
import { AddImageIcon } from "../Icons"
import { IsMobileContext, SessionContext, ProjectContext } from "../../App"
import { AddImageTiles } from "./TileComponents/AddImageTiles"
import { GridTile } from "./GridTile"

const UnmemoizedMyTilesTab = ({ deleteHandler, noImageClickHandler }) => {
  const {
    session: { email },
  } = useContext(SessionContext)
  const { isMobile } = useContext(IsMobileContext)
  const { gridData } = useContext(ProjectContext)

  return (
    <>
      <section className="border-bottom">
        <h2 className="pt-24">Image selection and management</h2>
        <>
          {isMobile ? (
            <p
              className="paragraph paragraph--l my-16"
              style={{ lineHeight: "26px" }}
            >
              This is an overview of your Soulfolio. Tap on any "Add Image" ({" "}
              {
                <AddImageIcon
                  className="inline-block vertical-align--middle color-dynamic-text"
                  size="medium"
                />
              }{" "}
              ) tile below to get started, or tap any tool icon below a finished
              image to preview, edit, or remove an image from your selection.{" "}
            </p>
          ) : (
            <p className="paragraph paragraph--l my-16">
              This is an overview of your Soulfolio. Click an "Add Image" ({" "}
              {
                <AddImageIcon
                  className="inline-block vertical-align--middle color-dynamic-text"
                  size="medium"
                />
              }{" "}
              ) tile below to get started, or hover over any finished image to
              preview, edit, or remove an image from your selection.{" "}
            </p>
          )}
        </>
      </section>
      <section className="flex flex-direction--row flex-wrap justify-content--center pt-24 App-GridTileWrapper">
        {gridData?.project_array
          .filter((a, b) => a.email?.toLowerCase() === email?.toLowerCase())
          .map(({ images }) =>
            images.map((item, index) => {
              const {
                image_name,
                canEdit,
                // isBack,
                // image_data,
                back_image_data,
                isUpload,
              } = item
              return (
                <GridTile
                  key={`${index}-${image_name}-myTiles`}
                  tileData={item}
                  imageName={image_name}
                  backImageName={back_image_data.file_name}
                  canEdit={canEdit}
                  deleteHandler={deleteHandler}
                  isUpload={isUpload}
                  id={`dynamic-image--${index}`}
                  className="App-project-grid--image outline-solid border-radius--smaller outline-offset--1px"
                  boundsSelector=".App-project-grid--image"
                  containerClass="App-project-grid--my-tiles-image-container"
                  alt=""
                  wrapperClasses="sm:w-100"
                />
              )
            })
          )}
        <AddImageTiles
          gridData={gridData}
          clickHandler={noImageClickHandler}
          parentIndex={0}
          email={email}
        />
      </section>
    </>
  )
}

const MyTilesTab = memo(UnmemoizedMyTilesTab)
export { MyTilesTab }
