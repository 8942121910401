import React, { useState } from "react"
import { TextInput, Message, InlineMessage } from "../../../../Components"

const ShippingInfo = ({
  shippingValidation,
  setShippingValidation,
  setShippingData,
  commonShipping,
  isProjectOwner,
  shippingInfo,
  commonShippingHandler,
}) => {
  const [validations, setValidations] = useState()

  const validateInput = (e) => {
    const inputName = /(first|last)Name/gi.test(e.target.id)
      ? "name"
      : e.target.id.split("_")[e.target.id.split("_").length - 1]
    !e.target.value && e.target.classList.add("input-error")
    e.target.value && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !e.target.value,
        message: `Please specify a valid ${inputName}.`,
      },
    })
    return !!e.target.value
  }
  const validateAndSetInput = (e) => {
    validateInput(e) && setShippingData(e)
  }
  const validateStreet = (e) => {
    const addressRegex =
      /^\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+\s(?:Avenue|Lane|Road|Boulevard|Drive|Street|Highway|Court|Circle|Ave|Dr|Rd|Blvd|Ln|St|Hwy|Ct|Cir|\w{2,})\.?$/gi
    const isPOBox = /\s*(\.?p\s?\.?o\.?\s?)?box/gi.test(e.target.value)
    const isValid = addressRegex.test(e.target.value)
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `${
          (isPOBox && /ship/gi.test(e.target.id)) || (isPOBox && commonShipping)
            ? "We are unable to ship to PO Boxes at this time. "
            : ""
        }Please specify a valid street address.`,
      },
    })
    return isValid
  }
  const validateAndSetStreet = (e) => {
    validateStreet(e) && setShippingData(e)
  }
  const validateZip = (e) => {
    const isValid = /^\d{5}(|-\d{4})$/g.test(e.target.value)
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `Please specify a valid zip code.`,
      },
    })
    return isValid
  }
  const validateAndSetZip = (e) => {
    validateZip(e) && setShippingData(e)
  }
  const validateState = (e) => {
    const isValid =
      /AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY/gi.test(
        e.target.value
      )
    !isValid && e.target.classList.add("input-error")
    isValid && e.target.classList.remove("input-error")
    setValidations({
      ...validations,
      [e.target.id]: {
        visible: !isValid,
        message: `Please specify a valid two-letter state abbr.`,
      },
    })
    return isValid
  }
  const validateAndSetState = (e) => {
    validateState(e) && setShippingData(e)
  }
  // const validateAndSetEmail = (e) => {
  //   const isValid = /^.+?@[\w\W]+?\.[\w\W]{2,3}$/gi.test(e.target.value)
  //   !isValid && e.target.classList.add("input-error")
  //   isValid && e.target.classList.remove("input-error")

  //   setValidations({
  //     ...validations,
  //     [e.target.id]: {
  //       visible: !isValid,
  //       message: "Please specify a valid email address.",
  //     },
  //   })

  //   isValid && setShippingData(e)
  // }

  return (
    <>
      <h2 className="heading heading-s mt-0 mb-16 color-brand--primary">
        Shipping Information
      </h2>
      <label
        htmlFor="common-shipping"
        className="flex align-items--center mb-32"
      >
        <input
          name="common-shipping"
          type="checkbox"
          className="App-checkout--checkbox mr-8"
          onClick={commonShippingHandler}
        />
        Use billing address
      </label>
      {isProjectOwner && !commonShipping && (
        <>
          <section className="flex flex-wrap w-full justify-content--space-between">
            <TextInput
              autoComplete="new-off"
              label="First Name"
              id="ship_firstName"
              name="ship_firstName"
              placeholder="Big"
              type="text"
              onBlur={validateAndSetInput}
              labelClasses="App-checkout--label display-block"
              inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
              wrapperClasses="md:w-50 md:pr-8 sm:w-full mb-16 box-sizing--border-box"
              defaultValue={shippingInfo.ship_firstName}
            >
              {validations?.["ship_firstName"]?.visible && (
                <InlineMessage
                  className="mt-16 mb-8"
                  kind="error"
                  message={validations["ship_firstName"].message}
                />
              )}
            </TextInput>
            <TextInput
              autoComplete="new-off"
              label="Last Name"
              id="ship_lastName"
              name="ship_lastName"
              placeholder="Bird"
              type="text"
              onBlur={validateAndSetInput}
              labelClasses="App-checkout--label display-block"
              inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
              wrapperClasses="md:w-50 md:pl-8 sm:w-full mb-16 box-sizing--border-box"
              defaultValue={shippingInfo.ship_lastName}
            >
              {validations?.["ship_lastName"]?.visible && (
                <InlineMessage
                  className="mt-16 mb-8"
                  kind="error"
                  message={validations["ship_lastName"].message}
                />
              )}
            </TextInput>
          </section>
          <TextInput
            autoComplete="new-off"
            label="Street Address"
            id="ship_street"
            name="ship_street"
            placeholder="123 Sesame St."
            type="text"
            onBlur={validateAndSetStreet}
            labelClasses="App-checkout--label display-block"
            inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box mb-16"
            wrapperClasses="w-full"
            defaultValue={shippingInfo.ship_street}
          >
            {validations?.["ship_street"]?.visible && (
              <InlineMessage
                className="mb-16"
                kind="error"
                message={validations["ship_street"].message}
              />
            )}
          </TextInput>
          <section className="flex flex-wrap w-full mb-48 justify-content--space-between">
            <TextInput
              autoComplete="new-off"
              label="City"
              id="ship_city"
              name="ship_city"
              placeholder="New York City"
              type="text"
              onBlur={validateAndSetInput}
              labelClasses="App-checkout--label display-block"
              inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
              wrapperClasses="md:w-30 sm:w-full sm:mb-16 box-sizing--border-box"
              defaultValue={shippingInfo.ship_city}
            >
              {validations?.["ship_city"]?.visible && (
                <InlineMessage
                  className="mt-16 mb-8"
                  kind="error"
                  message={validations["ship_city"].message}
                />
              )}
            </TextInput>
            <TextInput
              autoComplete="new-off"
              label="State"
              id="ship_state"
              name="ship_state"
              placeholder="NY"
              type="text"
              onBlur={validateAndSetState}
              maxLength={2}
              labelClasses="App-checkout--label display-block"
              inputClasses="p-16 font-size-10 App-checkout--input w-full box-sizing--border-box"
              wrapperClasses="md:w-30 sm:w-50 sm:pr-8 box-sizing--border-box"
              defaultValue={shippingInfo.ship_state}
            >
              {validations?.["ship_state"]?.visible && (
                <InlineMessage
                  className="mt-16 mb-8"
                  kind="error"
                  message={validations["ship_state"].message}
                />
              )}
            </TextInput>
            <TextInput
              autoComplete="new-off"
              label="ZIP"
              id="ship_zip"
              name="ship_zip"
              placeholder="10128"
              type="number"
              onBlur={validateAndSetZip}
              maxLength={10}
              labelClasses="App-checkout--label display-block"
              inputClasses="p-16 font-size-10 App-checkout--input  w-full box-sizing--border-box"
              wrapperClasses="md:w-30 sm:w-50 sm:pl-8 box-sizing--border-box"
              defaultValue={shippingInfo.ship_zip}
            >
              {validations?.["ship_zip"]?.visible && (
                <InlineMessage
                  className="mt-16 mb-8"
                  kind="error"
                  message={validations["ship_zip"].message}
                />
              )}
            </TextInput>
          </section>
        </>
      )}
      {shippingValidation.visible && (
        <section className="w-full">
          <Message
            id="shipping-message"
            kind={shippingValidation.kind}
            message={shippingValidation.message}
            visible={shippingValidation.visible}
            state={[shippingValidation, setShippingValidation]}
            className="w-full"
          />
        </section>
      )}
    </>
  )
}

export { ShippingInfo }
