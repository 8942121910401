import React, {memo} from "react"
import { PZImage } from "./PZImage"
import { withDataString } from "../../HOCs/withDataString"

/**
 * 
 * @param {*} imageName
 * @param {*} projectName
 * @param {*} projectOwner
 * @param {*} side
 * @param {*} id
 * @param {*} children
 * @param {*} containerClass
 * @param {*} className
 * @param {*} rerender
 * @param {*} size
 * @param {*} wrapperClasses
 * @param {*} boundsSelector
 * @param {*} dataString
 */
const UnmemoizedPZImageWithDataString = ({ ...props }) => {
  const Component = withDataString(PZImage, { ...props })
  return <Component />
}

const PZImageWithDataString = memo(UnmemoizedPZImageWithDataString)
export { PZImageWithDataString }
