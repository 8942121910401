import React, { useMemo } from "react"
import { ProjectGrid } from "../../../Components"
import { useParams } from "react-router-dom"
import { base64ToString } from "../../../utils/base64"
import { useTitle } from "../../../Hooks/UseTitle"

const NewProjectPage = () => {
  const { project_name, project_owner } = useParams()
  const projectName = useMemo(() => base64ToString(project_name), [project_name])
  const projectOwner = useMemo(() => base64ToString(project_owner), [project_owner])
  useTitle(`Soulfolio Project: ${projectName}`)
  return (
    <>
      <ProjectGrid
        projectName={projectName}
        projectOwner={projectOwner}
      />
    </>
  )
}

export { NewProjectPage }
