import React, { useMemo, useState } from "react"
import { blankSvgDataString } from "../utils/utils"
import { fetchDataString } from "../services/ImageServices"

const withDataString = (
  Component,
  { imageName, side = "front", size = "small", ...passthroughProps }
) => {
  return () => {
    const [dataString, setDataString] = useState(null)

    useMemo(() => {
      if (!dataString) {

        if (side === "back" && !passthroughProps.backImageName) {
          setDataString(blankSvgDataString)
        } else {
          fetchDataString({
            side,
            size,
            imageName,
            backImageName: passthroughProps.backImageName,
          })
            .then((data) =>
              setDataString(
                data === "data:" || !data ? blankSvgDataString : data
              )
            )
            .catch((err) => {
              console.error(err)
              setDataString(blankSvgDataString)
            })
        }
      }
      //eslint-disable-next-line
    }, [imageName, side, size, passthroughProps.backImageName])

    return (
      <>
        {dataString ? (
          <Component
            dataString={dataString}
            imageName={imageName}
            side={side}
            size={size}
            {...passthroughProps}
          />
        ) : (
          <section className="loading w-100 h-100"></section>
        )}
      </>
    )
  }
}

export { withDataString }
