import React, { useState, useContext, useCallback, useRef } from "react"
import { IsMobileContext, SessionContext } from "../../../App"
import {
  Modal,
  IconButton,
  Spinner,
  Message,
  InlineMessage,
  ProgressBar,
} from "../.."
import "./ImageUploadModal.css"
import { api } from "../../../utils/api"
import { RotateIcon, SaveIcon } from "../../Icons"
import Cropper from "react-easy-crop"
import getCroppedImg, { createImage } from "../../../utils/cropImage"

const ImageUploadModal = ({
  projectName,
  closeHandler,
  projectOwner,
  setImageChoice,
}) => {
  const constants = {
    NAME: "mini-editor",
  }
  const UpdateStatus = {
    SAVE: "Save",
    SAVING: "Saving...",
    UPLOADING: "Uploading",
    SUCCESS: "Success!",
    ERROR: "Error",
  }
  const defaultStatus = { visible: false, kind: undefined, message: undefined }
  const [dragActive, setDragActive] = useState(false)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [updateStatus, setUpdateStatus] = useState(UpdateStatus.SAVE)
  const [useStatus, setStatus] = useState(defaultStatus)
  const [angle, setAngle] = useState(0)
  const [hideUploadControls, setHideUploadControls] = useState(false)
  const [tempImageUri, setTempImageUri] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isLandscape, setIsLandscape] = useState(false)
  const {
    session: { email, token },
  } = useContext(SessionContext)
  const { isMobile } = useContext(IsMobileContext)
  const inputRef = useRef()

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const imageSelectHandler = async (file) => {
    if (
      new RegExp(/\.(jpe?g|png|webp|tiff?)$/gi).test(file.name) &&
      file.size <= 33554432
    ) {
      const dataURI = await convertBase64(file)
      const tempImage = await createImage(dataURI)
      setIsLandscape(tempImage.width >= tempImage.height)
      setSelectedFile(file)
      setTempImageUri(dataURI)
      setHideUploadControls(true)
    } else {
      setStatus({
        visible: true,
        kind: "error",
        message: "The selected file is not the correct format or is too large",
      })
    }
    //setIsSelected(true)
  }

  const rotationHandler = () => {
    angle < 360 - 90 ? setAngle(angle + 90) : setAngle(0)
  }

  const uploadImageXHR = async (image) =>
    new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append("File", image, selectedFile.name)
      setUpdateStatus(UpdateStatus.UPLOADING)
      let xhr = new XMLHttpRequest()

      xhr.open("POST", api.uploadImage({ isBack: false }), true)

      xhr.setRequestHeader("Authorization", `Bearer ${token}`)
      xhr.setRequestHeader("upload_user", email)

      xhr.upload.onprogress = (e) =>
        setProgress(Math.ceil((e.loaded / e.total) * 100))

      xhr.onload = () => {
        if (xhr.status !== 200) {
          reject({ name: undefined, status: xhr.status })
        } else {
          let response = JSON.parse(xhr.response)
          resolve({ name: response.file_name, status: response.status })
        }
      }

      xhr.send(formData)
    })

  const saveHandler = async () => {
    const croppedImage = await getCroppedImg(
      tempImageUri,
      croppedAreaPixels,
      angle
    )
    const imageDidUpload = await uploadImageXHR(croppedImage)
    setUpdateStatus(UpdateStatus.UPLOAD)
    if (imageDidUpload.status === 200) {
      setUpdateStatus(UpdateStatus.SAVING)
      const res = await fetch(api.addCustomImage({ isBack: false }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_name: imageDidUpload.name,
          project_name: projectName,
          token,
          email,
          owner: projectOwner,
        }),
      })
      const data = await res.json()
      if (data.status === 200) {
        setUpdateStatus(UpdateStatus.SUCCESS)
        setTimeout(() => {
          closeHandler()
          setImageChoice(imageDidUpload.name)
          setHideUploadControls(false)
        }, 750)
      } else {
        setUpdateStatus(UpdateStatus.ERROR)
        setStatus({
          visible: true,
          kind: "error",
          message: data.msg || "The file couldn't be added to the project.",
        })
      }
    } else {
      setUpdateStatus(UpdateStatus.ERROR)
      setStatus({
        visible: true,
        kind: "error",
        message:
          imageDidUpload.msg || "The file couldn't be added to the project.",
      })
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (/drag(enter|over)/gi.test(e.type)) {
      !dragActive && setDragActive(true)
    } else {
      dragActive && setDragActive(false)
    }
  }

  const handleDrop = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragActive && setDragActive(false)
    e?.dataTransfer?.files &&
      (await imageSelectHandler(e?.dataTransfer?.files[0]))
  }

  const handleChange = async (e) => {
    e.preventDefault()
    e?.target?.files && (await imageSelectHandler(e?.target?.files[0]))
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  return (
    <Modal
      title="Image Upload"
      closeHandler={closeHandler}
      dialogClassName="App-img-upload--modal"
    >
      {!hideUploadControls && (
        <div className="mb-24 md:mx-24">
          <InlineMessage
            kind="warning"
            message="Max file size is 32 MB and accepted formats are as follows: jpg / jpeg, png, and tif"
          />
        </div>
      )}
      <div className="md:my-16 md:mx-24 flex flex-direction--column relative">
        {/* {!hideUploadControls && (
          <>
            <section className="flex flex-direction--row mb-16 box-sizing--border-box">
              <input
                type="text"
                className="App-img-upload--text-input"
                disabled
                value={selectedFile?.name || "No file chosen..."}
              />
              <label className="App-img-upload--label-button">
                <input
                  className="App-img-upload--file-input"
                  type="file"
                  name="file"
                  accept=".jpeg, .jpg, .png, .tif"
                  onChange={imageSelectHandler}
                />
                Choose File
              </label>
            </section>
          </>
        )} */}
        <section>
          {hideUploadControls ? (
            <section className="flex flex-direction--column">
              <h2 className="heading heading-xs m-0 pb-16">Mini-Editor</h2>
              <section className="App-img-upload--preview relative m-auto">
                {new RegExp(
                  `${UpdateStatus.SAVING}|${UpdateStatus.UPLOADING}|${UpdateStatus.UPLOAD}`,
                  "gi"
                ).test(updateStatus) && (
                  <div className="App-uploader-msg bg-true-white p-16 border-radius box-shadow--medium">
                    <InlineMessage
                      className="mb-16"
                      message={
                        updateStatus === UpdateStatus.UPLOADING
                          ? "I'm uploading the image now. I'll add it to your project and take you back to the project manager after its complete."
                          : updateStatus === UpdateStatus.UPLOAD
                          ? "Upload was successful! Taking care of a few technical things..."
                          : "Adding the image to your project..."
                      }
                    />
                    <ProgressBar progress={progress} />
                  </div>
                )}
                <Cropper
                  image={tempImageUri}
                  crop={crop}
                  rotation={angle}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onRotationChange={setAngle}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit={`${isLandscape ? "vertical" : "horizontal"}-cover`}
                />
              </section>
              <section
                className="flex flex-direction--column relative"
                style={{ zIndex: 5 }}
              >
                <section className="flex flex-direction--row justify-content--space-evenly align-items--center mt-16">
                  <section className="flex flex-direction--column justify-content--center align-items--center">
                    <IconButton id="rotate" onClick={rotationHandler}>
                      <RotateIcon size="medium" id="rotate" />
                    </IconButton>
                    <label htmlFor="rotate" className="mt-8 color-gray--60">
                      Rotate
                    </label>
                  </section>
                  <section className="flex flex-direction--column justify-content--center align-items--center">
                    <input
                      id="zoom"
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => setZoom(+e.currentTarget.value)}
                    />
                    <label htmlFor="zoom" className="mt-8 color-gray--60">
                      Zoom
                    </label>
                  </section>
                  <section className="flex flex-direction--column justify-content--center align-items--center">
                    <IconButton
                      id="save"
                      onClick={saveHandler}
                      disabled={updateStatus === UpdateStatus.SAVING}
                    >
                      {updateStatus === UpdateStatus.SAVING ? (
                        <Spinner
                          color="dynamic-text--inverse"
                          size="tiny"
                          text=""
                        />
                      ) : (
                        <SaveIcon size="medium" />
                      )}
                    </IconButton>
                    <label htmlFor="save" className="mt-8 color-gray--60">
                      Save
                    </label>
                  </section>
                </section>
              </section>
              <InlineMessage
                className="mt-16"
                kind="warning"
                message="You must save the file to add it to your project."
              />
            </section>
          ) : (
            <form
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{ height: "200px" }}
            >
              <input
                ref={inputRef}
                type="file"
                className="display-none"
                id={constants.NAME}
                accept=".jpeg, .jpg, .png, .tif"
                onChange={handleChange}
              />
              <label
                htmlFor={constants.NAME}
                className="App-drag-drop-uploader--label flex align-items--center justify-content--center color-dynamic-text"
              >
                {isMobile ? (
                  <button
                    onClick={handleClick}
                    className="w-60 App-drag-drop-uploader--button"
                  >
                    Tap to Upload
                  </button>
                ) : (
                  <Message
                    visible
                    fixed
                    kind="info"
                    className="mx-16 w-70 mb-24"
                    inert
                  >
                    <p className="App-message--text ml-24 font-size--11 transition-message">
                      {dragActive ? (
                        "Drop it!"
                      ) : (
                        <>
                          Click here{" "}
                          <em>
                            <b>
                              <u>or</u>
                            </b>
                          </em>{" "}
                          drag and drop an image to upload.
                        </>
                      )}
                    </p>
                  </Message>
                )}
                {!isMobile && dragActive && (
                  <div
                    className="drag-drop-focus"
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </label>
            </form>
          )}
        </section>
      </div>
      <div className="md:my-16 md:mx-24">
        {useStatus.visible && (
          <Message
            kind={useStatus.kind}
            message={useStatus.message}
            state={[useStatus, setStatus]}
          />
        )}
      </div>
    </Modal>
  )
}

export { ImageUploadModal }
