import React, { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { ShoppingCartIcon } from "../Icons"
import { SoulfolioLogo } from ".."
import { MobileHeader } from "./MobileHeader"
import "./Header.css"
import { HeaderLinks } from "./HeaderLinks"
import { IsMobileContext } from "../../App"

const Header = ({ links, includeCart, cartItemCount, ...props }) => {
  const location = useLocation()
  const { isMobile } = useContext(IsMobileContext)

  return (
    <nav className="App-header">
      <Link to="/" className="flex">
        <SoulfolioLogo className="App-header--logo" />
      </Link>
      {isMobile ? (
        <>
          {includeCart && (
            <ul className="App-header--list my-24">
              <li className="inline-flex flex-direction--row">
                <Link to={cartItemCount > 0 ? "/design/cart" : "#"}>
                  <ShoppingCartIcon
                    id="shopping-cart-icon"
                    size="large"
                    className="App--shopping-cart"
                  />
                </Link>
                <label
                  className="App-shopping-cart--badge"
                  htmlFor="shopping-cart-icon"
                >
                  {cartItemCount || 0}
                </label>
              </li>
            </ul>
          )}
          <MobileHeader links={links} location={location} />
        </>
      ) : (
        <ul className="App-header--list my-24">
          <HeaderLinks links={links} location={location} />
          {includeCart && (
            <li className="inline-flex flex-direction--row">
              <Link to={cartItemCount > 0 ? "/design/cart" : "#"}>
                <ShoppingCartIcon
                  id="shopping-cart-icon"
                  size="large"
                  className="App--shopping-cart"
                />
              </Link>
              <label
                className="App-shopping-cart--badge"
                htmlFor="shopping-cart-icon"
              >
                {cartItemCount || 0}
              </label>
            </li>
          )}
        </ul>
      )}
    </nav>
  )
}

export { Header }
