import React, { useEffect, useContext, useState } from "react"
import {
  Button,
  DialogBox,
  Message,
  Modal,
  Spinner,
  Image,
  PreCheckoutModal,
  IconButton,
} from "../../../Components"
import {
  AddToCartIcon,
  LinkIcon,
  SoulfolioIcon,
  TrashIcon,
} from "../../../Components/Icons"
import { ProjectContext, SessionContext } from "../../../App"
import { ModalContext } from "../../ProjectWorkspace"
import { Link, useHistory } from "react-router-dom"
import { stringToBase64 } from "../../../utils/base64"
import { api } from "../../../utils/api"
import "./MyProjectsPage.css"
import { useTitle } from "../../../Hooks/UseTitle"

const MyProjectsPage = () => {
  const {
    session,
    session: { email, token },
  } = useContext(SessionContext)
  const projectContext = useContext(ProjectContext)
  const { modalHidden, setModalHidden } = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const [gridData, setGridData] = useState(null)
  const [tileCount, setTileCount] = useState(0)
  const [activeModal, setActiveModal] = useState(0)
  const [activeProject, setActiveProject] = useState(null)
  const [currentTab, setCurrentTab] = useState("my-projects")
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
    children: undefined,
    fixed: undefined,
  })
  const [projectToDelete, setProjectToDelete] = useState(null)
  const history = useHistory()

  const confirmationModal = 1
  const preCheckoutModal = 2

  const myProjects = "my-projects"
  const assignedProjects = "assigned-projects"
  const purchasedProjects = "purchased-projects"

  useTitle("My Projects")

  const deleteHandler = (e) => {
    setModalHidden(!modalHidden)
    const newGridData = gridData.filter(
      (obj) => obj.project_name !== projectToDelete
    )
    setGridData(newGridData)
    setTileCount(tileCount - 1)
    fetch(api.deleteProject({ email, projectToDelete }), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const confirmDelete = ({ project_name, owner }) => {
    if (owner?.toLowerCase() === email?.toLowerCase()) {
      setActiveModal(confirmationModal)
      setModalHidden(false)
      setProjectToDelete(project_name)
    }
  }

  const imageClickHandler = (project_name, owner) => {
    projectContext.setGridData(null)
    history.push(
      `/design/projects/${stringToBase64(project_name)}/${stringToBase64(owner)}`
    )
  }

  const preCheckoutHandler = ({ project_name, owner, email }) => {
    projectContext.setGridData(
      gridData.filter((obj) => obj.project_name === project_name)[0]
    )
    setActiveModal(preCheckoutModal)
    setActiveProject({ project_name, owner, email })
    setModalHidden(false)
  }

  const tabClickHandler = (e) => {
    setCurrentTab(e.target.id)
  }

  const myTabMarkup =
    gridData?.filter(
      (item) =>
        item.email?.toLowerCase() === session.email?.toLowerCase() &&
        !item.tracker?.tracking_code
    ).length > 0 ? (
      gridData
        .filter(
          (item) =>
            item.email?.toLowerCase() === session.email?.toLowerCase() &&
            !item.tracker?.tracking_code
        )
        .map(
          (
            {
              project_name,
              owner,
              souldier_count,
              tile_count,
              project_array,
              email,
              tracker,
            },
            index
          ) => (
            <div
              key={`tile-${index}`}
              className="App-project-mgmt--tile m-24 overflow--clip"
            >
              <div className="placeholder flex justify-content--center overflow--clip">
                {project_array.sort(
                  (a, b) => b.images.length - a.images.length
                ) && project_array[0].images.length > 0 ? (
                  <Image
                    className="App-project-mgmt--tile-preview faux-link"
                    id={stringToBase64(project_array[0].images[0].image_name)}
                    src={api.getImageBySize({
                      size: "small",
                      image_name: project_array[0].images[0].image_name,
                    })}
                    alt=""
                    onClick={() => {
                      imageClickHandler(project_name, owner)
                    }}
                  ></Image>
                ) : (
                  <SoulfolioIcon
                    size="3x-large"
                    className="align-self--center"
                  />
                )}
              </div>
              <section className="App-project-mgmt--tile-content flex flex-direction--column">
                <div className="flex flex-direction--row align-items--center bold font-size--12">
                  <span>
                    <div
                      className="align-items--center combo-link flex faux-link"
                      onClick={() => {
                        imageClickHandler(project_name, owner)
                      }}
                    >
                      <p className="truncate">{project_name}</p>
                      <LinkIcon className="flex" size="small" />
                    </div>
                  </span>
                </div>
                {/* <p>For: <span className="bold">{relationship}</span></p> */}
                <p className="font-size--10 color-gray--50 truncate">
                  Owner: <span className="bold">{owner}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Contributors: <span className="bold">{souldier_count}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Tile Count: <span className="bold">{tile_count}</span>
                </p>
                {tracker?.tracking_code && (
                  <p className="font-size--9 color-gray--50 truncate">
                    Tracking #:{" "}
                    <a
                      className="combo-link"
                      href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracker.tracking_code}`}
                      rel="noreferrer"
                      target="_blank"
                      title={tracker.tracking_code}
                    >
                      {tracker.tracking_code}
                    </a>
                  </p>
                )}
                <div className="inline-flex align-self--flex-end mt-16">
                  {!tracker?.tracking_code && (
                    <IconButton
                      className="App-positive-button mx-8"
                      title="Add to Cart"
                      aria-label="Add To Cart Button"
                      disabled={owner !== email}
                      onClick={(e) => {
                        preCheckoutHandler({
                          project_name,
                          owner,
                          email,
                        })
                      }}
                    >
                      <AddToCartIcon
                        size="medium"
                        className="box-sizing--border-box App-icon-button--icon"
                      />
                    </IconButton>
                  )}
                  <IconButton
                    className="App-negative-button"
                    title="Delete Project"
                    aria-label="Delete Project Button"
                    disabled={owner !== email}
                    onClick={(e) => {
                      confirmDelete({ project_name, owner })
                    }}
                  >
                    <TrashIcon
                      size="medium"
                      className="box-sizing--border-box App-icon-button--icon"
                    />
                  </IconButton>
                </div>
              </section>
            </div>
          )
        )
    ) : (
      <Message visible fixed kind="info">
        <p className={`App-message--text ml-24 font-size--11`}>
          You don't have any active Soulfolios. Return to the{" "}
          <Link className="underline App-link" to="/dashboard">
            dashboard
          </Link>{" "}
          and click the New Project button to create one.
        </p>
      </Message>
    )

  const assignedTabMarkup =
    gridData?.filter((item) => item.email !== session.email).length > 0 ? (
      gridData
        .filter((item) => item.email !== session.email)
        .sort((item, comparison) => item.email < comparison.email)
        .map(
          (
            {
              project_name,
              owner,
              souldier_count,
              tile_count,
              project_array,
              tracker,
            },
            index
          ) => (
            <div
              key={`tile-${index}`}
              className="App-project-mgmt--tile m-24 overflow--clip"
            >
              <div className="placeholder flex justify-content--center align-items--center overflow--clip">
                {project_array.sort(
                  (a, b) => b.images.length - a.images.length
                ) && project_array[0].images.length > 0 ? (
                  <Image
                    className="App-project-mgmt--tile-preview faux-link"
                    id={project_array[0].images[0]}
                    src={api.getImageBySize({
                      size: "small",
                      image_name: project_array[0].images[0].image_name,
                    })}
                    alt=""
                    onClick={() => {
                      imageClickHandler(project_name, owner)
                    }}
                  ></Image>
                ) : (
                  <SoulfolioIcon size="3x-large" />
                )}
              </div>
              <section className="App-project-mgmt--tile-content flex flex-direction--column">
                {/* <p>Title: <Link className="text-decoration--underline text-link inline-block ml-8 bold" to={`/design/projects/${project_name}`}>{project_name}</Link></p> */}
                <div className="flex flex-direction--row align-items--center bold font-size--12">
                  <span>
                    <div
                      className="align-items--center combo-link flex faux-link"
                      onClick={() => {
                        imageClickHandler(project_name, owner)
                      }}
                    >
                      <p className="truncate">{project_name}</p>
                      <LinkIcon className="flex" size="small" />
                    </div>
                  </span>
                </div>
                <p className="font-size--10 color-gray--50 truncate">
                  Owner: <span className="bold">{owner}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Contributors: <span className="bold">{souldier_count}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Tile Count: <span className="bold">{tile_count}</span>
                </p>
                {tracker?.tracking_code && (
                  <p className="font-size--10 color-gray--50 truncate">
                    Tracking #:{" "}
                    <a
                      className="combo-link"
                      href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracker.tracking_code}`}
                      rel="noreferrer"
                      target="_blank"
                      title={tracker.tracking_code}
                    >
                      {tracker.tracking_code}
                    </a>
                  </p>
                )}
              </section>
            </div>
          )
        )
    ) : (
      <Message
        visible
        fixed
        kind="info"
        message="You have not been invited to collaborate on any Soulfolios"
      />
    )

  const purchasedTabMarkup =
    gridData?.filter(
      (item) => !item.tracker || typeof item.tracker !== "object"
    ).length > 0 ? (
      gridData
        .filter((item) => !!item.tracker?.tracking_code)
        .sort((item, comparison) => item.email < comparison.email)
        .map(
          (
            {
              project_name,
              owner,
              souldier_count,
              tile_count,
              project_array,
              tracker,
            },
            index
          ) => (
            <div
              key={`tile-${index}`}
              className="App-project-mgmt--tile m-24 overflow--clip"
            >
              <div className="placeholder flex justify-content--center align-items--center overflow--clip">
                {project_array.sort(
                  (a, b) => b.images.length - a.images.length
                ) && project_array[0].images.length > 0 ? (
                  <Image
                    className="App-project-mgmt--tile-preview faux-link"
                    id={project_array[0].images[0]}
                    src={api.getImageBySize({
                      size: "small",
                      image_name: project_array[0].images[0].image_name,
                    })}
                    alt=""
                    onClick={() => {
                      imageClickHandler(project_name, owner)
                    }}
                  ></Image>
                ) : (
                  <SoulfolioIcon size="3x-large" />
                )}
              </div>
              <section className="App-project-mgmt--tile-content flex flex-direction--column">
                {/* <p>Title: <Link className="text-decoration--underline text-link inline-block ml-8 bold" to={`/design/projects/${project_name}`}>{project_name}</Link></p> */}
                <div className="flex flex-direction--row align-items--center bold font-size--12">
                  <span>
                    <div
                      className="align-items--center combo-link flex faux-link"
                      onClick={() => {
                        imageClickHandler(project_name, owner)
                      }}
                    >
                      <p className="truncate">{project_name}</p>
                      <LinkIcon className="flex" size="small" />
                    </div>
                  </span>
                </div>
                <p className="font-size--10 color-gray--50 truncate">
                  Owner: <span className="bold">{owner}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Contributors: <span className="bold">{souldier_count}</span>
                </p>
                <p className="font-size--10 color-gray--50 truncate">
                  Tile Count: <span className="bold">{tile_count}</span>
                </p>
                {tracker?.tracking_code && (
                  <p className="font-size--10 color-gray--50 truncate">
                    Tracking #:{" "}
                    <a
                      className="combo-link"
                      href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracker.tracking_code}`}
                      rel="noreferrer"
                      target="_blank"
                      title={tracker.tracking_code}
                    >
                      {tracker.tracking_code}
                    </a>
                  </p>
                )}
              </section>
            </div>
          )
        )
    ) : (
      <Message
        visible
        fixed
        kind="info"
        message="You have not purchased any Soulfolios"
      />
    )

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setLoading(true)
      fetch(api.getProjects, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then(({ status, data }) => {
          setLoading(false)
          if (status === 200 && data) {
            // console.log(data)
            setGridData(data)
            setTileCount(data.length)
          } else if (status === 401) {
            history.push("/logout?status=session-expired")
          } else {
            setStatus({
              visible: true,
              kind: "info",
              message: undefined,
              fixed: true,
              children: (
                <p className={`App-message--text ml-24 font-size--11`}>
                  You haven't created any Soulfolios yet. Click{" "}
                  <Link
                    className="active-link text-decoration--underline"
                    to="/design/dashboard"
                  >
                    here
                  </Link>{" "}
                  to go to the dashboard where you can create a new project
                </p>
              ),
            })
          }
        })
        .catch((err) => {
          setLoading(false)
          setStatus({
            visible: true,
            kind: "error",
            message: "Uh oh, something went wrong.  Try again in a moment.",
            fixed: false,
            children: undefined,
          })
          console.error(err)
        })
    } else {
      return () => (mounted = false)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {activeModal === confirmationModal && (
        <Modal
          title="Delete Project Confirmation"
          contentClassName="flex flex-direction--column justify-content--space-between"
        >
          <h1 className="center-text align-self--center pb-24 heading">
            Are you sure you want to delete "{projectToDelete}"?
          </h1>
          <div className="flex md:flex-direction--row sm:flex-direction--column justify-content--space-around">
            <Button onClick={deleteHandler}>DELETE</Button>
            <Button
              onClick={(e) => {
                setModalHidden(true)
              }}
            >
              CANCEL
            </Button>
          </div>
        </Modal>
      )}
      {activeModal === preCheckoutModal && (
        <PreCheckoutModal
          activeProject={activeProject}
          setActiveProject={setActiveProject}
        />
      )}
      <div>
        <DialogBox header="Active Projects" className="mb-48">
          <section className="App-project-grid--tab-bar">
            <span
              id={myProjects}
              onClick={tabClickHandler}
              className={`App-project-grid--tab ${
                currentTab === myProjects ? "active" : ""
              }`}
            >
              My Folios
            </span>
            <span
              id={assignedProjects}
              onClick={tabClickHandler}
              className={`App-project-grid--tab ${
                currentTab === assignedProjects ? "active" : ""
              }`}
            >
              Assigned Folios
            </span>
            <span
              id={purchasedProjects}
              onClick={tabClickHandler}
              className={`App-project-grid--tab ${
                currentTab === purchasedProjects ? "active" : ""
              }`}
            >
              Purchased Folios
            </span>
          </section>
          <div className="md:px-48 px-16 py-24 flex flex-direction--row flex-wrap justify-content--center align-items--center bg-true-white App-tab-content--container">
            {loading ? (
              <Spinner />
            ) : useStatus.visible ? (
              <Message
                kind={useStatus.kind}
                message={useStatus.message}
                state={[useStatus, setStatus]}
                fixed={useStatus.fixed}
              >
                {useStatus.children}
              </Message>
            ) : currentTab === "my-projects" ? (
              myTabMarkup
            ) : currentTab === "assigned-projects" ? (
              assignedTabMarkup
            ) : (
              purchasedTabMarkup
            )}
          </div>
        </DialogBox>
        {/* {!token && <Redirect to="/login" />} */}
      </div>
    </>
  )
}

export { MyProjectsPage }
