import { useState, useCallback } from "react"
import { encrypt, decrypt } from "../utils/utils"

const useProjectData = () => {
  const getProjectData = () =>
    localStorage.getItem("projectData")
      ? JSON.parse(decrypt(localStorage.getItem("projectData")))
      : null

  const [projectData, setProjectData] = useState(getProjectData())

  const saveProjectData = useCallback((data) => {
    
    localStorage.setItem("projectData", encrypt({...data }))
    data !== projectData && setProjectData(data)
    //eslint-disable-next-line
  }, [])

  return {
    setProjectData: saveProjectData,
    projectData: projectData,
  }
}

export { useProjectData }
