import React, { useEffect, useState } from "react"
import { Message } from "../Message/Message"
import "./DialogBox.css"

const DialogBox = ({
  children,
  header,
  containerColor = "true-white",
  className,
}) => {
  const [isChrome, setIsChrome] = useState(false)
  useEffect(() => {
    setIsChrome(!!window.chrome)
  }, [])
  return (
    <>
      <section className="App-dialog-box-header--container">
        <section className="App-dialog-box--header">
          <h4 className="color-true-white regular">{header}</h4>
        </section>
      </section>
      <section className={`App-dialog-box--container ${className || ""}`}>
        <section className="py-48">
          {!isChrome && (
            <Message
              className="mb-16 flex align-self--center"
              fixed
              kind="error"
              message="For the best experience, we recommend using the latest version of Google Chrome"
            />
          )}
          {children}
        </section>
      </section>
    </>
  )
}

export { DialogBox }
