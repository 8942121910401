/**
 * 
 * @param { string } str - the string value we want to capitalize
 * @returns { string } - the capitalized string
 */
const Capitalizer = str => {
  let strArr = str.split('')
      strArr[0] = strArr[0].toUpperCase();
      
  return strArr.join('')
}

export { Capitalizer }