import React, {
  useContext,
  useLayoutEffect,
  useState,
  memo,
  useCallback,
} from "react"
import { IsMobileContext, SessionContext, ProjectContext } from "../../App"
import { ModalContext } from "../../Pages/ProjectWorkspace/WorkspaceTemplate"
import {
  DialogBox,
  Message,
  Modal,
  Button,
  ImageGalleryModal,
  ImageUploadModal,
} from ".."
import { useHistory } from "react-router-dom"
import "./ProjectGrid.css"
import { ManageProjectTab } from "./ManageProjectTab"
import { MyTilesTab } from "./MyTilesTab"
import { AllTilesTab } from "./AllTilesTab"
import { useQuery } from "../../utils/useQuery"
import { api } from "../../utils/api"

const UnmemoizedProjectGrid = ({
  projectName,
  projectOwner,
  className,
  ...props
}) => {
  const defaultStatus = { visible: false, kind: undefined, message: undefined }
  const query = useQuery()
  let tabParam = query.get("tab")
  const [useStatus, setStatus] = useState(defaultStatus)
  const [imageChoice, setImageChoice] = useState(null)
  const [activeModal, setActiveModal] = useState(null)
  const [inviteSent, setInviteSent] = useState(false)
  const [currentTab, setCurrentTab] = useState(tabParam || "my-tiles")
  const {
    session: { token, email },
  } = useContext(SessionContext)
  const { modalHidden, setModalHidden } = useContext(ModalContext)
  const { isMobile } = useContext(IsMobileContext)
  const { gridData, setGridData, counter, refreshGridData } =
    useContext(ProjectContext)
  const history = useHistory()

  const fetchProjectData = useCallback(
    async (signal) => {
      const res = await fetch(api.getProject, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          project_name: projectName,
          email,
          owner: projectOwner,
        }),
        signal, // add the signal to the request
      })
      return await res.json()
    },
    // eslint-disable-next-line
    [imageChoice]
  )

  useLayoutEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const projectGridEffect = async () => {
      setActiveModal(null)
      const { status, data } = await fetchProjectData(signal)
      if (status === 200 && data) {
        setGridData({ ...data })
      } else if (status === 401) {
        history.push("/logout?status=session-expired")
      } else {
        setStatus({
          visible: true,
          kind: "warning",
          message:
            "Oops, this project appears to be empty :(\n\n Try creating a new one!",
        })
      }
    }

    projectGridEffect()

    return () => {
      abortController.abort() // clean up the effect
    }
    // eslint-disable-next-line
  }, [imageChoice, inviteSent, isMobile, counter])

  const noImageClickHandler = useCallback(() => {
    setActiveModal("prompt")
    modalHidden && setModalHidden(false)
    // eslint-disable-next-line
  }, [])

  const closeHandler = useCallback(() => {
    useStatus !== defaultStatus && setStatus(defaultStatus)
    setActiveModal(null)
    setModalHidden(true)
    // eslint-disable-next-line
  }, [])

  const closeUploadModalHandler = useCallback(() => {
    setModalHidden(true)
    setActiveModal(null)
    setImageChoice("upload")
    // eslint-disable-next-line
  }, [])

  const deleteHandler = useCallback(
    (imageName) => {
      fetch(
        api.deleteImageFromProject({
          email,
          projectName,
          imageName,
          projectOwner,
        }),
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(() => {
        imageChoice === imageName ? setImageChoice(null) : refreshGridData()
        setActiveModal(null)
        setModalHidden(true)
      })
    },
    // eslint-disable-next-line
    [projectName]
  )

  const tabClickHandler = useCallback((e) => {
    setCurrentTab(e.target.id)
    history.push({ search: `?tab=${e.target.id}` })
    // eslint-disable-next-line
  }, [])

  const galleryClicker = useCallback((e) => {
    setActiveModal("gallery")
    modalHidden && setModalHidden(false)
    // eslint-disable-next-line
  }, [])

  const uploadClicker = useCallback((e) => {
    setActiveModal("upload")
    modalHidden && setModalHidden(false)
    // eslint-disable-next-line
  }, [])

  return (
    gridData && (
      <div className={className ? className : ""}>
        {activeModal === "prompt" && (
          <Modal
            title="Choose a flow"
            closeHandler={closeHandler}
            dialogClassName="App-gallery--modal-tutor"
            contentClassName="App-gallery--modal-tutor-container flex justify-content--center"
          >
            <section className="flex flex-direction--column justify-content--center align-items--center w-60">
              {/* <Button className={`mb-16`} disabled>Guide Me (Coming Soon!)</Button> */}
              <Button onClick={galleryClicker} className={`mb-16 w-full`}>
                Gallery
              </Button>
              <Button onClick={uploadClicker} className={`mb-16 w-full`}>
                Upload your Own
              </Button>
            </section>
          </Modal>
        )}
        {activeModal === "upload" && (
          <ImageUploadModal
            projectName={projectName}
            projectOwner={projectOwner}
            setImageChoice={setImageChoice}
            closeHandler={closeUploadModalHandler}
          />
        )}
        {activeModal === "gallery" && (
          <ImageGalleryModal
            setImageChoice={setImageChoice}
            setActiveModal={setActiveModal}
            closeHandler={closeHandler}
          />
        )}

        <DialogBox
          header={`Project Name: ${gridData?.project_name || ""}`}
          containerColor="gray--10"
        >
          <section className="App-project-grid--tab-bar">
            <span
              id="my-tiles"
              onClick={tabClickHandler}
              className={`App-project-grid--tab ${
                currentTab === "my-tiles" ? "active" : ""
              }`}
            >
              My Tiles
            </span>
            <span
              id="all-tiles"
              onClick={tabClickHandler}
              className={`App-project-grid--tab ${
                currentTab === "all-tiles" ? "active" : ""
              }`}
            >
              All Tiles
            </span>
            {email?.toLowerCase() === gridData?.owner?.toLowerCase() && (
              <span
                id="manage-project"
                onClick={tabClickHandler}
                className={`App-project-grid--tab ${
                  currentTab === "manage-project" ? "active" : ""
                }`}
              >
                Manage
              </span>
            )}
          </section>

          <section className="md:px-48 px-16 py-24 flex flex-direction--column w-full box-sizing--border-box bg-true-white App-tab-content--container">
            {currentTab === "my-tiles" && (
              <MyTilesTab
                deleteHandler={deleteHandler}
                noImageClickHandler={noImageClickHandler}
              />
            )}

            {currentTab === "all-tiles" && <AllTilesTab />}
            {currentTab === "manage-project" &&
              email?.toLowerCase() === gridData?.owner?.toLowerCase() && (
                <ManageProjectTab
                  functions={[setInviteSent, deleteHandler]}
                  parentActiveModalState={setActiveModal}
                />
              )}
          </section>
          <div className="mx-24">
            <Message
              kind={useStatus.kind}
              message={useStatus.message}
              state={[useStatus, setStatus]}
            ></Message>
          </div>
        </DialogBox>
      </div>
    )
  )
}

const ProjectGrid = memo(UnmemoizedProjectGrid)

export { ProjectGrid }
