import React, { memo } from "react"
import { Image } from ".."
import Draggable from "react-draggable"
import { clsnx } from "@becks256/clsnx"

export const DynamicImage = memo(
  ({
    id,
    children,
    containerClass,
    className,
    boundsSelector,
    onDrag,
    onStop,
    editable,
    text,
    dataString,
    imageContainerRef,
    controlledPos,
    textOverlay,
    textStyles,
    ...props
  }) => {
    return (
      <>
        <Draggable
          bounds={boundsSelector}
          position={controlledPos}
          onDrag={onDrag}
          onStop={onStop}
          disabled={!editable}
          nodeRef={textOverlay}
        >
          <div ref={textOverlay} style={textStyles}>
            <p
              className={clsnx("App-DyanimcImage--text center-text m-0", {
                "marching-ants": !!editable && !!text,
                "invisible-fence": !!!editable
              })}
            >
              {text}
            </p>
          </div>
        </Draggable>
        <Image
          {...props}
          inert={`"${!editable}"`}
          id={id}
          src={dataString}
          className={className}
          containerClass={`flex ${containerClass ? containerClass : ""}`}
          containerRef={imageContainerRef}
          loading="eager"
        >
          {children}
        </Image>
      </>
    )
  }
)
