import React from "react"

const FontStrokeWidthSlider = ({
  canEdit,
  strokeWidth,
  strokeWidthHandler,
  strokeWidthRef,
  fontSize,
}) => {
  return (
    <section className="flex flex-direction--column">
      <label className="flex" htmlFor="stroke-size">
        Stroke Size
      </label>
      <section className="flex flex-direction--row w-full mb-16">
        <input
          disabled={!canEdit}
          name="stroke-size"
          className="App-image-editor--range box-sizing--border-box"
          type="range"
          value={strokeWidth || 0}
          min={0}
          max={fontSize / 10}
          step={0.1}
          onChange={strokeWidthHandler}
        ></input>
        <input
          style={{
            width: "2rem",
            height: "0.5rem",
            textAlign: "center",
            margin: "0 0 0 8px",
            borderRadius: "var(--border-radius--smaller)",
          }}
          type="number"
          ref={strokeWidthRef}
          value={strokeWidth || 0}
          className="App-number-field"
          disabled
        ></input>
      </section>
    </section>
  )
}

export { FontStrokeWidthSlider }
