import React from 'react'
import { IconContainer } from './IconContainer'

const XmarkIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size} >
      <g>
        <polygon fill={fill} points="100,8.7 91.3,0 50,41.3 8.7,0 0,8.7 41.3,50 0,91.3 8.7,100 50,58.7 91.3,100 100,91.3 58.7,50 	" />
      </g>
    </IconContainer>
  )
}

export { XmarkIcon }