import CryptoJS from "crypto-js"

const key = process.env.REACT_APP_KEY

export const blankSvgDataString = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="0 0 800 800"><path fill="white" d="M0 0h800v800H0z"/></svg>`

export const encrypt = (data) =>
  typeof data === "object"
    ? CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
    : CryptoJS.AES.encrypt(data, key).toString()

export const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, key)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const generateToken = (length = 64) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdyfghijklmnopqrstuvwxyz1234567890"
  let token = ""
  for (let i = 0; i < length; i++) {
    let randomNumber = Math.floor(Math.random() * (chars.length - 1))
    token += chars[randomNumber]
  }

  return token
}

export const calculateGrandTotal = ({
  isTaxable,
  basePrice,
  ohTax,
  shippingRate,
  giftWrapRate,
}) => {
  return isTaxable
    ? +basePrice * (1 + +ohTax) + +shippingRate.value + giftWrapRate
    : +basePrice + +shippingRate.value + giftWrapRate
}

export const calculateDiscount = ({ promo, total }) => {
  const { fixed_amount, discount } = promo
  return fixed_amount ? total - discount : total - total * (discount / 100)
}

export const getBasePrice = (itemCount) => {
  const {
    REACT_APP_25_BULK_PRICE,
    REACT_APP_50_BULK_PRICE,
    REACT_APP_75_BULK_PRICE,
    REACT_APP_100_BULK_PRICE,
  } = process.env
  return itemCount <= 25
    ? +REACT_APP_25_BULK_PRICE
    : itemCount > 25 && itemCount <= 50
      ? +REACT_APP_50_BULK_PRICE
      : itemCount > 50 && itemCount <= 75
        ? +REACT_APP_75_BULK_PRICE
        : +REACT_APP_100_BULK_PRICE
}

export const getCurrentYear = () => {
  const d = new Date()
  return d.getFullYear()
}

export const calcInitialScaledTextSize = (textSize) =>
  !textSize
    ? 32
    : textSize < 12
    ? (((textSize * 1.33) / 96) * 300) / 1.33
    : textSize

export const calculatePosition = (num) => {
  console.log(num)
  return num < 0 ? num + 16 : num > 0 ? num - 16 : 0
}

export function classname() {
  const classes = []
  Array.from(arguments).forEach((item) => {
    typeof item === "string" && classes.push(item)
    if (typeof item === "object") {
      Object.keys(item).forEach((i) => {
        if (typeof item[i] === "boolean") {
          item[i] && classes.push(i)
        } else if (typeof item[i] === "string") {
          classes.push(item[i])
        } else if (typeof item[i] === "object") {
          item[i] && classes.push(classname(item[i]))
        }
      })
    }
  })
  return [...new Set(classes)].join(" ")
}
