import { useState } from "react"
import { encrypt, decrypt } from "../utils/utils"
//import { TokenValidator } from './IsTokenValid';

const useSession = () => {
  const getSession = () =>
    localStorage.getItem("session")
      ? JSON.parse(decrypt(localStorage.getItem("session")))
      : { token: null, name: null, email: null }
  const [session, setSession] = useState(getSession())

  const saveSession = (userSession) => {
    if (userSession === null) {
      localStorage.clear()
    } else {
      const { token, name, email } = userSession
      localStorage.setItem("session", encrypt({ token, name, email }))
    }
    setSession(userSession)
  }

  return {
    setSession: saveSession,
    session,
  }
}

export { useSession }
