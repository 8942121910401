import React from "react"
import { IconButton } from "../IconButton/IconButton"
import { MinusIcon, PlusIcon } from "../Icons"
import "./QuantityStepper.css"

const QuantityStepper = ({ quantity, disabled, onChange, onIncrease, onDecrease }) => {

  return (
    <section className="flex flex-direction--row align-items--center">
      <IconButton
        className="App-negative-button"
        onClick={onDecrease}
        disabled={disabled}
      >
        <MinusIcon size="medium" />
      </IconButton>
      <input
        type="number"
        value={quantity}
        className="App-quantityStepper--input"
        onChange={onChange}
        disabled={disabled}
      ></input>
      <IconButton
        className="App-positive-button"
        onClick={onIncrease}
        disabled={disabled}
      >
        <PlusIcon size="medium" />
      </IconButton>
    </section>
  )
}

export { QuantityStepper }
