import React from "react"
import { IconButton } from "../"
import { QuestionIcon } from "../Icons"
import "./HelpButton.css"

export const HelpButton = ({ onClick }) => {
  return (
    <div className="App-HelpButton">
      <IconButton onClick={onClick} palette="special">
        <QuestionIcon size="medium" />
      </IconButton>
    </div>
  )
}
