import React from "react"
import { Button, InlineMessage } from "../../"

const AddToCartButton = ({ isComplete, onClick, status}) => {
  return (
    <section className="flex-direction--column md:w-50 sm:w-full justify-content--center">
      <section className="flex justify-content--center">
        <Button
          onClick={onClick}
          disabled={!isComplete}
          className="mt-24 box-sizing--border-box md:w-50 sm:w-100"
        >
          Add to Cart
        </Button>
      </section>
      {status.visible && (
        <section className="flex">
          <InlineMessage
            kind={status.kind}
            // visible={status.visible}
            // state={[status, setStatus]}
            className="md:w-50 sm:w-100 mt-16"
          >
            <p className={`App-inline-message--text ml-8 font-size--10`}>
              This project is not yet complete. Refer to the{" "}
              <strong>Image Selections by Contributor</strong> section below for
              more detail.
            </p>
          </InlineMessage>
        </section>
      )}
    </section>
  )
}

export { AddToCartButton }
