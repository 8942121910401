import React from "react"
import { IconButton } from "../../"
import { SaveIcon, EditIcon, XmarkIcon } from "../../Icons"
import { clsnx } from "@becks256/clsnx"

const EditAndSaveButton = ({ isEditing, onSave, onEdit, onCancel }) => {
  const WhichIcon = isEditing ? SaveIcon : EditIcon
  return (
    <section className="flex flex-direction--row">
      <IconButton
        className={clsnx("App-special-button", { "mr-8": isEditing })}
        title={isEditing ? "Save" : "Edit Project Name"}
        onClick={isEditing ? onSave : onEdit}
      >
        <WhichIcon
          size="medium"
          className="box-sizing--border-box App-icon-button--icon"
        />
      </IconButton>
      {isEditing && (
        <IconButton
          className="App-negative-button"
          title="Cancel"
          onClick={onCancel}
        >
          <XmarkIcon
            size="medium"
            className="box-sizing--border-box App-icon-button--icon"
          />
        </IconButton>
      )}
    </section>
  )
}

export { EditAndSaveButton }
