import React from 'react'
import './NumberInput.css'

const NumberInput = ({ className, label, title, inputClasses, wrapperClasses, labelClasses, ...props }) => {
  return (
    <div className={wrapperClasses || ''}>
      {label && <label className={labelClasses || ''} htmlFor={title}>{label}</label>}
      <input title={title} {...props} className={`App-number-field ${inputClasses || ''}`} />
    </div>
  )
}

export { NumberInput }