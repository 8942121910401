import React from 'react'
import { IconContainer } from './IconContainer'

const AddImageIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <circle cx="19.6" cy="32.7" r="6.1" />
      <path d="M94.5,64.7H79.2c-3,0-5.5,2.5-5.5,5.5v15.3c0,3,2.5,5.5,5.5,5.5h15.3c3,0,5.5-2.5,5.5-5.5V70.2
		C100,67.2,97.5,64.7,94.5,64.7z M95.8,78.1c0,1-0.8,1.8-1.9,1.8h-5.1V85c0,1-0.8,1.9-1.7,1.9h-0.5c-0.5,0-0.9-0.2-1.2-0.5
		c-0.3-0.3-0.5-0.8-0.5-1.3v-5.1h-5.1c-0.5,0-1-0.2-1.4-0.5c-0.3-0.3-0.5-0.7-0.5-1.2v-0.5c0-1,0.8-1.8,1.9-1.8h5.1v-5.1
		c0-1,0.8-1.9,1.8-1.9h0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.4l0,5.1H94c0.4,0,0.8,0.1,1.2,0.4c0.4,0.3,0.7,0.8,0.7,1.4
		V78.1z"/>
      <path d="M91.2,8.9H8.8C3.9,8.9,0,12.8,0,17.7v64.7c0,4.8,3.9,8.8,8.8,8.8H71c-1.1-1.6-1.7-3.5-1.7-5.6v-1v-6.2v-6.7v-1.5
		c0-5.4,4.4-9.9,9.9-9.9h14.2h1.1c2,0,3.9,0.6,5.5,1.7V17.7C100,12.8,96.1,8.9,91.2,8.9z M73.7,28.6c-1.2-2-3.7-2-4.9,0L44.4,61.8
		L34.2,49.1c-1-1.7-3.5-1.7-4.5,0L11.4,71.7H6.6v-54c0-1.2,1-2.2,2.2-2.2h82.4c1.2,0,2.2,1,2.2,2.2V54L73.7,28.6z"/>
    </IconContainer>
  )
}

export { AddImageIcon }