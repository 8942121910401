import React, { useState, useEffect } from "react"
import { Spinner, Message, InlineMessage } from "../../../"
import { ImageEditorTab } from "../../ImageEditorModal/ImageEditorTab"
import { fetchDataString } from "../../../../services/ImageServices"
import { blankSvgDataString } from "../../../../utils/utils"

const ImageEditor = ({
  tileData,
  imageName,
  backImageName,
  isUpload,
  canEdit,
  onClose,
}) => {
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [tileFront, setTileFront] = useState(null)
  const [tileBack, setTileBack] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState("front")
  const [tileFrontName] = useState(imageName)
  const [isBusy, setIsBusy] = useState(false)

  const frontId = "front",
    backId = "back"

  const tabClickHandler = (e) => {
    !isBusy && setCurrentTab(e.target.id)
  }

  useEffect(() => {
    //console.log(isUpload)
    !loading && setLoading(true)

    fetchDataString({ side: "front", imageName, backImageName, size: "large" })
      .then((data) => setTileFront(data === "data:" || !data ? null : data))
      .then(() => {
        fetchDataString({
          side: "back",
          imageName,
          backImageName,
          size: "large",
        })
          .then((data) =>
            setTileBack(data === "data:" || !data ? blankSvgDataString : data)
          )
          .then(() => setLoading(false))
          .catch((err) => console.error(err))
      })
      .catch((err) => console.error(err))
    // eslint-disable-next-line
  }, [imageName])

  return (
    <section className="flex flex-direction--column" style={{minWidth: "50vw"}}>
      {loading ? (
        <section
          className="flex align-items--center justify-content--center"
          style={{ width: "100%", height: "65vh" }}
        >
          <Spinner />
        </section>
      ) : (
        <>
          <div className="mx-24">
            <Message
              kind={useStatus.kind}
              message={useStatus.message}
              state={[useStatus, setStatus]}
            ></Message>
          </div>
          <section className="App-image-editor--tab-bar">
            <span
              id={frontId}
              onClick={tabClickHandler}
              className={`App-image-editor--tab ${
                currentTab === frontId ? "active" : ""
              } ${isBusy ? "button-disabled" : ""}`}
            >
              Tile Front
            </span>
            <span
              id={backId}
              onClick={tabClickHandler}
              className={`App-image-editor--tab ${
                currentTab === backId ? "active" : ""
              } ${isBusy ? "button-disabled" : ""}`}
            >
              Tile Back
            </span>
          </section>
          {currentTab === frontId && tileFront && (
            <ImageEditorTab
              tileData={tileData}
              dataString={tileFront}
              setDataString={setTileFront}
              imageName={tileFrontName}
              closeHandler={onClose}
              setIsBusy={setIsBusy}
              isBusy={isBusy}
              canEdit={canEdit}
            />
          )}
          {currentTab === backId && tileBack && (
            <ImageEditorTab
              tileData={tileData}
              dataString={tileBack}
              setDataString={setTileBack}
              imageName={backImageName}
              frontImageName={tileFrontName}
              closeHandler={onClose}
              setIsBusy={setIsBusy}
              isBusy={isBusy}
              canEdit={true}
              isUpload={isUpload}
            />
          )}
        </>
      )}
      <InlineMessage kind="info" message="Click the Close button to return to the Folio Overview" className="justify-self--center" />
    </section>
  )
}

export { ImageEditor }
