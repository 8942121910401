import React, { useState, useCallback } from "react"
import { PZImageWithDataString, Message } from "../.."
import { ZoomIcon } from "../../Icons"
import { StaticPreview } from "./StaticPreview"
import { blankSvgDataString } from "../../../utils/utils"
import { clsnx } from "@becks256/clsnx"
import { InstanceModal as Modal } from "../../InstanceModal/InstanceModal"
import "./ZoomIconWithPreviewModal.css"

const NoMemoZoomIconWithPreviewModal = ({
  imageName,
  backImageName,
  isUpload,
  canEdit,
}) => {
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [tileFront, setTileFront] = useState(null)
  const [tileBack, setTileBack] = useState(
    isUpload && !backImageName && blankSvgDataString
  )
  const [loading, setLoading] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const [currentTab, setCurrentTab] = useState("front")

  const frontId = "front",
    backId = "back"

  const closeHandler = useCallback(() => {
    setCurrentTab(frontId)
    setIsVisible(false)
  }, [])

  const openHandler = useCallback(() => {
    setIsVisible(true)
  }, [])

  const tabClickHandler = useCallback((e) => {
    setCurrentTab(e.target.id)
  }, [])

  return (
    <>
      <ZoomIcon
        className="faux-link image-tool m-8"
        size="large"
        onClick={openHandler}
      />
      {isVisible && (
        <Modal
          title="Image Preview"
          contentClassName="flex flex-direction--column"
          imageOnly
          onClose={closeHandler}
          show={isVisible}
        >
          <>
            <div className="mx-24">
              <Message
                kind={useStatus.kind}
                message={useStatus.message}
                state={[useStatus, setStatus]}
              ></Message>
            </div>
            {/* only display tabs if there is a front and back tile */}
            {(tileBack || canEdit) && (
              <section className="App-img-preview--tab-bar mb-16">
                <span
                  id={frontId}
                  onClick={tabClickHandler}
                  className={clsnx("App-img-preview--tab", {
                    active: currentTab === frontId,
                  })}
                >
                  Front
                </span>
                <span
                  id={backId}
                  onClick={tabClickHandler}
                  className={clsnx("App-img-preview--tab", {
                    active: currentTab === backId,
                  })}
                >
                  Back
                </span>
              </section>
            )}
            <div className="App-img-preview--wrapper flex justify-content--center">
              {canEdit || isUpload || currentTab === backId ? (
                <PZImageWithDataString
                  key="preview-container"
                  imageName={imageName}
                  backImageName={backImageName}
                  isUpload={isUpload}
                  side={currentTab}
                  id="pzpreview"
                  className="App-img-preview--image"
                  boundsSelector=".App-img-preview--wrapper"
                  containerClass="App-img-preview--image-container"
                  size="medium"
                />
              ) : (
                <StaticPreview
                  key="preview-container"
                  imageName={imageName}
                  isUpload={isUpload}
                  src={currentTab === frontId ? tileFront : tileBack}
                  loading={loading}
                  setLoading={setLoading}
                  setTileFront={setTileFront}
                  setTileBack={setTileBack}
                  side={currentTab}
                />
              )}
            </div>
          </>
        </Modal>
      )}
    </>
  )
}

// const ZoomIconWithPreviewModal = memo(NoMemoZoomIconWithPreviewModal)
// export { ZoomIconWithPreviewModal }
export { NoMemoZoomIconWithPreviewModal as ZoomIconWithPreviewModal }
