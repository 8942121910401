import React, {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  memo,
} from "react"
import { IsMobileContext, ProjectContext } from "../../App"
import { fontData } from "../../fonts/fontData"
import { DynamicImage } from "../"
import { calcInitialScaledTextSize } from "../../utils/utils"

const UnmemoizedPZImage = ({
  imageName,
  backImageName,
  projectName,
  projectOwner,
  side = "front",
  id,
  children,
  containerClass,
  className,
  rerender = 0,
  size = "small",
  wrapperClasses,
  boundsSelector,
  dataString,
  ...props
}) => {
  const { isMobile } = useContext(IsMobileContext)
  const { gridData } = useContext(ProjectContext)

  const data = useMemo(
    () =>
      gridData.project_array
        .flatMap(({ images }) => images)
        .flat()
        .filter((item) =>
          new RegExp(
            `${item?.image_name}|${item?.back_image_data?.file_name}`,
            "gi"
          ).test(imageName)
        )[0],
        //eslint-disable-next-line
    [gridData]
  )
  const [, setProjectFileName] = useState(null)
  const [imgData, setImgData] = useState({})
  const [textStyles, setTextStyles] = useState({})
  const [forceUpdate, setForceUpdate] = useState(0)
  const [loading, setLoading] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const textOverlay = useRef(null)
  const imageContainerRef = useRef(null)
  const imageRef = useRef(null)

  const updateTextStyles = useCallback(() => {
    const { fontName, fontColor, textSize, scaleFactor } = imgData
    const imageLayer = imageRef.current?.getBoundingClientRect()
    const textLayer = textOverlay.current?.getBoundingClientRect()
    setTextStyles({
      position: "absolute",
      display: "block",
      whiteSpace: "break-spaces",
      maxWidth: imageLayer?.width,
      top: `${imageLayer?.width / 2 - textLayer?.height / 2}px`,
      left: `${imageLayer?.width / 2 - textLayer?.width / 2}px`,
      fontFamily: fontName,
      fontSize: imgData.scaledFontSize,
      lineHeight: `${
        Math.ceil(textSize * imgData.lineHeighScaleFactor) * scaleFactor
      }px`,
      color: fontColor?.hexValue,
      zIndex: 1,
      userSelect: "none",
      // transitionDuration: "0.3s",
      // transitionTimingFunction: "ease-in-out",
      // transitionProperty: "font-family",
      WebkitTextStroke: `${imgData.scaledStrokeSize} ${imgData.strokeColor}`,
      MozTextStroke: `${imgData.scaledStrokeSize} ${imgData.strokeColor}`,
      paintOrder: "stroke fill",
    })
    forceUpdate < 3 && setForceUpdate(forceUpdate + 1)
    //eslint-disable-next-line
  }, [imgData, imageRef.current, textOverlay.current])

  useLayoutEffect(() => {
    let ignore = false

    if (!ignore) {
      !loading && setLoading(true)
      const { image_data = {}, back_image_data } = data
      const imageData = side === "back" ? back_image_data : image_data
      const initialTextSize = calcInitialScaledTextSize(
        imageData?.text_size || 32
      )
      const scaledImageSize = imageRef.current.getBoundingClientRect().width
      const prodImageSize = 2.75 * 96
      const scaleFactor = scaledImageSize / prodImageSize
      //console.log(scaleFactor)
      const dataObj = {
        scaledImageSize: imageRef.current.getBoundingClientRect().width,
        scaledTextBoxSize: textOverlay.current.getBoundingClientRect().height,
        textValue: imageData?.text_value || "",
        fontName: imageData?.font_name || fontData[0]["font-family"],
        fontColor: {
          hexValue: imageData?.font_color || "#000000",
        },
        textSize: initialTextSize,
        strokeSize: imageData?.text_stroke_width || 0,
        strokeColor: imageData?.text_stroke_color || "#000000",
        lineHeighScaleFactor: imageData?.lineheight_scale || 1.75,
      }

      const dataDependentObj = {
        scaleFactor,
        scaledFontSize: `${dataObj.textSize * scaleFactor}pt`,
        imageSize: dataObj.scaledImageSize,
        textBoxSize: dataObj.scaledTextBoxSize,
        scaledStrokeSize: `${dataObj.strokeSize * scaleFactor}px`,
      }

      setPosition({
        x: +imageData?.text_layer_bounds?.x * scaleFactor || 0,
        y: +imageData?.text_layer_bounds?.y * scaleFactor || 0,
      })

      setImgData({
        ...dataObj,
        ...dataDependentObj,
      })

      setProjectFileName(imageData?.file_name)
    }
    return () => {
      ignore = true
    }
    //eslint-disable-next-line
  }, [dataString, rerender, isMobile, imageRef.current, forceUpdate, data])

  useLayoutEffect(() => {
    let ignore = false

    !ignore && updateTextStyles()

    return () => {
      ignore = true
    }
    //eslint-disable-next-line
  }, [imgData, position, isMobile, imageRef.current, textOverlay.current])

  const onControlledDrag = useCallback(
    (e, position) => {
      const { x, y } = position
      setPosition({ x, y })
    },
    //eslint-disable-next-line
    [position]
  )

  const onControlledDragStop = useCallback(
    (e, position) => {
      onControlledDrag(e, position)
    },
    //eslint-disable-next-line
    [position]
  )

  return (
    <div
      ref={imageRef}
      className={`relative box-sizing--border-box overflow--hidden ${
        wrapperClasses || ""
      }`}
    >
      <DynamicImage
        id={id}
        dataString={dataString}
        className={className}
        containerClass={`flex ${containerClass ? containerClass : ""}`}
        bounds={boundsSelector}
        editable={false}
        text={imgData.textValue}
        controlledPos={position}
        onDrag={onControlledDrag}
        onStop={onControlledDragStop}
        textOverlay={textOverlay}
        textStyles={textStyles}
        containerRef={imageContainerRef}
        {...props}
      >
        {children}
      </DynamicImage>
    </div>
  )
}

const PZImage = memo(UnmemoizedPZImage)

export { PZImage }
