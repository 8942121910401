import React, { memo, useContext } from "react"
import { ProjectContext } from "../../../App"
import { EmptyTileMapper } from "./EmptyTileMapper"

const UnmemoizedAddImageTiles = ({
  clickHandler,
  email,
  parentIndex,
}) => {
  const { gridData } = useContext(ProjectContext)

  return (
    gridData &&
    gridData?.project_array
      .filter((a, b) => a.email?.toLowerCase() === email?.toLowerCase())
      .map((item, index) => (
        <EmptyTileMapper
          key={`empty-tile-${index}`}
          tiles={item?.tiles}
          images={item?.images}
          parentIndex={parentIndex}
          mapToParent
          isMyTileView
          noImageClickHandler={clickHandler}
        />
      ))
  )
}

const AddImageTiles = memo(UnmemoizedAddImageTiles)
export { AddImageTiles }
