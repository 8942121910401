import React, { useState } from "react"
import { HeaderLinks } from "./HeaderLinks"
import "./MobileHeader.css"

const MobileHeader = ({ links, location }) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheck = (e) => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <input
        type="checkbox"
        id="hamburger-input"
        className="burger-shower"
        checked={isChecked}
        onChange={handleCheck}
      />
      <label id="hamburger-menu" htmlFor="hamburger-input">
        <section className="flex flex-direction--column">
          <span></span>
          <span></span>
          <span></span>
        </section>
        <nav id="sidebar-menu">
          <h3 className="mt-64 mb-32">Menu</h3>
          <ul>
            <HeaderLinks links={links} location={location} handleCheck={handleCheck}/>
          </ul>
        </nav>
      </label>
      <div className="overlay" onClick={handleCheck}></div>
    </>
  )
}

export { MobileHeader }
