import React from 'react'
import './Container.css'

const Container = (props) => {
  const { children, center, className } = props
  return (
    <div className={`App-container ${center ? '-center' : ''} ${className ? className : ''}`} >
      { children }
    </div>
  )
}

export { Container }