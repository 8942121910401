import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react"
import { PZImageWithDataString, Message, Image } from "../../.."
import { api } from "../../../../utils/api"
import { blankSvgDataString } from "../../../../utils/utils"
import { ReactSortable } from "react-sortablejs"
import "./SortableImages.css"
import { IsMobileContext, ProjectContext } from "../../../../App"

const SortableImages = ({ saveChanges }) => {
  const { gridData, setGridData } = useContext(ProjectContext)
  const { isMobile } = useContext(IsMobileContext)
  const dragItem = useRef(-1)
  const dragOverItem = useRef(-1)
  const selectedItem = useRef()
  const [images, setImages] = useState(null)

  const [messageStatus, setMessageStatus] = useState({
    visible: true,
    message: isMobile
      ? "To re-arrange tiles, first tap the image you want to move, then tap a different image to insert before it."
      : "To re-arrange tiles, click and drag the image you want to move, then drop it on top of a different image to insert before it.",
    kind: "info",
  })

  const updateProjectData = (imagesCopy) => {
    let projectDataCopy = gridData
    for (let obj of imagesCopy) {
      for (let { images } of projectDataCopy.project_array) {
        for (let i in images) {
          let image = images[i]
          if (image.image_name === obj.image_name) {
            image.sort_order = obj.sort_order /* eslint-disable-line */
          }
        }
      }
    }
    setGridData(projectDataCopy)
    saveChanges({ saveAndContinue: false })
  }

  const updateSortOrder = () => {
    const imagesCopy = [...images]
    for (let [index, item] of imagesCopy.entries()) {
      item.sort_order = index
    }
    updateProjectData(imagesCopy)
  }

  const touchStart = (position) => (e) => {
    if (dragItem.current === -1) {
      e.target.classList.toggle("selected")
      selectedItem.current = e.target
      dragItem.current = position
    } else if (dragItem.current > -1 && dragItem.current !== position) {
      const imagesCopy = [...images]
      const dragItemContent = imagesCopy[dragItem.current]
      selectedItem.current.classList.toggle("selected")
      dragOverItem.current = position
      imagesCopy.splice(dragItem.current, 1)
      imagesCopy.splice(dragOverItem.current, 0, dragItemContent)
      dragItem.current = -1
      dragOverItem.current = -1
      for (let [index, item] of imagesCopy.entries()) {
        item.sort_order = index
      }
      updateProjectData(imagesCopy)
      setImages(imagesCopy)
    } else {
      e.target.classList.toggle("selected")
      dragItem.current = -1
      return
    }
  }

  const mapSortedTilesForDesktop = useCallback(
    () => (
      <ReactSortable
        list={images}
        setList={setImages}
        className="App-sortable-images--container"
        onEnd={updateSortOrder}
        animation={300}
        delayOnTouchStart={true}
        delay={2}
        ghostClass="App-sortable-image--ghost"
      >
        {images.map(({ image_name, canEdit }, index) => (
          <section
            key={`tile-sort-${index}`}
            id={`dnd-item-${index}`}
            className="flex flex-direction--column align-items--center mb-8"
          >
            {canEdit ? (
              <PZImageWithDataString
                imageName={image_name}
                side="front"
                className="App-sortable-image"
                boundsSelector=".App-sortable-image"
                containerClass="relative cursor-grab"
                wrapperClasses="m-8"
              />
            ) : (
              <Image
                inert="true"
                className="App-sortable-image"
                containerClass="flex relative cursor-grab m-8"
                src={
                  image_name
                    ? api.getImageBySize({ size: "small", image_name })
                    : blankSvgDataString
                }
                alt=""
              />
            )}
            {`Page ${index + 1}`}
          </section>
        ))}
      </ReactSortable>
    ),
    // eslint-disable-next-line
    [images]
  )

  const mapSortedTilesForMobile = useCallback(
    () =>
      images.map(({ image_name, canEdit }, index) => (
        <section
          key={`tile-sort-${index}`}
          id={`dnd-item-${index}`}
          onClick={(e) => touchStart(index)(e)}
          className="flex flex-direction--column align-items--center mb-8"
        >
          {canEdit ? (
            <PZImageWithDataString
              imageName={image_name}
              className="App-sortable-image"
              boundsSelector=".App-sortable-image"
              containerClass="relative"
              wrapperClasses="m-8"
            />
          ) : (
            <Image
              className="App-sortable-image"
              containerClass="relative m-8"
              src={api.getImageBySize({ size: "small", image_name })}
              alt=""
            />
          )}
          {`Page ${index + 1}`}
        </section>
      )),
      // eslint-disable-next-line
    [images]
  )

  useEffect(() => {
    const flattenedImagesArray = gridData?.project_array
      .flatMap(({ images }) => images)
      .flat()
      .sort((a, b) => a.sort_order - b.sort_order)

    const imagesCopy = [...flattenedImagesArray]
    for (let [index, item] of imagesCopy.entries()) {
      item.sort_order = item.sort_order === null ? index : item.sort_order
    }
    updateProjectData(imagesCopy)
    setImages(imagesCopy)
    // eslint-disable-next-line
  }, [])

  const classNames = isMobile
    ? `flex flex-wrap flex-direction--row justify-content--space-evenly App-sortable-image--container`
    : `App-sortable-image--container`

  return (
    <section
      className={classNames}
      // style={{ maxHeight: "65vh", overflowY: "scroll" }}
    >
      {/* <h3>{isMobile ? mobileDirections : standardDirections}</h3> */}
      {messageStatus.visible && (
        <section className="flex justify-content--center sm:px-16 py-16 w-full">
          <Message
            visible={messageStatus.visible}
            message={messageStatus.message}
            kind={messageStatus.kind}
            state={[messageStatus, setMessageStatus]}
          />
        </section>
      )}
      {images && isMobile && mapSortedTilesForMobile()}
      {images && !isMobile && mapSortedTilesForDesktop()}
    </section>
  )
}

export { SortableImages }
