import { api } from "../utils/api"
import { blankSvgDataString } from "../utils/utils"

export const getImageData = async ({
  imageName,
  projectName,
  projectOwner,
  projectFileName,
  session,
  email = false,
  signal,
}) => {
  const res = await fetch(api.getImageData, {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
    },
    body: JSON.stringify({
      image_name: imageName,
      file_name: projectFileName,
      project_name: projectName,
      owner: projectOwner,
      token: session.token,
      email: email ? session.email : undefined,
    }),
    signal,
  })
  return await res.json()
}

export const fetchDataString = ({
  side,
  imageName,
  backImageName,
  size,
}) =>
  new Promise((resolve, reject) => {
    if (
      side === "back" &&
      (!backImageName || !imageName?.replace(/front/gi, "back"))
    ) {
      resolve(blankSvgDataString)
    }
    const imageToFetch =
      side === "back"
        ? backImageName || imageName.replace(/front/gi, "back")
        : imageName
    fetch(
      api.getImageBySize({
        image_name: imageToFetch,
        size,
      }),
      { method: "GET" }
    )
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        } // <--- `this.result` contains a base64 data URI
        reader.onerror = function () {
          reject(this.error)
        }
        reader.readAsDataURL(blob)
      })
  })

export const uploadImageData = async ({ token, email, formData, isBack }) => {
  const res = await fetch(api.uploadImage({ isBack }), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      upload_user: email,
    },
    body: formData,
  })
  return await res.json()
}

export const addUploadToProject = async ({
  image_name,
  projectName,
  token,
  email,
  projectOwner,
  angle = 0,
  isBack,
  frontImageName,
}) => {
  const res = await fetch(api.addCustomImage({ isBack, frontImageName }), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      image_name,
      project_name: projectName,
      token,
      email,
      owner: projectOwner,
      angle: angle,
    }),
  })
  return await res.json()
}

export const getProjectData = async ({
  email,
  projectName,
  projectOwner,
  token,
}) => {
  const res = await fetch(api.getProject, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      project_name: projectName,
      email,
      owner: projectOwner,
    }),
  })
  return await res.json()
}
