import { clsnx } from "@becks256/clsnx"
import React, {
  useState,
  useContext,
  useRef,
  useCallback,
} from "react"
import { Message, InlineMessage, ProgressBar } from "../"
import { IsMobileContext, SessionContext, ProjectContext } from "../../App"
import { addUploadToProject } from "../../services/ImageServices"
import "./DragAndDropUpload.css"
import getCroppedImg, { createImage } from "../../utils/cropImage"
import Cropper from "react-easy-crop"
import { EditorControls } from "./EditorControls"
import { api } from "../../utils/api"

const DragAndDropUploader = ({
  accept,
  multiple = false,
  className,
  imageName,
  setDataString,
  setIsBlank,
  imageSize,
  setBackImageName,
}) => {
  const constants = {
    NAME: "drag-drop-uploader",
    INPUT_CLASS: "App-drag-drop-uploader--input",
  }

  const UpdateStatus = {
    SAVE: "Save",
    SAVING: "Saving...",
    UPLOADING: "Uploading",
    SUCCESS: "Success!",
    ERROR: "Error",
  }
  const defaultStatus = { visible: false, kind: undefined, message: undefined }
  const [dragActive, setDragActive] = useState(false)
  const [, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [updateStatus, setUpdateStatus] = useState(UpdateStatus.SAVE)
  const [useStatus, setStatus] = useState(defaultStatus)
  const [angle, setAngle] = useState(0)
  const [tempImageUri, setTempImageUri] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isLandscape, setIsLandscape] = useState(false)
  const { isMobile } = useContext(IsMobileContext)
  const { session } = useContext(SessionContext)
  const { projectName, projectOwner } = useContext(ProjectContext)
  const inputRef = useRef()
  const uploadedImage = useRef()
  const croppedImage = useRef()

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const imageSelectHandler = async (file) => {
    if (
      new RegExp(/\.(jpe?g|png|webp|tiff?)$/gi).test(file.name) &&
      file.size <= 33554432
    ) {
      const dataURI = await convertBase64(file)
      const tempImage = await createImage(dataURI)
      setIsLandscape(tempImage.width >= tempImage.height)
      setSelectedFile(file)
      setTempImageUri(dataURI)
    } else {
      setStatus({
        visible: true,
        kind: "error",
        message: "The selected file is not the correct format or is too large",
      })
    }
    //setIsSelected(true)
  }

  const rotationHandler = () => {
    angle < 360 - 90 ? setAngle(angle + 90) : setAngle(0)
  }

  const uploadImageXHR = async (image) =>
    new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append("File", image, selectedFile.name)
      setUpdateStatus(UpdateStatus.UPLOADING)
      let xhr = new XMLHttpRequest()

      xhr.open("POST", api.uploadImage({ isBack: true }), true)

      xhr.setRequestHeader("Authorization", `Bearer ${session.token}`)
      xhr.setRequestHeader("upload_user", session.email)

      xhr.upload.onprogress = (e) =>
        setProgress(Math.ceil((e.loaded / e.total) * 100))

      xhr.onload = () => {
        if (xhr.status !== 200) {
          reject({ name: undefined, status: xhr.status })
        } else {
          let response = JSON.parse(xhr.response)
          resolve({ name: response.file_name, status: response.status })
        }
      }

      xhr.send(formData)
    })

  const saveHandler = async () => {
    setUpdateStatus(UpdateStatus.SAVING)
    setIsLoading(true)
    croppedImage.current = await getCroppedImg(
      tempImageUri,
      croppedAreaPixels,
      angle,
      undefined,
      true
    )
    const imageDidUpload = await uploadImageXHR(croppedImage.current.blob)
    uploadedImage.current = imageDidUpload.name
    setUpdateStatus(UpdateStatus.UPLOAD)
    if (imageDidUpload.status === 200) {
      setUpdateStatus(UpdateStatus.SAVING)
      const data = await addUploadToProject({
        image_name: uploadedImage.current,
        projectName,
        token: session.token,
        email: session.email,
        projectOwner,
        angle: 0,
        isBack: true,
        frontImageName: imageName,
      })
      if (data.status === 200) {
        setUpdateStatus(UpdateStatus.SUCCESS)
        setIsLoading(false)
        setTimeout(() => {
          setDataString(croppedImage.current.base64)
          setBackImageName(uploadedImage.current)
          setIsBlank(false)
        }, 500)
      } else {
        setUpdateStatus(UpdateStatus.ERROR)
        setStatus({
          visible: true,
          kind: "error",
          message: data.msg || "The file couldn't be added to the project.",
        })
      }
    } else {
      setUpdateStatus(UpdateStatus.ERROR)
      setStatus({
        visible: true,
        kind: "error",
        message:
          imageDidUpload.msg || "The file couldn't be added to the project.",
      })
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (/drag(enter|over)/gi.test(e.type)) {
      !dragActive && setDragActive(true)
    } else {
      dragActive && setDragActive(false)
    }
  }

  const handleDrop = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragActive && setDragActive(false)
    e?.dataTransfer?.files &&
      (await imageSelectHandler(e?.dataTransfer?.files[0]))
  }

  const handleChange = async (e) => {
    e.preventDefault()
    e?.target?.files && (await imageSelectHandler(e?.target?.files[0]))
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  return (
    <>
      {tempImageUri ? (
        <section className="flex flex-direction--column">
          <section
            className="relative"
            style={{ height: imageSize, width: imageSize }}
          >
            {new RegExp(
              `${UpdateStatus.SAVING}|${UpdateStatus.UPLOADING}|${UpdateStatus.UPLOAD}`,
              "gi"
            ).test(updateStatus) && (
              <div className="App-uploader-msg bg-true-white p-16 border-radius box-shadow--medium">
                <InlineMessage
                  className="mb-16"
                  message={
                    updateStatus === UpdateStatus.UPLOADING
                      ? "I'm uploading the image now.  I'll add it to your project and refresh this editor when it's complete."
                      : updateStatus === UpdateStatus.UPLOAD
                      ? "Upload was successful! Taking care of a few technical things..."
                      : "Adding the image to your project..."
                  }
                />
                <ProgressBar progress={progress} />
              </div>
            )}
            <Cropper
              image={tempImageUri}
              crop={crop}
              rotation={angle}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onRotationChange={setAngle}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              objectFit={
                `${isLandscape ? "vertical" : "horizontal"}-cover`
              }
            />
          </section>
          <EditorControls
            rotationHandler={rotationHandler}
            saveHandler={saveHandler}
            zoomHandler={setZoom}
            zoom={zoom}
            status={updateStatus}
            constants={UpdateStatus}
          />
        </section>
      ) : (
        <form
          className={clsnx({ className })}
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            className="display-none"
            id={constants.NAME}
            accept={accept}
            multiple={multiple}
            onChange={handleChange}
          />
          <label
            htmlFor={constants.NAME}
            className="App-drag-drop-uploader--label flex align-items--center justify-content--center"
          >
            {isMobile ? (
              <button
                onClick={handleClick}
                className="w-60 App-drag-drop-uploader--button"
              >
                Tap to Upload
              </button>
            ) : (
              <Message visible fixed kind="info" className="mx-16 w-70" inert>
                <p
                  className={`App-message--text color-static-text ml-24 font-size--11 transition-message`}
                >
                  {dragActive ? (
                    "Drop it!"
                  ) : (
                    <>
                      Click here{" "}
                      <em>
                        <b>
                          <u>or</u>
                        </b>
                      </em>{" "}
                      drag and drop an image to personalize this tile.
                      <br />
                      <br />
                      You may also add image text without a background image.
                    </>
                  )}
                </p>
              </Message>
            )}
            {!isMobile && dragActive && (
              <div
                className="drag-drop-focus"
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
            {useStatus.visible && (
              <div className="md:my-16 md:mx-24">
                <Message
                  kind={useStatus.kind}
                  message={useStatus.message}
                  state={[useStatus, setStatus]}
                />
              </div>
            )}
          </label>
        </form>
      )}
    </>
  )
}

export { DragAndDropUploader }
