import React, { useContext, useState, memo, useCallback } from "react"
import { ModalContext } from "../../Pages/ProjectWorkspace"
import {
  Image,
  PZImageWithDataString,
  Modal,
  Button,
  Message,
} from ".."
import { TrashIcon } from "../Icons"
import { api } from "../../utils/api"
import { ZoomIconWithPreviewModal } from "../Modals/ImagePreviewModal/ZoomIconWithPreviewModal"
import { EditIconWithEditorModal } from "../Modals/ImageEditorModal/EditIconWithEditorModal"

const UnmemoizedGridTile = ({
  tileData,
  imageName,
  backImageName,
  canEdit,
  deleteHandler,
  isUpload,
  id,
  containerClass,
  className,
  wrapperClasses,
  boundsSelector,
}) => {
  const { setModalHidden } = useContext(ModalContext)
  const [activeModal, setActiveModal] = useState(null)
  const [imageToDelete, setImageToDelete] = useState(null)

  const deletePreHandler = useCallback(() => {
    setImageToDelete(imageName)
    setActiveModal("confirmation")
    setModalHidden(false)
    // eslint-disable-next-line
  }, [imageName])

  const onDelete = useCallback(() => {
    deleteHandler(imageToDelete)
    // eslint-disable-next-line
  }, [imageToDelete])

  const closeHandler = useCallback(() => {
    setActiveModal(null)
    setModalHidden(true)
    // eslint-disable-next-line
  }, [])

  const ImageTools = memo(() => (
    <>
      <div className="App-project-grid--image-overlay"></div>
      <div className="App-project-grid--image-tools align-self--center">
        <div className="flex flex-direction--row flex-wrap justify-content--space-evenly">
          <ZoomIconWithPreviewModal
            imageName={imageName}
            backImageName={backImageName}
            isUpload={isUpload}
            canEdit={canEdit}
          />
          {/* <HeartIcon id="heart-icon" className="faux-link image-tool m-8" size="large" /> */}
          <EditIconWithEditorModal
            tileData={tileData}
            imageName={imageName}
            backImageName={backImageName}
            isUpload={isUpload}
            canEdit={canEdit}
          />
          <TrashIcon
            className="faux-link image-tool m-8"
            size="large"
            onClick={deletePreHandler}
          />
        </div>
      </div>
    </>
  ))

  return (
    <>
      {activeModal === "confirmation" && imageToDelete && (
        <Modal
          title="Delete Image Confirmation"
          contentClassName="flex flex-direction--column justify-content--space-between"
          closeHandler={closeHandler}
        >
          <h1 className="center-text align-self--center paragraph paragraph-xl">
            Are you sure you want to delete this image?
          </h1>
          <div className="flex md:flex-direction--row sm:flex-direction--column justify-content--space-around">
            <Button
              className="App-project-grid--yes-button font-size--11 sm:mb-16"
              onClick={onDelete}
            >
              YES, DELETE
            </Button>
            <Button className="font-size--11" onClick={closeHandler}>
              CANCEL
            </Button>
          </div>
          <div className="my-24 md:mx-48">
            <Message
              fixed
              kind="error"
              message="Warning: This will irreversibly delete this image from your project! Click CANCEL or close this dialog to abort."
            ></Message>
          </div>
        </Modal>
      )}
      {canEdit ? (
        <PZImageWithDataString
          id={id}
          className={className}
          boundsSelector={boundsSelector}
          containerClass={containerClass}
          imageName={imageName}
          wrapperClasses={wrapperClasses}
        >
          <ImageTools />
        </PZImageWithDataString>
      ) : (
        <Image
          id={id}
          className={className}
          containerClass={`outline-solid border-radius--smaller ${
            containerClass ? containerClass : ""
          }`}
          src={api.getImageBySize({
            isPz: canEdit,
            image_name: imageName,
          })}
          alt=""
        >
          {<ImageTools />}
        </Image>
      )}
    </>
  )
}

const GridTile = memo(UnmemoizedGridTile)

export { GridTile }
