import React from "react"
import { IconContainer } from "./IconContainer"

const SaveIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M29.9,34.3h28.5c0.7,0,1.3-0.6,1.3-1.3V3.4c0-0.5-0.4-0.9-0.9-0.9H29.4c-0.5,0-0.9,0.4-0.9,0.9v29.5
	C28.6,33.7,29.2,34.3,29.9,34.3z"
      />
      <path
        d="M29.4,97.5h41.2c0.5,0,0.9-0.4,0.9-0.9v-6.1c0-0.7-0.5-1.2-1.2-1.2H29.8c-0.7,0-1.2,0.5-1.2,1.2v6.1
	C28.6,97.1,29,97.5,29.4,97.5z"
      />
      <path
        d="M70.1,71.7H29.9c-0.7,0-1.3,0.6-1.3,1.3v6.5c0,0.7,0.5,1.2,1.2,1.2h40.5c0.7,0,1.2-0.5,1.2-1.2v-6.5
	C71.5,72.3,70.9,71.7,70.1,71.7z"
      />
      <path
        d="M96.2,23.7L76.3,3.8c-0.8-0.8-2-1.3-3.2-1.3h-3.4c-0.5,0-0.9,0.4-0.9,0.9v35.2c0,2.5-2,4.5-4.5,4.5H23.9
	c-2.5,0-4.5-2-4.5-4.5V3.4c0-0.5-0.4-0.9-0.9-0.9H7c-2.5,0-4.5,2-4.5,4.5v86c0,2.5,2,4.5,4.5,4.5h11.6c0.5,0,0.9-0.4,0.9-0.9V67.4
	c0-2.5,2-4.5,4.5-4.5h52.2c2.5,0,4.5,2,4.5,4.5v29.2c0,0.5,0.4,0.9,0.9,0.9H93c2.5,0,4.5-2,4.5-4.5V26.9
	C97.5,25.7,97,24.5,96.2,23.7z"
      />
    </IconContainer>
  )
}

export { SaveIcon }
