import React from "react"
import "./ProgressBar.css"

export const ProgressBar = ({ progress }) => {
  return (
    <div className="App-ProgressBar">
      <div
        style={{
          width: `${progress}%`,
          height: "100%",
          backgroundColor: "var(--color-interactive-90)",
          borderRadius: progress > 90 ? "625rem" : "625rem 0 0 625rem",
          transition: "width 0.2s ease-in-out"
        }}
      ></div>
      <span
        style={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: "8pt",
        }}
      >
        {progress}%
      </span>
    </div>
  )
}
