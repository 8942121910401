import React from 'react'
import { IconContainer } from './IconContainer'

const PlusIcon = ({ fill, size, className, bordered, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      {
          bordered 
          ?
            <>
              <path d="M84,0H65.8H16C7.2,0,0,7.2,0,16v68c0,8.8,7.2,16,16,16h49.8H84c8.8,0,16-7.2,16-16V65.8V34.2V16C100,7.2,92.8,0,84,0z
                M92,84c0,4.4-3.6,8-8,8H61.4H16c-4.4,0-8-3.6-8-8V16c0-4.4,3.6-8,8-8h45.4H84c4.4,0,8,3.6,8,8v22.6v22.8V84z"/>
              <path d="M75.5,43.8H56.2V24.5c0-1.6-1.3-3-3-3h-6.6c-1.6,0-3,1.3-3,3v19.3H24.5c-1.6,0-3,1.3-3,3v6.6c0,1.6,1.3,3,3,3h19.3v19.3
                c0,1.6,1.3,3,3,3h6.6c1.6,0,3-1.3,3-3V56.2h19.3c1.6,0,3-1.3,3-3v-6.6C78.5,45.1,77.1,43.8,75.5,43.8z"/>
            </>
          :
            <>
              <path d="M91.8,39H61V8.2C61,3.7,57.3,0,52.7,0h-5.4C42.7,0,39,3.7,39,8.2V39H8.2C3.7,39,0,42.7,0,47.3v5.4C0,57.3,3.7,61,8.2,61H39 v30.8c0,4.5,3.7,8.2,8.2,8.2h5.4c4.5,0,8.2-3.7,8.2-8.2V61h30.8c4.5,0,8.2-3.7,8.2-8.2v-5.4C100,42.7,96.3,39,91.8,39z"/>
            </>
      }
    </IconContainer>
  )
}

export { PlusIcon }