import React, { useEffect, memo } from "react"
import { Image } from "../../"
import { blankSvgDataString } from "../../../utils/utils"
import { fetchDataString } from "../../../services/ImageServices"

const UnmemoizedStaticPreview = ({
  imageName,
  isUpload,
  setTileBack,
  setTileFront,
  setLoading,
  loading,
  src,
  side,
}) => {
  useEffect(() => {
    !loading && setLoading(true)

    isUpload
      ? fetchDataString({ side: "front", imageName, size: "medium" })
          .then((data) => setTileFront(data === "data:" || !data ? null : data))
          .then(() => {
            setTileBack(blankSvgDataString)
            setLoading(false)
          })
          .catch((err) => console.error(err))
      : fetchDataString({ side: "front", imageName, size: "medium" })
          .then((data) => setTileFront(data === "data:" || !data ? null : data))
          .then(() => {
            fetchDataString({ side: "back", imageName, size: "medium" })
              .then((data) =>
                setTileBack(data === "data:" || !data ? null : data)
              )
              .then(() => setLoading(false))
              .catch((err) => console.error(err))
          })
          .catch((err) => console.error(err))
    // eslint-disable-next-line
  }, [imageName])

  return (
    <Image
      containerClass="App-img-preview--image-container m-8"
      className="App-img-preview--image"
      src={src}
    ></Image>
  )
}

const StaticPreview = memo(UnmemoizedStaticPreview)

export { StaticPreview }
