import React from "react"

export const ZoomController = ({value, changeHandler}) => {
  return (
    <section className="flex flex-direction--column justify-content--center align-items--center">
      <input
        id="zoom"
        type="range"
        value={value}
        min={1}
        max={3}
        step={0.1}
        aria-labelledby="Zoom"
        onChange={(e) => changeHandler(+e.currentTarget.value)}
      />
      <label htmlFor="zoom" className="mt-8 color-gray--60">
        Zoom
      </label>
    </section>
  )
}
