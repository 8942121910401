import React from 'react'
import { IconContainer } from './IconContainer'

const DisabledImageIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <circle cx="19.6" cy="32.7" r="6.1" />
      <path d="M62.8,74.3c0-11.3,9.2-20.5,20.5-20.5c6.9,0,13.1,3.5,16.8,8.8V62v-4.8V17.7c0-4.8-3.9-8.8-8.8-8.8H8.8
		C3.9,8.9,0,12.8,0,17.7v64.7c0,4.8,3.9,8.8,8.8,8.8h58.1H71h0.5C66.2,87.4,62.8,81.3,62.8,74.3z M34.2,49.1c-1-1.7-3.5-1.7-4.5,0
		L11.4,71.7H6.6v-54c0-1.2,1-2.2,2.2-2.2h82.4c1.2,0,2.2,1,2.2,2.2V54L73.7,28.6c-1.2-2-3.7-2-4.9,0L44.4,61.8L34.2,49.1z"/>
      <path d="M95.1,62.5c-6.5-6.5-17.2-6.5-23.7,0c-6.5,6.5-6.5,17.2,0,23.7c3.2,3.2,7.4,4.9,11.9,4.9s8.7-1.7,11.9-4.9
		c3.2-3.2,4.9-7.4,4.9-11.9S98.3,65.7,95.1,62.5z M77.4,64.7c4.4-2.7,10.1-2,13.8,1.7c3.7,3.7,4.3,9.4,1.7,13.8L77.4,64.7z M89,84
		c-4.4,2.7-10.1,2-13.8-1.7c-3.7-3.7-4.3-9.4-1.7-13.8L89,84z"/>
      <path d="M83.6,63.4" />
    </IconContainer>
  )
}

export { DisabledImageIcon }