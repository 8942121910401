import React from "react"
import { InlineMessage } from "../../../"
const StatusMessage = ({ canEdit, saveStatus }) => {
  return (
    <section className="my-16 ">
      {canEdit ? (
        <InlineMessage
          kind={
            /file saved!/gi.test(saveStatus)
              ? "success"
              : /saving\.\.\./gi.test(saveStatus)
              ? "warning"
              : "info"
          }
          message={saveStatus}
          className="App-image-editor--inline-message"
        />
      ) : (
        <InlineMessage
          className="App-image-editor--inline-message"
          kind="warning"
          message="This image cannot be modified."
        />
      )}
    </section>
  )
}

export { StatusMessage }
