import React from 'react'
import './PageWrapper.css'

const PageWrapper = ({children}) => {
  
  return (
    <div className="Page-wrapper">
      { children }
    </div>
  )
}

export { PageWrapper }