import React from "react"
import { TextInput } from "../../"
import { EditAndSaveButton } from "./"

const EditableField = ({
  isEditing,
  onEdit,
  onSave,
  onCancel,
  defaultValue,
  title,
  label,
  onChange,
}) => {
  return (
    <section className="flex flex-direction--row justify-content--space-evenly sm:mt-24 box-sizing--border-box lg:w-50 w-full px-16">
      <TextInput
        type="text"
        wrapperClasses="flex sm:flex-direction--column flex-direction--row align-items--center pt-16 w-full"
        inputClasses="w-full box-sizing--border-box"
        labelClasses="md:mr-16 white-space--nowrap sm:mb-8 App-project-mgmt-label"
        label={label}
        title={title}
        value={defaultValue}
        disabled={!isEditing}
        onChange={onChange}
      />
      <section className="align-self--flex-end pt-16 ml-16">
        <EditAndSaveButton
          isEditing={isEditing}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
      </section>
    </section>
  )
}

export { EditableField }
