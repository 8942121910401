import React, { useState, useRef, useContext } from "react"
import { QuantityStepper } from "../.."
import { SessionContext, ProjectContext } from "../../../App"
import { api } from "../../../utils/api"

const ManageTileCountStepper = ({ tiles, contributorEmail, projectName }) => {
  const [quantity, setQuantity] = useState(tiles)
  const [saving, setSaving] = useState(false)
  const { refreshGridData } = useContext(ProjectContext)
  const { session } = useContext(SessionContext)
  const timeoutID = useRef(null)

  const submitNewTileCount = (qty) => {
    setSaving(true)
    fetch(api.modifyTileCountForUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        owner: session.email,
        email: contributorEmail,
        project_name: projectName,
        tiles: qty
      })
    })
    .then(res => res.json())
    .then(data => {
        setSaving(false)
        refreshGridData()
    })
    .catch((err) => console.log(err))
  }

  const save = ({delay = 500, qty}) => {
    timeoutID.current && clearTimeout(timeoutID.current)

    timeoutID.current = setTimeout(() => {
      submitNewTileCount(qty)
    }, delay)
  }
  const increaseCount = () => {
    const qty = +quantity + 1
    setQuantity(qty)
    save({qty})
  }

  const decreaseCount = () => {
    const qty = +quantity - 1
    setQuantity(qty)
    save({qty})
  }

  const inputChangeHandler = (e) => {
    const qty = +(e.currentTarget.value)
    setQuantity(qty)
    save({qty})
  }

  return (
    <QuantityStepper
      quantity={quantity}
      disabled={saving || !contributorEmail}
      onIncrease={increaseCount}
      onDecrease={decreaseCount}
      onChange={inputChangeHandler}
    />
  )
}

export { ManageTileCountStepper }
