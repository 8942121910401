import React from "react"
import { IconContainer } from "./IconContainer"

const RemoveUserIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M98.6,66.4c1-1,1.5-2.4,1.3-3.9c-0.1-1-0.6-2-1.4-2.8l-4.6-4.6l4.6-4.6c1.9-1.9,2-4.8,0.1-6.7l-0.5-0.5
		c-1.8-1.8-4.8-1.8-6.7,0.1L86.8,48l-4.6-4.6c-1.9-1.9-4.8-2-6.7-0.1l-0.5,0.5c-0.9,0.9-1.3,2-1.3,3.2c0,1.3,0.5,2.5,1.5,3.5
		l4.6,4.6l-4.6,4.6c-0.9,0.9-1.5,2.2-1.5,3.5c0,1.2,0.5,2.4,1.3,3.2l0.5,0.5c1.8,1.8,4.8,1.8,6.7-0.1l4.6-4.6l4.6,4.6
		c1.9,1.9,4.8,2,6.7,0.1L98.6,66.4z"
      />
      <circle cx="43.7" cy="28.4" r="28.4" />
      <path d="M0,100c0-20.4,16.7-37,37-37h13.2c20.4,0,37,16.7,37,37v0L0,100L0,100z" />
    </IconContainer>
  )
}

export { RemoveUserIcon }
