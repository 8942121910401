import React, { Children, memo } from "react"
import { GridTile } from "../GridTile"

const UnmemoizedTileMapper = ({
  images,
  deleteHandler,
}) => {
  return Children.toArray(
    images.map((item, index) => {
      const {
        image_name,
        canEdit,
        back_image_data,
        isUpload,
      } = item
      return (
        <GridTile
          tileData={item}
          imageName={image_name}
          backImageName={back_image_data.file_name}
          canEdit={canEdit}
          deleteHandler={deleteHandler}
          isUpload={isUpload}
          id={`dynamic-image--${index}`}
          className="App-project-grid--image outline-solid border-radius--smaller outline-offset--1px"
          boundsSelector=".App-project-grid--image"
          containerClass="App-project-grid--my-tiles-image-container"
          alt=""
          wrapperClasses="sm:w-100"
        />
      )
    })
  )
}

const TileMapper = memo(UnmemoizedTileMapper)
export { TileMapper }
