import React from "react"
import { RotationController } from "./Components/RotationController"
import { SaveController } from "./Components/SaveController"
import { ZoomController } from "./Components/ZoomController"

export const EditorControls = ({
  rotationHandler,
  saveHandler,
  zoomHandler,
  zoom,
  status,
  constants,
}) => {
  return (
    <section className="absolute align-items--flex-end flex flex-direction--row justify-content--space-evenly m-auto w-60 App-editor-controls border-radius--smaller bg-true-white">
      <RotationController clickHandler={rotationHandler} />
      <ZoomController changeHandler={zoomHandler} value={zoom} />
      <SaveController
        clickHandler={saveHandler}
        status={status}
        constants={constants}
      />
    </section>
  )
}
