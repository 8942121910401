import React, { useState } from 'react'
import './Accordion.css'

const Accordion = ({title, children, titleColor = 'brand--primary'}) => {
  const [expand, setExpand] = useState(false)
  const clickHandler = () => setExpand(!expand) 

  return (
    <div className="App-accordion">
      <section className="flex flex-direction--row align-items--center pt-16">
        <span className={`App-accordion--caret ${expand ? 'rotate-180' : ''}`}>▴</span>
        <p className={`App-accordion--title paragraph paragraph-l color-${titleColor} italic`} onClick={clickHandler}>{ title }</p>
      </section>
      <section className={`App-accordion--content App-accordion--${expand ? 'open' : 'close'}`}>
        {children}
      </section>
    </div>
  )
}

export { Accordion }