import React, { useState } from "react"
import { Image, InlineMessage } from "../../.."
import { api } from "../../../../utils/api"
import "./ImagePreview.css"

const ImagePreview = ({
  img,
  previousModalState,
  setModalProgress,
  imagePair = true,
  fetchImage = true,
  isMobile,
}) => {
  const frontId = "front"
  const backId = "back"
  const frontImage = img
  const backImage = img.replace(/front/gi, "back")
  const [currentTab, setCurrentTab] = useState(frontId)
  const clickHandler = () => setModalProgress(previousModalState)
  const tabClickHandler = (e) => setCurrentTab(e.target.id)
  return (
    <section className="flex flex-direction--column align-items--center w-full">
      {imagePair ? (
        <>
          <section className="App-img-preview--tab-bar mx-8 mb-8 w-full">
            <span
              id={frontId}
              onClick={tabClickHandler}
              className={`App-img-preview--tab ${
                currentTab === frontId ? "active" : ""
              }`}
            >
              Front
            </span>
            <span
              id={backId}
              onClick={tabClickHandler}
              className={`App-img-preview--tab ${
                currentTab === backId ? "active" : ""
              }`}
            >
              Back
            </span>
          </section>
          {currentTab === frontId && (
            <Image
              src={api.getImageBySize({
                size: "medium",
                image_name: frontImage,
              })}
              className="image-preview cursor--zoom-out mt-16"
              onClick={clickHandler}
            />
          )}
          {currentTab === backId && (
            <Image
              src={api.getImageBySize({
                size: "medium",
                image_name: backImage,
              })}
              className="image-preview cursor--zoom-out mt-16"
              onClick={clickHandler}
            />
          )}
        </>
      ) : (
        <>
          <Image
              src={fetchImage ? api.getImageBySize({
                size: "medium",
                image_name: frontImage,
              }) : img}
              className="image-preview cursor--zoom-out mt-16"
              onClick={clickHandler}
            />
        </>
      )}
      <InlineMessage
        kind="info"
        message="Click or tap the image to go back"
        className="mt-16"
      />
    </section>
  )
}

export { ImagePreview }
