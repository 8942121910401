import React from "react"
import { Link } from "react-router-dom"
import { DarkmodeToggle } from "react-darkmode-hook"

const HeaderLinks = ({ links, location, handleCheck }) => {
  return (
    <>
      {links.length > 0 &&
        links.map(({ text, href }, index) => (
          <li key={`li-${index}`}>
            <Link
              className={`${
                href === location.pathname ? "active-link" : "passive-link"
              }`}
              to={href || "#"}
              onClick={handleCheck}
            >
              {text}
            </Link>
          </li>
        ))}
      <li>
        <DarkmodeToggle
          className="faux-link"
          style={{
            border: "solid 1.5px",
            borderRadius: "625rem",
            padding: "3px",
          }}
        />
      </li>
    </>
  )
}

export { HeaderLinks }
