import React from "react"
import { IconContainer } from "./IconContainer"

export const QuestionIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M68.9,11.6c-4.8-4.2-11.1-6.3-18.6-6.3c-7.5,0-13.9,2.2-19,6.5c-3.2,2.7-5.6,6.1-7.1,10.1c-0.8,2.2-0.5,4.6,0.8,6.5
		c1.3,1.9,4,3,7.5,3l0,0c4.7,0,7.3-1.8,8.3-4.4c0.5-1.4,1.3-2.6,2.4-3.6c1.8-1.6,4-2.4,6.6-2.4c2.7,0,4.8,0.8,6.4,2.5
		c1.7,1.7,2.5,3.8,2.5,6.4c0,2.6-0.8,4.7-2.3,6.4l-8.9,8.9c-2.7,2.7-4.5,4.9-5.3,6.7c-0.9,1.8-1.3,4.4-1.3,7.9v1.9
		c0,3.9,4.7,7,8.6,7l0,0c3.9,0,8.2-3.1,8.2-7c0-2.8,0.6-5,1.8-6.5c0.5-0.6,1.3-1.4,2.2-2.3c0.9-0.9,2-2,3.4-3.2
		c1.3-1.2,2.4-2.3,3.3-3.1c0.9-0.9,2-2.2,3.6-4.2c2.9-3.5,4.4-7.9,4.4-13.1C76.3,21.7,73.8,15.8,68.9,11.6z"
      />
      <path d="M49.5,75.4c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6C59.1,79.8,54.8,75.4,49.5,75.4z" />
    </IconContainer>
  )
}
