import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import { Header, AppWrapper } from "../../Components"
import { ProjectWorkspace } from "../../Pages/ProjectWorkspace"
import { ShoppingCartContext } from "../../App"
import { LogoutPage, HomePage } from "../../Pages"
import { HelpPage } from "../../Pages/HelpPage/HelpPage"
import { RedirectNew } from "../StaticRoutes/StaticRoutes"

const ProtectedRoutes = () => {
  const { shoppingCart } = useContext(ShoppingCartContext)

  return (
    <AppWrapper>
      <Router>
        <Header
          links={[
            { text: "dashboard", href: "/design/dashboard" },
            { text: "my projects", href: "/design/my-projects" },
            { text: "logout", href: "/logout?status=success" },
          ]}
          includeCart
          cartItemCount={shoppingCart?.length}
        ></Header>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <RedirectNew />}
          />
          <Route
            exact
            path="/design/help"
            render={(props) => <HelpPage {...props} />}
          />
          <Route
            exact
            path="/design/*"
            render={(props) => <ProjectWorkspace {...props} />}
          />
          <Route
            exact
            path="/logout"
            render={(props) => <LogoutPage {...props} />}
          />
          <Route
            path="/*"
            render={(props) => <Redirect push to="/design/dashboard" />}
          />
        </Switch>
      </Router>
    </AppWrapper>
  )
}

export { ProtectedRoutes }
