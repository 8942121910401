import React from "react"
//import { CloseIcon } from '../Icons'
import * as Icon from "../Icons"
import "./Message.css"
import { Capitalizer } from "../../utils/Capitalizer"

const Message = ({ kind, message, state, className, fixed, children }) => {
  const IconName = Icon[`${Capitalizer(kind || "info")}Icon`]
  const content = (
    <>
      <section className="App-message--content">
        <div>
          <IconName size={"small"} />
        </div>
        <div>
          {message && (
            <p className={`App-message--text ml-24 font-size--11`}>{message}</p>
          )}
          {children && children}
        </div>
      </section>
    </>
  )
  if (!fixed) {
    const [useStatus, setStatus] = state
    const clickHandler = () => {
      setStatus({ visible: false })
    }

    return (
      <div
        // TODO: use 16pt padding on mobile
        className={`App-message App-message--${
          kind ? kind : "default"
        } ${useStatus.visible ? "fade-in" : "hidden"} ${className}`}
      >
        {content}
        <section className="App-message--dismissButton" onClick={clickHandler}>
          <Icon.CloseIcon size={"small"} fill="#555" />
        </section>
      </div>
    )
  } else {
    return (
      <div
        // TODO: use 16pt padding on mobile
        className={`App-message App-message--${
          kind ? kind : "default"
        } fade-in ${className}`}
      >
        {content}
      </div>
    )
  }
}

export { Message }
