import React, { useContext } from "react"
import { Route, Switch, useLocation, useHistory } from "react-router-dom"
import * as App from "../WorkspacePages"
import { Workspace } from "./WorkspaceTemplate"
import { SessionContext } from "../../App"
import { useQuery } from "../../utils/useQuery"
import { HelpButton } from "../../Components"

const ProjectWorkspace = () => {
  const {
    session: { name },
  } = useContext(SessionContext)
  const location = useLocation()
  const query = useQuery()
  const history = useHistory()

  const clickHandler = () => {
    const getParams = (query) => {
      let params;
      for (const [key, value] of query.entries()) {
        params = `${key},${value}`;
     }
     return params || ""
    }
    const params = getParams(query)
    history.push(`/design/help?path="${location.pathname}"${!!params ? `&params="${params}"` : ""}`)
  }

  return (
    <>
      {!(/\/help/gi.test(location.pathname)) && <HelpButton onClick={clickHandler}/>}
      <Workspace>
        <Switch>
          <Route path="/design/dashboard">
            <App.DashboardPage name={name} />
          </Route>

          <Route exact path="/design/projects/:project_name/:project_owner">
            <App.NewProjectPage />
          </Route>

          <Route path="/design/my-images">
            <App.MyUploadsPage />
          </Route>

          <Route exact path="/design/my-projects">
            <App.MyProjectsPage />
          </Route>

          <Route exact path="/design/my-projects/new-project">
            <App.NewProjectPage />
          </Route>

          <Route exact path="/design/cart">
            <App.ShoppingCartPage />
          </Route>

          <Route exact path="/design/order-confirmation">
            <App.OrderConfirmationPage />
          </Route>

          <Route path="/design/order-error">
            <App.OrderErrorPage />
          </Route>
        </Switch>
      </Workspace>
    </>
  )
}

export { ProjectWorkspace }
