import React from "react"
import { BaseListItem } from "./BaseListItem"

const BasicListItem = ({ label, value, identifier }) => {
  return (
    <BaseListItem>
      <div>
        <p className="pr-16">{label}</p>
      </div>
      <div className="flex justify-content--flex-end">
        {identifier && (
          <div>
            <p className="pr-8">{identifier}</p>
          </div>
        )}
        <div>
          <p>{value}</p>
        </div>
      </div>
    </BaseListItem>
  )
}

export { BasicListItem }
