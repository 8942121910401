import React, { useState, memo } from "react"
import "./Image.css"

const Image = memo(
  ({
    alt,
    className = "",
    loading = "lazy",
    containerClass = "",
    containerEvent,
    children,
    onLoad,
    imageRef,
    containerRef,
    isUpload,
    ...props
  }) => {
    const [isLoading, setIsLoading] = useState(true)
    const loadingHandler = () => {
      setIsLoading(false)
      onLoad && onLoad()
    }
    return (
      <div
        ref={containerRef}
        className={`App-image--container ${containerClass} ${
          isLoading ? "loading" : ""
        }`}
        {...containerEvent}
      >
        {children}
        <img
          {...props}
          ref={imageRef}
          className={`${className} ${isLoading ? "transparent" : ""}`}
          alt={alt || ""}
          loading={loading}
          onLoad={loadingHandler}
        ></img>
      </div>
    )
  }
)

export { Image }
