import React from 'react'
import { IconContainer } from './IconContainer'

const LeftArrowIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer { ...other } className={ className } size={ size } >
      <path fill={ fill } d="M50,3.5C24.4,3.5,3.5,24.4,3.5,50S24.4,96.5,50,96.5S96.5,75.6,96.5,50S75.6,3.5,50,3.5z M50,90.5 C27.7,90.5,9.5,72.3,9.5,50S27.7,9.5,50,9.5S90.5,27.7,90.5,50S72.3,90.5,50,90.5z"/>
      <path fill={ fill } d="M44,50l18.8-18.8c1.9-1.9,2-4.7,0.3-6.4l-1.5-1.5c-0.8-0.8-1.8-1.2-2.9-1.2c-1.3,0-2.5,0.6-3.5,1.5L33.6,45.4 c-2.6,2.6-2.6,6.7,0,9.3l21.7,21.7c1,1,2.3,1.5,3.5,1.5c1.1,0,2.1-0.4,2.9-1.2l1.5-1.5c1.7-1.7,1.5-4.5-0.3-6.4L44,50z"/>
    </IconContainer>
  )
}

export { LeftArrowIcon }