import React from 'react'
import { IconContainer } from './IconContainer'

const NewProjectIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size} >
      <path fill={fill} id="Layerd_Cards" d="M87.2,50c0-1.9-1-3.6-2.7-4.4l-7.6-3.8l7.6-3.8c1.7-0.8,2.7-2.5,2.7-4.4c0-1.9-1-3.6-2.7-4.4l-32.3-16
	c-1.4-0.7-3-0.7-4.3,0l-32.3,16c-1.7,0.8-2.7,2.5-2.7,4.4c0,1.9,1,3.6,2.7,4.4l7.6,3.8l-7.6,3.8c-1.7,0.8-2.7,2.5-2.7,4.4
	c0,1.9,1,3.6,2.7,4.4l7.6,3.8l-7.6,3.8c-1.7,0.8-2.7,2.5-2.7,4.4c0,1.9,1,3.6,2.7,4.4l32.3,16c0.7,0.3,1.4,0.5,2.2,0.5
	c0.7,0,1.5-0.2,2.2-0.5l32.3-16c1.7-0.8,2.7-2.5,2.7-4.4c0-1.9-1-3.6-2.7-4.4l-7.6-3.8l7.6-3.8C86.2,53.6,87.2,51.9,87.2,50z
	 M16.5,33.7c0-0.2,0.1-0.8,0.7-1.1l32.3-16c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1l32.3,16c0.6,0.3,0.7,0.8,0.7,1.1
	c0,0.2-0.1,0.8-0.7,1.1l-32.3,16c-0.3,0.2-0.7,0.2-1,0l-32.3-16C16.6,34.4,16.5,33.9,16.5,33.7z M82.8,65.3c0.6,0.3,0.7,0.8,0.7,1.1
	c0,0.2-0.1,0.8-0.7,1.1l-32.3,16c-0.3,0.2-0.7,0.2-1,0l-32.3-16c-0.6-0.3-0.7-0.8-0.7-1.1c0-0.2,0.1-0.8,0.7-1.1l10.2-5l20.5,10.1
	c0.7,0.3,1.4,0.5,2.2,0.5c0.7,0,1.5-0.2,2.2-0.5l20.5-10.1L82.8,65.3z M82.8,51.1L50.5,67c-0.3,0.2-0.7,0.2-1,0l-32.3-16
	c-0.6-0.3-0.7-0.8-0.7-1.1c0-0.2,0.1-0.8,0.7-1.1l10.2-5l20.5,10.1c0.7,0.3,1.4,0.5,2.2,0.5c0.7,0,1.5-0.2,2.2-0.5l20.5-10.1l10.2,5
	c0.6,0.3,0.7,0.8,0.7,1.1C83.5,50.2,83.4,50.8,82.8,51.1z"/>
      <g id="Ting_Accent_TL">
        <path fill={fill} d="M8,5.6c-0.6-0.6-1.5-0.6-2,0L5.6,6c-0.6,0.6-0.6,1.5,0,2l8,8c0.6,0.6,1.5,0.6,2,0l0.4-0.4c0.6-0.6,0.6-1.5,0-2L8,5.6z" />
        <path fill={fill} d="M23.1,1.5c0-0.8-0.6-1.4-1.4-1.4h-0.6c-0.8,0-1.4,0.6-1.4,1.4l0,11.3c0,0.8,0.6,1.4,1.4,1.4h0.6c0.8,0,1.4-0.6,1.4-1.4L23.1,1.5z" />
        <path fill={fill} d="M12.8,19.7l-11.3,0c-0.8,0-1.4,0.6-1.4,1.4v0.6c0,0.8,0.6,1.4,1.4,1.4l11.3,0c0.8,0,1.4-0.6,1.4-1.4v-0.6C14.2,20.3,13.6,19.7,12.8,19.7z" />
      </g>
      <g id="Ting_Accent_BR">
        <path fill={fill} d="M86.4,84c-0.6-0.6-1.5-0.6-2,0L84,84.4c-0.6,0.6-0.6,1.5,0,2l8,8c0.6,0.6,1.5,0.6,2,0l0.4-0.4c0.6-0.6,0.6-1.5,0-2L86.4,84z" />
        <path fill={fill} d="M79,85.8h-0.6c-0.8,0-1.4,0.6-1.4,1.4l0,11.3c0,0.8,0.6,1.4,1.4,1.4H79c0.8,0,1.4-0.6,1.4-1.4l0-11.3C80.4,86.4,79.8,85.8,79,85.8z" />
        <path fill={fill} d="M98.6,76.9l-11.3,0c-0.8,0-1.4,0.6-1.4,1.4v0.6c0,0.8,0.6,1.4,1.4,1.4l11.3,0c0.8,0,1.4-0.6,1.4-1.4v-0.6C100,77.6,99.4,76.9,98.6,76.9z" />
      </g>
    </IconContainer>
  )
}

export { NewProjectIcon }