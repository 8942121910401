import React from 'react'
import { IconContainer } from './IconContainer'

const SoulfolioIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size} >
      <path d="M15.5,13.5C15.5,13.5,15.5,13.5,15.5,13.5h42.5L18.2,2.8c-0.3-0.1-0.6-0.1-0.9-0.1c-1.5,0-2.8,1-3.2,2.4l-2.6,9.6
	C12.7,13.9,14,13.5,15.5,13.5z"/>
      <path d="M8.9,24.6L0.1,57.5c-0.5,1.8,0.6,3.6,2.3,4l6.5,1.7V24.6z" />
      <path d="M68,17.2L21.1,44.2c-0.8,0.4-1.3,1.2-1.5,2c-0.2,0.9-0.1,1.7,0.3,2.5l27.1,46.9c0.4,0.8,1.2,1.3,2,1.5
	c0.9,0.2,1.7,0.1,2.5-0.3l46.9-27.1c0.8-0.4,1.3-1.2,1.5-2c0.2-0.9,0.1-1.7-0.3-2.5L72.5,18.4C71.6,16.8,69.5,16.3,68,17.2z"/>
      <path d="M15.6,27.7l41-11H15.5h0c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.5-1,2.3v10C13,28.9,14.2,28.1,15.6,27.7z" />
      <path d="M12.2,40.1v34c0,1.8,1.5,3.3,3.3,3.3l6.7,0L12.2,40.1z" />
      <path d="M19.4,41.4l36.8-21.2L16.5,30.8c-1.8,0.5-2.8,2.3-2.3,4l2.6,9.7C17.3,43.2,18.2,42.1,19.4,41.4z" />
      <path d="M19.3,54.3l8.8,32.9c0.2,0.9,0.8,1.6,1.5,2s1.7,0.6,2.5,0.3l6.5-1.7L19.3,54.3z" />
    </IconContainer>
  )
}

export { SoulfolioIcon }