import { useState } from "react"
import { encrypt, decrypt } from "../utils/utils"

const useShoppingCart = () => {
  const getShoppingCart = () =>
    localStorage.getItem("cart")
      ? JSON.parse(decrypt(localStorage.getItem("cart")))
      : []

  const [shoppingCart, setShoppingCart] = useState(getShoppingCart())

  const saveSession = (sessionCart) => {
    if (sessionCart === null || sessionCart.length === 0) {
      localStorage.removeItem("cart")
    } else {
      localStorage.setItem("cart", encrypt(sessionCart))
    }
    setShoppingCart(sessionCart)
  }

  return {
    setShoppingCart: saveSession,
    shoppingCart,
  }
}

export { useShoppingCart }
