import { MD5 } from "crypto-js"
import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Message, Button, ConfirmationModal, Tabs } from "../"
import { SessionContext, ProjectContext } from "../../App"
import { ModalContext } from "../../Pages/ProjectWorkspace"
import { api } from "../../utils/api"
import { PreCheckoutModal } from "../Modals/PreCheckoutModal/PreCheckoutModal"
import { AddToCartButton, DeleteProjectButton } from "./MgmtComponents"
import { ProjectSettings } from "./MgmtComponents/ProjectSettings"
import { UserTab } from "./MgmtComponents/UserTab"

const ManageProjectTab = ({ functions, parentActiveModalState }) => {
  const [status, setStatus] = useState({
    visible: false,
    message: undefined,
    kind: undefined,
  })
  const [isComplete, setIsComplete] = useState(false)
  const [activeProject, setActiveProject] = useState(null)
  const [projectToDelete, setProjectToDelete] = useState(null)
  const [activeModal, setActiveModal] = useState(null)

  const { modalHidden, setModalHidden } = useContext(ModalContext)
  const { session } = useContext(SessionContext)
  const { gridData, projectOwner, projectName } = useContext(ProjectContext)

  const { email, token } = session
  const [setInviteSent, deleteHandler] = functions

  const confirmDeleteProjectModal = 0
  const preCheckoutModal = 1

  let history = useHistory()

  useEffect(() => {
    !modalHidden && setModalHidden(true)
    const comparisonValue = gridData.project_array.length
    let accumulator = 0
    gridData.project_array?.forEach(
      ({ tiles, images }) => images.length === tiles && (accumulator += 1)
    )
    let allTilesComplete = comparisonValue === accumulator

    if (allTilesComplete) {
      setIsComplete(true)
    } else {
      setStatus({
        visible: true,
        message: "",
        kind: "error",
      })
      setIsComplete(false)
    }
    // eslint-disable-next-line
  }, [gridData.tile_count])

  const preCheckoutHandler = () => {
    setActiveModal(preCheckoutModal)
    setActiveProject({ project_name: projectName, owner: projectOwner, email })
    setModalHidden(false)
  }

  const deleteProjectHandler = (e) => {
    setModalHidden(!modalHidden)
    fetch(api.deleteProject({ email, projectToDelete }), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    history.push("/design/dashboard")
  }

  const confirmDeleteProject = () => {
    if (projectOwner?.toLowerCase() === email?.toLowerCase()) {
      setActiveModal(confirmDeleteProjectModal)
      setModalHidden(false)
      setProjectToDelete(projectName)
    }
  }

  const closeHandler = () => {
    setModalHidden(true)
    setActiveModal(null)
    parentActiveModalState(null)
  }

  const generateTabContentArray = () => {
    return gridData?.project_array
      .sort((x, y) => {
        return x.email?.toLowerCase() === email?.toLowerCase()
          ? -1
          : y.email?.toLowerCase() === email?.toLowerCase()
          ? 1
          : 0
      })
      .map(({ email, tiles, images }, parentIndex) => {
        const name =
          parentIndex === 0
            ? "My Tiles"
            : email?.slice(0, email.indexOf("@")) || `User ${parentIndex + 1}`
        const content = (
          <>
            <UserTab
              key={MD5(name)}
              index={parentIndex}
              tiles={tiles}
              email={email}
              images={images}
              deleteHandler={deleteHandler}
            />
          </>
        )
        return { name, content }
      })
  }

  return (
    <>
      {activeModal === confirmDeleteProjectModal && (
        <ConfirmationModal
          title={"Delete Project Confirmation"}
          prompt={
            <>
              Are you sure you want to delete the project titled{" "}
              <span className="text-decoration--underline">
                {projectToDelete}
              </span>
              ? This is a permanent operation and the project can not be
              recovered after deletion.
            </>
          }
          DeleteButton={() => (
            <Button
              className="m-16 color-dynamic-text bg-error--90"
              onClick={deleteProjectHandler}
            >
              DELETE
            </Button>
          )}
          CancelButton={() => (
            <Button className="m-16" onClick={closeHandler}>
              CANCEL
            </Button>
          )}
          closeHandler={closeHandler}
        />
      )}
      {activeModal === preCheckoutModal && (
        <PreCheckoutModal
          activeProject={activeProject}
          setActiveProject={setActiveProject}
        />
      )}

      <h2 className="pt-24">Project Overview</h2>
      <p className="paragraph paragraph--l">
        Manage project details like the name and contributors, as well as gain
        insight into the images being submitted by each contributor.
      </p>
      <section>
        <Message
          message="As the project owner, only you have access to these features."
          fixed
          kind="info"
        />
      </section>
      <ProjectSettings
        email={email}
        projectName={projectName}
        setInviteSent={setInviteSent}
      />
      <h3 className="mt-48 border-bottom py-24">
        Image Selections by Contributor
      </h3>
      {gridData?.project_array && (
        <Tabs tabStyle="button" tabs={generateTabContentArray()} />
      )}
      <h3 className="border-bottom py-24">Manage</h3>
      <section className="flex justify-content--center flex-direction--row sm:flex-direction--column">
        <AddToCartButton
          onClick={preCheckoutHandler}
          isComplete={isComplete}
          status={status}
        />
        <DeleteProjectButton onClick={confirmDeleteProject} />
      </section>
    </>
  )
}

export { ManageProjectTab }
