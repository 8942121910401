import React, { useState, useEffect, useContext, useMemo, useRef } from "react"
import {
  Image,
  Modal,
  CarouselButton,
  Button,
  Spinner,
  InlineMessage,
} from "../.."
import { hiRes } from "./images"
import { thumbnails } from "./images"
import {
  SessionContext,
  ShoppingCartContext,
  ProjectContext,
  IsMobileContext,
} from "../../../App"
import { ZoomIcon } from "../../Icons"
import { SortableImages, ImagePreview, FolioPreview } from "./Components"
import { api } from "../../../utils/api"
import "./PreCheckoutModal.css"
import { useHistory } from "react-router-dom"
import { Message } from "../../Message/Message"
import { ModalContext } from "../../../Pages/ProjectWorkspace"
import { ImageEditor } from "./Components/ImageEditor"

const PreCheckoutModal = ({ activeProject, setActiveProject }) => {
  const [modalProgress, setModalProgress] = useState(1)
  const [fastenerSelection, setFastenerSelection] = useState(null)
  const [coverSelection, setCoverSelection] = useState(null)
  const [coverImages, setCoverImages] = useState(null)
  const [preview, setPreview] = useState(null)
  const [imageToEdit, setImageToEdit] = useState(null)
  const [previousModalState, setPreviousModalState] = useState(null)
  const [loading, setLoading] = useState(true)
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const {
    session: { token },
  } = useContext(SessionContext)
  const { gridData, setGridData } = useContext(ProjectContext)
  const { isMobile } = useContext(IsMobileContext)
  const { setModalHidden } = useContext(ModalContext)
  const { shoppingCart, setShoppingCart } = useContext(ShoppingCartContext)
  const refresh = useRef(false)

  const history = useHistory()

  const modalSteps = 4

  const modalTitles = [
    "Fasteners",
    "Front and Back Cover",
    "Tile Order",
    "Final Review",
  ]

  const FLOW_STATE = {
    fastenerSelect: 1,
    coverSelect: 2,
    tileSort: 3,
    folioReview: 4,
    editTile: "edit",
    preview: "preview",
  }

  // !isMobile && notPreviewOrEditState
  const notPreviewOrEditState =
    modalProgress !== FLOW_STATE.preview &&
    modalProgress !== FLOW_STATE.editTile
  /* 
    labels will need to coincide with prop names in backend buildParcelObject util function
    "black_leather_with_black": 1.72,
    "white_leather_with_gold": 1.67,
    "blue_leather_with_gold": 1.82,
    "gold_horseshoe": 13.22,
    "gold_wire": 2.38,
    "blue_wire": 2.48,
    "black_wire": 2.41,
    "white_wire": 2.48,
    "silver_wire": 2.5
  
  */
  const fasteners = [
    {
      image: hiRes[0],
      thumb: thumbnails[0],
      label: "Black Leather",
    },
    {
      image: hiRes[3],
      thumb: thumbnails[3],
      label: "Black Wire",
    },
    {
      image: hiRes[1],
      thumb: thumbnails[1],
      label: "Blue Leather",
    },
    {
      image: hiRes[4],
      thumb: thumbnails[4],
      label: "Blue Wire",
    },
    {
      image: hiRes[2],
      thumb: thumbnails[2],
      label: "White Leather",
    },
    {
      image: hiRes[5],
      thumb: thumbnails[5],
      label: "White Wire",
    },
    {
      image: hiRes[7],
      thumb: thumbnails[7],
      label: "Gold Metal",
    },
    {
      image: hiRes[6],
      thumb: thumbnails[6],
      label: "Gold Wire",
    },
  ]

  const stepForward = () => {
    if (
      (modalProgress === 1 && (fastenerSelection || gridData?.fastener)) ||
      modalProgress > 1
    ) {
      useStatus.visible &&
        setStatus({
          visible: false,
          kind: undefined,
          message: undefined,
        })
      modalProgress < modalSteps && setModalProgress(modalProgress + 1)
    } else {
      setStatus({
        visible: true,
        kind: "warning",
        message: "Please make a selection before proceeding.",
      })
    }
  }

  const stepBack = () =>
    modalProgress > 1 && setModalProgress(modalProgress - 1)

  const submitProjectData = ({ saveAndContinue = true }) => {
    fetch(api.updateProject, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        data: {
          ...gridData,
          fastener: fastenerSelection || gridData.fastener,
          cover_image: coverSelection || gridData.cover_image,
        },
      }),
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 200) {
          if (saveAndContinue) {
            const itemExistsInCart =
              shoppingCart.length > 0 &&
              !!shoppingCart.find(
                (obj) => obj.project_name === gridData.project_name
              )
            setShoppingCart(
              itemExistsInCart ? shoppingCart : [...shoppingCart, gridData]
            )
            setModalHidden(true)
            history.push("/design/cart")
          }
        }
      })
  }

  const zoomHandler = ({ img, imagePair, fetchImage }) => {
    setPreview({ img, imagePair, fetchImage })
    setPreviousModalState(modalProgress)
    setModalProgress(FLOW_STATE.preview)
  }

  const editHandler = () => {
    setPreviousModalState(modalProgress)
  }

  const fastenerClickHandler = (e) => setFastenerSelection(e.target.id)

  const coverClickHandler = (e) => setCoverSelection(e.target.id)

  const getTitle = () => {
    return typeof modalProgress === "number"
      ? `Finishing Touches: ${modalTitles[modalProgress - 1]}`
      : `Preview`
  }

  const closePreview = () => {
    const shouldUpdate = modalProgress === FLOW_STATE.editTile
    setModalProgress(previousModalState)
    if (shouldUpdate) {
      refresh.current = !refresh.current
    }
  }

  const closeSorter = () => {
    setActiveProject(null)
    setModalProgress(1)
    setModalHidden(true)
  }

  useEffect(() => {
    activeProject && setLoading(true)
    activeProject &&
      fetch(api.getCoverImages, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setCoverImages(data)
        })
        .then(() => {
          fetch(api.getProject, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              ...activeProject,
            }),
          })
            .then((res) => res.json())
            .then(({ status, data }) => {
              setLoading(false)
              if (status === 200 && data) {
                setGridData({ ...data })
                //setModalProgress(3)
              } else if (status === 401) {
                history.push("/logout?status=session-expired")
              } else {
                setStatus({
                  visible: true,
                  kind: "warning",
                  message:
                    "Hmm.  I couldn't connect to the server.  Please try again in a moment.",
                })
              }
            })
            .catch((err) => {
              setLoading(false)
              setStatus({
                visible: true,
                kind: "error",
                message: "Uh oh, something went wrong.  Try again in a moment.",
              })
              console.error(err)
            })
        })
    // eslint-disable-next-line
  }, [activeProject, refresh.current])

  const fastenersContent = useMemo(
    () =>
      fasteners?.map(({ thumb, image, label }, index) => (
        <label
          key={`fastener-label-${index}`}
          htmlFor={`fastener-${index}`}
          className="flex flex-direction--column align-items--center"
        >
          <input
            key={`fastener-input-${index}`}
            id={`fastener-${index}`}
            name="fastener-choice"
            type="radio"
            className="App-checkout-modal--radio"
            defaultChecked={
              label === fastenerSelection || label === gridData.fastener
            }
          />
          <Image
            key={`fastener-img-${index}`}
            id={label}
            src={thumb}
            className="App-checkout-modal--image-preview fastener"
            onClick={fastenerClickHandler}
          />
          {label}
          <Button
            key={`fastener-span-${index}`}
            id={label}
            className="flex flex-direction--row align-items--center justify-content--center mt-8"
            onClick={() => {
              zoomHandler({
                img: image,
                imagePair: false,
                fetchImage: false,
              })
            }}
            compact
          >
            Zoom In
            <ZoomIcon
              key={`cover-icon-${index}`}
              className="ml-8"
              size="medium"
            />
          </Button>
        </label>
      )),
      // eslint-disable-next-line
    [fasteners, fastenerSelection, gridData]
  )

  const coverImagesContent = useMemo(
    () =>
      coverImages?.map(({ image_name }, index) => (
        <label
          key={`cover-label-${index}`}
          htmlFor={`cover-${index}`}
          className="flex flex-direction--column align-items--center"
        >
          <input
            key={`cover-input-${index}`}
            id={`cover-${index}`}
            name="cover-choice"
            type="radio"
            className="App-checkout-modal--radio"
            defaultChecked={
              image_name === coverSelection ||
              image_name === gridData.cover_image
            }
          />
          <Image
            key={`cover-img-${index}`}
            id={image_name}
            src={api.getImageBySize({ size: "small", image_name })}
            className="App-checkout-modal--image-preview cover"
            onClick={coverClickHandler}
          />
          <Button
            key={`cover-span-${index}`}
            id={image_name}
            className="flex flex-direction--row align-items--center justify-content--center"
            onClick={() => {
              zoomHandler({
                img: image_name,
                imagePair: true,
                fetchImage: true,
              })
            }}
            compact
          >
            Zoom In
            <ZoomIcon
              key={`cover-icon-${index}`}
              className="ml-8"
              size="medium"
            />
          </Button>
        </label>
      )),
      // eslint-disable-next-line
    [coverSelection, coverImages, gridData]
  )

  return (
    <Modal
      title={getTitle()}
      contentClassName={`App-checkout-modal flex md:flex-direction--row sm:flex-direction--column justify-content--space-between ${
        notPreviewOrEditState && !isMobile ? "pb-32" : ""
      }`}
      closeHandler={!notPreviewOrEditState ? closePreview : closeSorter}
    >
      {loading ? (
        <section className="align-items--center justify-content--center w-full mt-48">
          <Spinner
            text={
              modalProgress === FLOW_STATE.fastenerSelect
                ? "Retrieving Project Data..."
                : undefined
            }
          />
          <InlineMessage
            kind="info"
            message="Depending on the number of tiles in your project, this step may take a moment.  Please wait."
            className="mt-24 w-60"
          />
        </section>
      ) : (
        gridData && (
          <>
            {!isMobile && notPreviewOrEditState && (
              <CarouselButton
                size="xtra-large"
                position="left"
                onClick={stepBack}
                className={`${
                  modalProgress === 1
                    ? `button-disabled button-disabled--svg`
                    : `button-active button-active--svg`
                } p-24`}
              />
            )}
            {modalProgress === FLOW_STATE.fastenerSelect && (
              <section className="flex flex-direction--column overflow-x--auto">
                <h3>Pick a fastener</h3>
                <section className="mx-24 align-self--center">
                  <Message
                    kind={useStatus.kind}
                    visible={useStatus.visible}
                    message={useStatus.message}
                    state={[useStatus, setStatus]}
                    className="align-self--center"
                  />
                </section>
                <section className="flex flex-direction--row justify-content--space-evenly flex-wrap">
                  {fastenersContent}
                </section>
              </section>
            )}
            {modalProgress === FLOW_STATE.coverSelect && (
              <section className="flex flex-direction--column App-sortable-image--container">
                <h3>Select a cover design (optional)</h3>
                <section className="flex flex-direction--row justify-content--space-evenly flex-wrap">
                  {coverImagesContent}
                </section>
              </section>
            )}
            {modalProgress === FLOW_STATE.tileSort && (
              <SortableImages saveChanges={submitProjectData} />
            )}
            {modalProgress === FLOW_STATE.folioReview && (
              <FolioPreview
                setModalProgress={setModalProgress}
                setImageToEdit={setImageToEdit}
                editState={FLOW_STATE.editTile}
                editHandler={editHandler}
              />
            )}
            {modalProgress === FLOW_STATE.preview && !!preview?.img && (
              <ImagePreview
                {...preview}
                previousModalState={previousModalState}
                setModalProgress={setModalProgress}
                isMobile={isMobile}
              />
            )}
            {modalProgress === FLOW_STATE.editTile && imageToEdit && (
              <ImageEditor
                tileData={imageToEdit}
                imageName={imageToEdit.image_name}
                backImageName={imageToEdit.back_image_data.file_name}
                canEdit={imageToEdit.canEdit}
                isUpload={imageToEdit.isUpload}
                onClose={closePreview}
              />
            )}
            {!isMobile && notPreviewOrEditState && (
              <CarouselButton
                size="xtra-large"
                position="right"
                className={`button-active button-active--svg p-24`}
                onClick={
                  modalProgress < modalSteps ? stepForward : submitProjectData
                }
              />
            )}
            {isMobile && notPreviewOrEditState && (
              <div className="flex flex-direction--row pt-24 justify-content--space-between align-content--center">
                <div>
                  <CarouselButton
                    size="xtra-large"
                    position="left"
                    onClick={stepBack}
                    className={`${
                      modalProgress === 1
                        ? `button-disabled button-disabled--svg`
                        : `button-active button-active--svg`
                    } p-24`}
                  />
                </div>
                <div>
                  <CarouselButton
                    size="xtra-large"
                    position="right"
                    onClick={
                      modalProgress < modalSteps
                        ? stepForward
                        : submitProjectData
                    }
                    className={`button-active button-active--svg p-24`}
                  />
                </div>
              </div>
            )}
          </>
        )
      )}
    </Modal>
  )
}

export { PreCheckoutModal }
