import React, { useEffect, useContext } from "react"
import { Redirect } from "react-router-dom"
import { SessionContext } from "../../App"
import { useTitle } from "../../Hooks/UseTitle"
import { api } from "../../utils/api"
import { useQuery } from "../../utils/useQuery"

const LogoutPage = () => {
  const { session, setSession } = useContext(SessionContext)
  let query = useQuery()
  let statusQuery = query?.get("status") || undefined
  useTitle("Logout of Soulfolio")
  useEffect(() => {
    setSession(null)
    fetch(api.logout, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Redirect
      push
      from="/logout"
      to={`/login${statusQuery ? `?status=${statusQuery}` : ""}`}
    />
  )
}

export { LogoutPage }
