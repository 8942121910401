//import * as fs from 'fs'

export const staticSitePages = [
  { text: 'home', href: '/' },
  // { text: 'our story', href: '/our-story' },
  // { text: 'faq', href: '/faq' },
  { text: 'sign up', href: '/signup' },
  { text: 'login', href: '/login' },
]

export const staticAppPages = [
  { text: 'User Settings', href: '/design/user-settings' },
  { text: 'Project Workspace', href: '/design/project-workspace' },
  { text: 'Dashboard', href: '/design/dashboard' },
  { text: 'Projects', href: '/design/projects' }
]

export const dynamicAppPages = [
  { text: 'Image Browser', type: 'modal' },
  { text: 'Image Editor', href: '/design/image-editor', type: 'page' },
  { text: 'Product Treatments', type: 'modal' },
  { text: 'Shopping Cart', type: 'modal' }
]