import React, { useEffect, useState, useContext } from "react"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { Button, Divider, Message, Spinner } from "../../../Components"
import {
  BillingInfo,
  // GiftWrapCTA,
  ShippingInfo,
  ShippingRates,
} from "./Components"
import { ShoppingCartContext, SessionContext } from "../../../App"
import { api } from "../../../utils/api"
import "./CheckoutForm.css"

const CheckoutForm = ({
  isProjectOwner = true,
  shippingRateState,
  taxableState,
  setGiftWrap,
  promo,
  paymentIntentId,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { shoppingCart } = useContext(ShoppingCartContext)
  const {
    session: { email },
  } = useContext(SessionContext)
  const [shippingRate, setShippingRate] = shippingRateState
  const [isTaxable, setIsTaxable] = taxableState
  const [paymentValidation, setPaymentValidation] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [shippingValidation, setShippingValidation] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [billingValidation, setBillingValidation] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [postageValidation, setPostageValidation] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loadingRates, setLoadingRates] = useState(false)
  const [commonShipping, setCommonShipping] = useState(false)
  const [componentShippingRates, setComponentShippingRates] = useState({
    id: undefined,
    rates: [],
  })

  const [shippingInfo, setShippingInfo] = useState({
    ship_firstName: "",
    ship_lastName: "",
    ship_street: "",
    ship_city: "",
    ship_state: "",
    ship_zip: "",
  })
  const [billingInfo, setBillingInfo] = useState({
    bill_firstName: "",
    bill_lastName: "",
    bill_street: "",
    bill_city: "",
    bill_state: "",
    bill_zip: "",
    bill_email: "",
  })

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setPaymentValidation({
            visible: true,
            kind: "success",
            message: "Payment succeeded!",
          })
          break
        case "processing":
          setPaymentValidation({
            visible: true,
            kind: "success",
            message: "Your payment is processing.",
          })
          break
        case "requires_payment_method":
          setPaymentValidation({
            visible: true,
            kind: "error",
            message: "Your payment was not successful, please try again.",
          })
          break
        default:
          setPaymentValidation({
            visible: true,
            kind: "error",
            message: "Something went wrong.",
          })
          break
      }
    })
  }, [stripe])

  useEffect(() => {
    if (
      (commonShipping &&
        billingInfo.bill_firstName &&
        billingInfo.bill_lastName &&
        billingInfo.bill_street &&
        billingInfo.bill_city &&
        billingInfo.bill_state &&
        /^\d{5}(|-\d{4})$/g.test(billingInfo.bill_zip)) ||
      (!commonShipping &&
        shippingInfo.ship_street &&
        shippingInfo.ship_firstName &&
        shippingInfo.ship_lastName &&
        shippingInfo.ship_city &&
        shippingInfo.ship_state &&
        /^\d{5}(|-\d{4})$/g.test(shippingInfo.ship_zip))
    ) {
      const req = commonShipping
        ? {
            name: `${billingInfo.bill_firstName} ${billingInfo.bill_lastName}`,
            city: billingInfo.bill_city,
            street1: billingInfo.bill_street,
            state: billingInfo.bill_state,
            zip: billingInfo.bill_zip,
          }
        : {
            name: `${shippingInfo.ship_firstName} ${shippingInfo.ship_lastName}`,
            city: shippingInfo.ship_city,
            street1: shippingInfo.ship_street,
            state: shippingInfo.ship_state,
            zip: shippingInfo.ship_zip,
          }

      //console.log(req)
      setIsTaxable(/oh/gi.test(billingInfo.bill_state))
      setLoadingRates(true)
      fetch(api.getShippingRates, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderData: {
            tile_count: shoppingCart.reduce(
              (acc, curr) => acc + Number(curr.tile_count),
              0
            ),
            hasGiftWrap: false,
          },
          ...req,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const { easypost } = data
          const filteredRates = easypost.rates
            .filter(({ service }) => !/express/gi.test(service))
            .filter(({ carrier }) => /usps/gi.test(carrier))
            .sort(
              (a, b) =>
                Number(a.est_delivery_days) - Number(b.est_delivery_days)
            )

          //console.log(filteredRates[1])
          setComponentShippingRates({
            id: easypost.id,
            rates: promo?.free_shipping ? [filteredRates[1]] : filteredRates,
          })
          setLoadingRates(false)
        })
        .catch((err) => console.error(err))
    } else {
      setComponentShippingRates({ id: undefined, rates: [] })
      setLoadingRates(false)
    }
    // eslint-disable-next-line
  }, [shippingInfo, billingInfo, commonShipping, promo])

  const setShippingData = (e) => {
    if (shippingInfo[e.target.id] === e.target.value) {
      return
    }
    let info = {
      ...shippingInfo,
      [e.target.id]: e.target.value,
    }
    setShippingInfo({ ...info })
  }
  const setBillingData = (e) => {
    if (billingInfo[e.target.id] === e.target.value) {
      return
    }
    let info = {
      ...billingInfo,
      [e.target.id]: e.target.value,
    }
    setBillingInfo({ ...info })
  }
  const radioButtonClickHandler =
    (e) =>
    ({ rate_id }) => {
      setShippingRate({
        value: e.target.value,
        rate_id,
        ship_id: componentShippingRates.id,
      })
    }

  const commonShippingHandler = () => {
    //console.log(shippingInfo)
    const newCommonShippingValue = !commonShipping
    setCommonShipping(newCommonShippingValue)
    newCommonShippingValue === true &&
      setShippingInfo({
        ship_firstName: "",
        ship_lastName: "",
        ship_street: "",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
      })
  }

  // const giftWrapHandler = (e) => {
  //   const valueIsTrue = e.target.value === "true"
  //   setGiftWrap(valueIsTrue)
  // }

  const validateAndSanitizeShipAndBillData = () => {
    const {
      bill_firstName,
      bill_lastName,
      bill_city,
      bill_state,
      bill_street,
      bill_zip,
    } = billingInfo

    const {
      ship_firstName,
      ship_lastName,
      ship_city,
      ship_state,
      ship_zip,
      ship_street,
    } = shippingInfo

    let shippingDetails = {}
    const billingDetails = {
      name: `${bill_firstName} ${bill_lastName}`,
      address: {
        city: bill_city,
        line1: bill_street,
        state: bill_state,
        postal_code: bill_zip,
      },
    }

    if (isProjectOwner) {
      if (commonShipping) {
        shippingDetails = { ...billingDetails }
      } else {
        shippingDetails = {
          name: `${ship_firstName} ${ship_lastName}`,
          address: {
            city: ship_city,
            line1: ship_street,
            state: ship_state,
            postal_code: ship_zip,
          },
        }
      }
    }

    if (!shippingRate.ship_id || !shippingRate.rate_id) {
      setIsLoading(false)
      setPostageValidation({
        visible: true,
        kind: "error",
        message: `Please select a shipping option`,
      })
    }

    if (!shippingDetails.address.line1 && !commonShipping) {
      setShippingValidation({
        visible: true,
        kind: "error",
        message: "Please provide a shipping address",
      })
    }
    if (!billingDetails.address.line1) {
      setBillingValidation({
        visible: true,
        kind: "error",
        message: "Please provide the billing address",
      })
    }
    return { billingDetails, shippingDetails }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (promo?.free_shipping) {
      if (document.querySelectorAll(".App-inline-message--error").length > 0) {
        setIsLoading(false)
        setPaymentValidation({
          visible: true,
          kind: "error",
          message:
            "There are errors in the form fields above.  Please fix them and try again.",
        })
        return
      }

      const { billingDetails, shippingDetails } =
        validateAndSanitizeShipAndBillData()

      if (
        !shippingDetails.address.line1 ||
        !billingDetails.address.line1 ||
        !shippingRate.ship_id ||
        !shippingRate.rate_id
      ) {
        setPaymentValidation({
          visible: true,
          kind: "error",
          message:
            "Please fill out all required fields above before proceeding.",
        })
        setIsLoading(false)
        return
      }
      setPaymentValidation({
        visible: true,
        kind: "warning",
        message:
          "Your order is now processing!  Please do not close this window or use the back button; you'll be redirected after the payment succeeds.",
      })

      const returnUrl = encodeURI(
        api.buyShipment({
          ship_id: shippingRate.ship_id,
          rate_id: shippingRate.rate_id,
          owner: shoppingCart[0].owner,
          project_name: shoppingCart[0].project_name,
        })
      )

      window.location.replace(returnUrl)

      setIsLoading(false)
    } else {
      if (document.querySelectorAll(".App-inline-message--error").length > 0) {
        setIsLoading(false)
        setPaymentValidation({
          visible: true,
          kind: "error",
          message:
            "There are errors in the form fields above.  Please fix them and try again.",
        })
        return
      }

      await fetch(api.updatePaymentIntent, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          isTaxable,
          shippingRate,
          promo,
          id: paymentIntentId,
          items: shoppingCart.reduce(
            (acc, curr) => acc + Number(curr.tile_count),
            0
          ),
        }),
      })

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        setIsLoading(false)
        return
      }

      // confirm pay redirects, so we either need fetch the buy label route,
      // or call the function to do so when updating the payment intent above.
      const returnUrl = encodeURI(
        api.buyShipment({
          ship_id: shippingRate.ship_id,
          rate_id: shippingRate.rate_id,
          owner: shoppingCart[0].owner,
          project_name: shoppingCart[0].project_name,
        })
      )

      const { billingDetails, shippingDetails } =
        validateAndSanitizeShipAndBillData()

      if (
        !shippingDetails.address.line1 ||
        !billingDetails.address.line1 ||
        !shippingRate.ship_id ||
        !shippingRate.rate_id
      ) {
        setPaymentValidation({
          visible: true,
          kind: "error",
          message:
            "Please fill out all required fields above before proceeding.",
        })
        setIsLoading(false)
        return
      }
      setPaymentValidation({
        visible: true,
        kind: "warning",
        message:
          "Your order is now processing!  Please do not close this window or use the back button; you'll be redirected after the payment succeeds.",
      })

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // we'll redirect to the buy label api route with appropriate url params so
          // the shipping label can be purchased and captured in the database.
          return_url: returnUrl,
          payment_method_data: {
            billing_details: {
              ...billingDetails,
              email: billingInfo.bill_email,
            },
          },
          shipping: { ...shippingDetails },
        },
      })

      setIsLoading(false)
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentValidation({
          visible: true,
          kind: "error",
          message: error.message,
        })
      } else {
        setPaymentValidation({
          visible: true,
          kind: "error",
          message: `An unexpected error occurred.`,
        })
      }
    }
  }

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="md:ml-24 sm:w-full w-60"
      autoComplete="nope"
    >
      <BillingInfo
        billingValidation={billingValidation}
        setBillingValidation={setBillingValidation}
        setBillingData={setBillingData}
        email={email}
        commonShipping={commonShipping}
      />
      <Divider />
      <ShippingInfo
        shippingValidation={shippingValidation}
        setShippingValidation={setShippingValidation}
        setShippingData={setShippingData}
        isProjectOwner={isProjectOwner}
        commonShipping={commonShipping}
        shippingInfo={shippingInfo}
        commonShippingHandler={commonShippingHandler}
      />

      <section className="mb-48">
        <Divider />
        <h2 className="heading heading-s transition color-brand--primary">
          Shipping Rates
        </h2>
        {loadingRates ? (
          <Spinner />
        ) : componentShippingRates.rates.length > 0 ? (
          <>
            <ShippingRates
              componentShippingRates={componentShippingRates}
              clickHandler={radioButtonClickHandler}
              promo={promo}
            />
            {/* <GiftWrapCTA giftWrapHandler={giftWrapHandler} /> */}
          </>
        ) : (
          <Message
            kind="info"
            message="Please add a shipping address to see rates..."
            visible={componentShippingRates.rates.length > 0}
            fixed
          />
        )}
      </section>
      {postageValidation.visible && (
        <section className="w-full">
          <Message
            id="postage-message"
            kind={postageValidation.kind}
            message={postageValidation.message}
            visible={postageValidation.visible}
            state={[postageValidation, setPostageValidation]}
            className="w-full"
          />
        </section>
      )}
      <Divider />
      {promo?.free_shipping ? (
        <section className="flex flex-direction--row justify-content--flex-end w-full mt-24">
          <Button
            disabled={isLoading}
            id="submit"
            className="sm:w-full box-sizing--border-box"
          >
            <span id="button-text">
              {isLoading ? (
                <Spinner text={null} size="micro" className="p-0 m-0" />
              ) : (
                "Submit Order"
              )}
            </span>
          </Button>
        </section>
      ) : (
        <>
          <h2 className="heading heading-s color-brand--primary">
            Payment Information
          </h2>
          <PaymentElement id="payment-element" />
          <section className="flex flex-direction--row justify-content--flex-end w-full mt-24">
            <Button
              disabled={isLoading}
              id="submit"
              className="sm:w-full box-sizing--border-box"
            >
              <span id="button-text">
                {isLoading ? (
                  <Spinner text={null} size="micro" className="p-0 m-0" />
                ) : (
                  "Pay Now"
                )}
              </span>
            </Button>
          </section>
        </>
      )}
      {/* Show any error or success messages */}
      {paymentValidation.visible && (
        <section className="w-full">
          <Message
            id="payment-message"
            kind={paymentValidation.kind}
            message={paymentValidation.message}
            visible={paymentValidation.visible}
            state={[paymentValidation, setPaymentValidation]}
            className="w-full"
          />
        </section>
      )}
    </form>
  )
}

export { CheckoutForm }
