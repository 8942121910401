import React from "react"
import { Button } from "../../../"

const ResetPositionButton = ({ clickHandler, isBusy }) => {
  return (
    <Button
      onClick={clickHandler}
      className="mt-8 mr-8 box-sizing--border-box w-unset w-50"
      disabled={isBusy}
      compact
    >
      Center Text
    </Button>
  )
}

export { ResetPositionButton }
