import React from "react"
import { Button } from "../../"

const DeleteProjectButton = ({ onClick }) => {
  return (
    <section className="flex-direction--column md:w-50 sm:w-full">
      <section className="flex justify-content--center">
        <Button
          className="bg-error--70 mt-24 box-sizing--border-box md:w-50 sm:w-100"
          onClick={onClick}
        >
          Delete Project
        </Button>
      </section>
    </section>
  )
}

export { DeleteProjectButton }
