import React from "react"
import { IconContainer } from "./IconContainer"

const RotateIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M93.2,56.1l5.5-26.8c0.4-2.2-1.6-4-3.7-3.3l-4.9,1.6c-0.3,0.1-0.6,0-0.8-0.3c-9-16.3-26.7-25.5-45.9-23.7
	c-14.6,1.4-27.7,9.8-35,22.7C0.8,39.7-0.1,55.2,6,68.9c6,13.4,18.1,23.4,32.4,26.6c3.4,0.8,7,1.2,10.6,1.2
	c11.3,0,21.9-3.9,29.1-10.6c0.8-0.7,1.4-1.6,1.8-2.5c1.2-3,0.3-6.2-2-8.1L76,73.8c-2.6-2.1-6.3-2.2-8.9-0.1c-10,7.8-23.1,8.6-34.3,2
	c-8.3-4.8-13.6-13.5-14.3-23.1c-0.6-9.9,3.2-19.2,10.5-25.4c7.3-6.2,16.8-8.6,26.2-6.5c7.2,1.6,13.5,5.8,17.8,11.8
	c0.3,0.4,0.1,0.9-0.3,1l-5.1,2c-2.1,0.7-2.7,3.4-1,4.9l22,17.4C90.1,59.2,92.7,58.3,93.2,56.1z"
      />
    </IconContainer>
  )
}

export { RotateIcon }
