import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import { Header, PageWrapper, Footer } from "../../Components"
import {
  LoginPage,
  SignupPage,
  PageNotFound,
  HomePage,
  ResetPasswordPage,
  SetNewPasswordPage,
} from "../../Pages"
import * as pageDefs from "../../utils/pageDefinitions"

export const RedirectNew = () => {
  React.useEffect(() => {
    window.location.replace("https://soulfolio.co")
}, [])
return <></>
}

const StaticRoutes = () => {
  return (
    <Router>
      <Header links={pageDefs.staticSitePages} />
      <PageWrapper>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <RedirectNew />}
          />
          {/* <Route path="/our-story" exact render={() => <h1>Welcome to Our Story!</h1>} />
          <Route path="/faq" exact render={() => <h1>Welcome to FAQ!</h1>} /> */}
          <Route
            path="/login"
            exact
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            path="/signup"
            exact
            render={(props) => <SignupPage {...props} />}
          />
          <Route
            path="/reset-password"
            exact
            render={(props) => <ResetPasswordPage {...props} />}
          />
          <Route
            path="/set-password"
            exact
            render={(props) => <SetNewPasswordPage {...props} />}
          />
          <Route
            path="/design"
            render={(props) => <Redirect push to="/login" />}
          />
          <Route
            path="/design/*"
            render={(props) => <Redirect push to="/login" />}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </PageWrapper>
      <Footer className="relative" />
    </Router>
  )
}

export { StaticRoutes }
