import React from 'react'
import './Select.css'

const Select = ({ label, title, inputClasses, wrapperClasses, labelClasses, children, compact, ...props }) => {
  return (
    <div className={wrapperClasses || undefined}>
      <>
        {label && <label className={labelClasses || undefined} htmlFor={title}>{label}</label>}
        <select {...props} title={title} className={`App-select-field ${compact ? "compact" : ""} ${inputClasses || ''}`} aria-label={title}>
          {children}
        </select>
      </>
    </div>
  )
}

export { Select }