import React from "react"
import { Modal } from "../../"

const ConfirmationModal = ({DeleteButton, CancelButton, title, prompt, closeHandler}) => {

  return (
    <Modal
      title={title}
      contentClassName="flex flex-direction--column justify-content--space-between"
      closeHandler={closeHandler}
    >
      <p className="align-self--center pb-24 paragraph paragraph-xl w-80">
        {prompt}
      </p>
      <div className="flex md:flex-direction--row sm:flex-direction--column justify-content--space-around">
        {DeleteButton && <DeleteButton />}
        {CancelButton && <CancelButton />}
      </div>
    </Modal>
  )
}

export { ConfirmationModal }
