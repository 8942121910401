import React from "react"
import "./Spinner.css"

/**
 * 
 * @param {string} size - one of micro, tiny, small, medium, or large
 */

const Spinner = ({
  text = "Loading...",
  size = "medium",
  inline = false,
  color = "brand--primary",
}) => (
  <div
    className={`flex flex-direction--${
      inline ? "row justify-content--center" : "column"
    } align-items--center`}
  >
    <div
      className={`App-spinner App-spinner--${size} color-${color}${
        inline ? " mr-8" : ""
      }`}
    ></div>
    {text && <p className={`m-0 ${inline ? "" : "pt-8 "}regular color-${color}`}>
      {text}
    </p>}
  </div>
)

export { Spinner }
