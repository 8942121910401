import React from "react"
import { IconContainer } from "./IconContainer"

const ReturnIcon = ({ fill, size, className, ...props }) => {
  return (
    <IconContainer {...props} className={className} size={size}>
      <path
        d="M57.2,16.8h-21V3.3c0-2.7-3.2-4.3-5.3-2.5l-28.6,23c-1.6,1.3-1.6,3.8,0,5.1l28.6,23c2.1,1.7,5.3,0.2,5.3-2.5V35.8h21.2
	c12,0,22.3,9.5,22.7,21.7c0.5,12.8-9.7,23.4-22.1,23.4H28.1c-2.1,0-3.8,1.7-3.8,3.9v11.3c0,2.1,1.7,3.9,3.8,3.9H58
	c22.9,0,41.4-19.2,40.9-42.6C98.4,34.7,79.5,16.8,57.2,16.8z"
      />
    </IconContainer>
  )
}

export { ReturnIcon }
