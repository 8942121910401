import React from 'react';
import { IconButton } from '../../';
import { RotateIcon } from '../../Icons';

export const RotationController = ({clickHandler}) => {
  return (
    <section className="flex flex-direction--column justify-content--center align-items--center">
        <IconButton id="rotate" onClick={clickHandler}>
          <RotateIcon size="medium" id="rotate" />
        </IconButton>
        <label htmlFor="rotate" className="mt-8 color-gray--60">
          Rotate
        </label>
      </section>
  )
}