import React from 'react';
import './AppWrapper.css'

const AppWrapper = ({ children }) => {
  return (
    <div className='App-wrapper'>
      { children }
    </div>
  )
}

export { AppWrapper }