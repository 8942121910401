import React, { useContext } from "react"
import { ProjectContext } from "../../../App"
import {
  ManageProjectNameField,
  ManageContributorField,
  ManageTileCountField,
} from "./"

const ProjectSettings = ({ email, setInviteSent }) => {
  const { gridData } = useContext(ProjectContext)
  return (
    <>
      <h3 className="border-bottom py-24">Settings</h3>
      <section className="flex flex-direction--row justify-content--space-between flex-wrap">
        <ManageProjectNameField gridData={gridData} />
        <ManageTileCountField />
      </section>
      <section className="flex flex-direction--row justify-content--space-between flex-wrap mt-48">
        {gridData?.project_array
          .filter((a, b) => a.email !== email)
          .map((item, index) => (
            <ManageContributorField
              key={`contributor-${index}`}
              index={index}
              item={item}
              setInviteSent={setInviteSent}
            />
          ))}
      </section>
    </>
  )
}

export { ProjectSettings }
