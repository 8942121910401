import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { SessionContext } from "../../App"
import { useQuery } from "../../utils/useQuery"
import {
  TextInput,
  Button,
  Container,
  Message,
  Spinner,
} from "../../Components"
import { messages } from "./StatusMessages"
import "./LoginPage.css"
import { encrypt } from "../../utils/utils"
import { useTitle } from "../../Hooks/UseTitle"
import { api } from "../../utils/api"

const LoginPage = ({ ...props }) => {
  let query = useQuery()
  let statusParam = query.get("status")
  const { setSession } = useContext(SessionContext)
  const [useStatus, setStatus] = useState({
    visible: messages[statusParam]?.visible || false,
    kind: messages[statusParam]?.kind || undefined,
    message: messages[statusParam]?.message || undefined,
  })
  const [loading, setLoading] = useState(false)
  useTitle("Login to Soulfolio")

  const clickHandler = () => {
    setLoading(true)
    const inputs = document.querySelectorAll("input:not(input[type='checkbox'])")
    const newUser = {}
    inputs.forEach((input) => {
      let key = input.id
      let prop = input.value
      if (prop !== "") {
        if (key !== "password-confirm") {
          newUser[key] = encrypt(prop)
        }
      } else {
        setStatus({
          visible: true,
          kind: "error",
          message: `Yikes! The email address and / or password was incorrect.  Give it another try!`,
        })
        setLoading(false)
        return
      }
    })

    fetch(api.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUser),
    })
      .then((res) => res.json())
      .then((data) => {
        const { msg, status, token, first_name, email } = data
        if (status === 200) {
          setSession({
            token,
            name: first_name,
            email,
          })
        }
        setLoading(false)
        setStatus({
          visible: true,
          kind: status === 200 ? "success" : "error",
          message:
            msg ||
            "Yikes! The email address and / or password was incorrect. Give it a minute and try again.",
        })
      })
      .catch((err) => {
        setLoading(false)
        setStatus({
          visible: true,
          kind: "warning",
          message: `Uh oh! Something went wrong. Give it another try!`,
        })
      })
  }

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      e.key === "Enter" && clickHandler()
    })
    return () => {
      window.removeEventListener("keyup", (e) => {
        e.key === "Enter" && clickHandler()
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container className="App-login--container box-shadow--medium flex flex-direction--column align-items--center justify-content--center">
      <h1 className="center-text Login-header">Login to Soulfolio</h1>
      <TextInput
        id="email"
        inputClasses="Login-input"
        type="text"
        placeholder="Email Address"
        wrapped={false}
        autoComplete="new-off"
      ></TextInput>
      <TextInput
        id="password"
        inputClasses="Login-input"
        type="password"
        placeholder="Password"
        wrapped={false}
        autoComplete="new-off"
      ></TextInput>
      <Button
        onClick={clickHandler}
        className="Login-button"
        disabled={loading}
      >
        {loading ? <Spinner size="tiny" color="white" text={null} /> : "Login"}
      </Button>
      <div className="center-text pt-24">
        <div className="my-8">
          <Link className="active-link font-size--9" to={"/signup"}>
            Don't have an account?
          </Link>
        </div>
        <div className="my-8">
          <Link className="active-link font-size--9" to={"/reset-password"}>
            Forgot your password?
          </Link>
        </div>
      </div>
      <Message
        kind={useStatus.kind}
        message={useStatus.message}
        state={[useStatus, setStatus]}
      ></Message>
    </Container>
  )
}

export { LoginPage }
