import React, { useState } from "react"
import { EditableField } from "./"

const ManageProjectNameField = ({ gridData }) => {
  const [isEditing, setIsEditing] = useState(false)
  // Need to add backend service to change project name
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState(gridData.project_name)

  const editHandler = () => {
    setIsEditing(true)
  }

  const saveHandler = (e) => {
    setIsEditing(false)
    // fetch(api.modifyTileCount, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     tile_count: +inputValue,
    //     project_name: gridData.project_name

    //   })
    // })
  }

  const changeHandler = (e) => {
    setInputValue(e.currentTarget.value)
  }

  const cancelHandler = () => {
    setIsEditing(false)
  }

  return (
    <EditableField 
      label="Project Name: "
      title="project_name"
      defaultValue={gridData?.project_name || ""}
      isEditing={isEditing}
      onEdit={editHandler}
      onSave={saveHandler}
      onCancel={cancelHandler}
      onChange={changeHandler}
    />
  )
}

export { ManageProjectNameField }
