import React, { useContext, useEffect, useRef, useState, Children } from "react"
import { useHistory } from "react-router"
import {
  Modal,
  TextInput,
  NumberInput,
  Message,
  CarouselButton,
  Spinner,
  InlineMessage,
} from "../../"
import { SessionContext } from "../../../App"
import { ModalContext } from "../../../Pages/ProjectWorkspace/WorkspaceTemplate"
import { api } from "../../../utils/api"
import { stringToBase64 } from "../../../utils/base64"

const NewProjectModal = () => {
  const defaultState = {
    project_name: null,
    relationship: null,
    tile_count: 0,
    souldier_count: 0,
    invites: [],
  }
  const [projectData, setProjectData] = useState({ ...defaultState })
  const [modalProgress, setModalProgress] = useState(1)
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  const [nameIsInvalid, setNameIsInvalid] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const emailInputContainer = useRef()
  const { session } = useContext(SessionContext)
  const { modalHidden, setModalHidden } = useContext(ModalContext)
  let history = useHistory()

  const modalDialogues = 6

  const wrapperClasses =
    "justify-content--center center flex flex-direction--column fade-in mx-24"
  const labelClasses = "mb-16 font-size--32 color-brand--primary"
  const inputClasses = "md:font-size--14 sm:font-size-12"

  const submitProjectData = () => {
    const input = document.querySelector("input:not(input[type='checkbox']")
    if (
      input.value &&
      Number(input.value) >= Number(projectData.souldier_count)
    ) {
      setModalProgress(6)
      fetch(api.newProject, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.token}`,
        },
        body: JSON.stringify({
          ...projectData,
          [input["title"]]: input.value,
          email: session.email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const { status, msg } = data
          if (status === 200) {
            setModalHidden(true)
            setProjectData({ ...defaultState })
            history.push(
              `/design/projects/${stringToBase64(
                projectData.project_name
              )}/${stringToBase64(session.email)}`
            )
          } else {
            setModalProgress(1)
            setStatus({
              visible: true,
              kind: "error",
              message: msg,
            })
          }
        })
        .catch((err) =>
          setStatus({
            visible: true,
            kind: "error",
            message:
              "Well that's strange. We've encountered an error trying to capture the information you provided.",
          })
        )
    } else {
      setStatus({
        visible: true,
        kind: "error",
        message: `Uh oh! That won't be enough tiles to go around. Make sure the number of tiles is greater than or equal to the number of contributors provided previously (${projectData.souldier_count})`,
      })
    }
  }

  const stepForward = () => {
    const hasErrors =
      document.querySelectorAll(".App-inline-message--error").length > 0
    if (hasErrors) {
      return
    }

    const input = document.querySelector("input:not(input[type='checkbox'])")
    // console.log(input.value)
    if (input.value) {
      let nextModal =
        modalProgress === 3 && input.value == 1 /* eslint-disable-line */
          ? modalProgress + 2
          : modalProgress + 1
      useStatus.visible &&
        setStatus({ visible: false, kind: undefined, message: undefined })
      setProjectData({ ...projectData, [input["title"]]: input.value })
      nextModal <= modalDialogues && setModalProgress(nextModal)
      // modalProgress === modalDialogues && submitProjectData()
    } else {
      setStatus({
        visible: true,
        kind: "warning",
        message: "Oops!  We need an answer before we can continue.",
      })
    }
  }

  const stepBack = () => {
    const hasErrors =
      document.querySelectorAll(".App-inline-message--error").length > 0
    if (hasErrors) {
      return
    }
    let prevModal =
      modalProgress === 5 &&
      projectData.souldier_count == 1 /* eslint-disable-line */
        ? modalProgress - 2
        : modalProgress - 1
    if (useStatus.visible) {
      setStatus({ visible: false, kind: undefined, message: undefined })
    }
    modalProgress > 1 && setModalProgress(prevModal)
  }

  const enterKeyHandler = (e) => {
    const hasErrors =
      document.querySelectorAll(".App-inline-message--error").length > 0
    if (hasErrors) {
      return
    }
    if (e.key === "Enter") {
      !modalHidden && modalProgress < 4
        ? stepForward()
        : modalProgress === 4
        ? inviteHandler()
        : submitProjectData()
    }
  }

  const inviteHandler = () => {
    let arr = []
    let nextModal = modalProgress + 1
    emailInputContainer.current
      .querySelectorAll("input")
      .forEach(({ value }) => {
        value && arr.push(value)
      })
    arr.length &&
      setProjectData({
        ...projectData,
        invites: [...arr],
      })
    setModalProgress(nextModal)
  }

  const validateName = (e) => {
    const nameContainsInvalidCharacters =
      /[!@#$%^&*()_+=[\]{}:;<,>.?/\\|~`]+/gi.test(e.target.value)
    if (nameContainsInvalidCharacters) {
      setNameIsInvalid(nameContainsInvalidCharacters)
    } else {
      nameIsInvalid && setNameIsInvalid(false)
    }
  }

  useEffect(() => {
    ;/mobile/gi.test(navigator.userAgent) && setIsMobile(true)
    window.addEventListener("resize", () => {
      const width = window.innerWidth
      setIsMobile(width < 900)
    })
    return () =>
      window.removeEventListener("resize", () => {
        const width = window.innerWidth
        setIsMobile(width < 900)
      })
  }, [setIsMobile])

  return (
    <Modal
      title="New Project"
      contentClassName="flex md:flex-direction--row sm:flex-direction--column justify-content--space-between"
    >
      {!isMobile && (
        <CarouselButton
          size="xtra-large"
          position="left"
          onClick={stepBack}
          className={`${
            modalProgress === 1
              ? `button-disabled button-disabled--svg`
              : `button-active button-active--svg`
          } p-24`}
        />
      )}
      <div
        className="flex flex-direction--column max-width--650 pb-32 overflow-y--auto"
        style={{ maxHeight: "60vh" }}
      >
        {!modalHidden && modalProgress === 1 && (
          <>
            <TextInput
              type="text"
              wrapperClasses={wrapperClasses}
              label="Give your project a name"
              labelClasses={labelClasses}
              title="project_name"
              placeholder="i.e. Soulfolio for Jane or Grandma's gift"
              defaultValue={projectData.project_name || ""}
              inputClasses={inputClasses}
              autoFocus={true}
              onKeyUp={enterKeyHandler}
              onChange={validateName}
            />
            {nameIsInvalid && (
              <InlineMessage
                kind="error"
                message={`Project names may only contain letters, numbers, hyphens ( - ), and apostrophes ( ' ).`}
                className="mt-16 w-90"
              />
            )}
          </>
        )}
        {modalProgress === 2 && (
          <TextInput
            type="text"
            wrapperClasses={wrapperClasses}
            label="This Soulfolio is for my...?"
            labelClasses={labelClasses}
            title="relationship"
            placeholder="i.e. Grandma, Friend, Niece, Uncle, etc."
            defaultValue={projectData.relationship || ""}
            inputClasses={inputClasses}
            autoFocus={true}
            onKeyUp={enterKeyHandler}
          />
        )}
        {modalProgress === 3 && (
          <NumberInput
            type="number"
            wrapperClasses={wrapperClasses}
            label="How many contributors are there (including yourself)?"
            labelClasses={labelClasses}
            title="souldier_count"
            placeholder="The more, the merrier :)"
            min={1}
            max={25}
            inputMode="numeric"
            pattern="[0-9]+"
            defaultValue={projectData.souldier_count || null}
            inputClasses={inputClasses}
            autoFocus={true}
            onKeyUp={enterKeyHandler}
            // ref={(el) => {
            //   el.select()
            // }}
          />
        )}
        {modalProgress === 4 && (
          <section ref={emailInputContainer}>
            {Children?.toArray(
              new Array(Number(projectData.souldier_count - 1))
                .fill(0)
                .map((item, index) => (
                  <>
                    <TextInput
                      type="text"
                      wrapperClasses={`mb-16 ${wrapperClasses}`}
                      label={
                        index === 0 ? "Invite your contributors" : undefined
                      }
                      labelClasses={labelClasses}
                      title="relationship"
                      placeholder="someone@awesome.com"
                      defaultValue={projectData.invites[index] || ""}
                      inputClasses={inputClasses}
                      autoFocus={index === 0}
                      onKeyUp={
                        index === projectData.souldier_count - 2
                          ? enterKeyHandler
                          : undefined
                      }
                    />
                    {index === Number(projectData.souldier_count - 2) && (
                      <div className="mx-24">
                        <Message kind="info" fixed>
                          <p
                            className={`App-message--text ml-24 font-size--11`}
                          >
                            To invite collaborators now, enter one email address
                            in each field above before clicking the right arrow
                            to proceed.
                          </p>
                          <p
                            className={`App-message--text ml-24 pt-8 font-size--11`}
                          >
                            To invite collaborators later, simply leave the
                            fields blank.
                          </p>
                        </Message>
                      </div>
                    )}
                  </>
                ))
            )}
          </section>
        )}
        {modalProgress === 5 && (
          <>
            <NumberInput
              type="number"
              wrapperClasses={wrapperClasses}
              label="How many tiles in this Soulfolio?"
              labelClasses={labelClasses}
              title="tile_count"
              placeholder="As few or as many as you like!"
              min={1}
              max={250}
              inputMode="numeric"
              pattern="[0-9]*"
              defaultValue={projectData.tile_count || null}
              inputClasses={inputClasses}
              autoFocus={true}
              onKeyUp={enterKeyHandler}
            />
            <div className="mx-24">
              <Message
                kind="info"
                message="1 tile can equal 1 year of life, of a relationship, of service, of employment, or something else. Get creative! How will you use your tiles?"
                fixed
              ></Message>
            </div>
          </>
        )}
        {modalProgress === 6 && (
          <>
            <Spinner text="Creating your project..." />
          </>
        )}
        <div className="mx-24">
          <Message
            kind={useStatus.kind}
            message={useStatus.message}
            state={[useStatus, setStatus]}
            className="w-full"
          ></Message>
        </div>
      </div>
      {!isMobile && (
        <CarouselButton
          size="xtra-large"
          position="right"
          className={`button-active button-active--svg p-24`}
          onClick={
            modalProgress < 4
              ? stepForward
              : modalProgress === 4
              ? inviteHandler
              : submitProjectData
          }
        />
      )}
      {isMobile && (
        <div className="flex flex-direction--row pt-24 justify-content--space-between align-content--center">
          <div>
            <CarouselButton
              size="xtra-large"
              position="left"
              onClick={stepBack}
              className={`${
                modalProgress === 1
                  ? `button-disabled button-disabled--svg`
                  : `button-active button-active--svg`
              } p-24`}
            />
          </div>
          <div>
            <CarouselButton
              size="xtra-large"
              position="right"
              onClick={
                modalProgress < 4
                  ? stepForward
                  : modalProgress === 4
                  ? inviteHandler
                  : submitProjectData
              }
              className={`button-active button-active--svg p-24`}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export { NewProjectModal }
