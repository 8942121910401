import React, { useEffect, useState, useContext } from "react"
import { Modal, Image, Message, TextInput, Button, Spinner } from "../.."
import { ProjectContext, SessionContext } from "../../../App"
import { ModalContext } from "../../../Pages/ProjectWorkspace"
import { api } from "../../../utils/api"
import { PlusIcon } from "../../Icons"
import "./ImageGalleryModal.css"

const ImageGalleryModal = ({
  setImageChoice,
  setActiveModal,
  closeHandler,
}) => {
  const [images, setImages] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [useStatus, setStatus] = useState({
    visible: false,
    kind: undefined,
    message: undefined,
  })
  // eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    session: { token, email },
    setSession,
  } = useContext(SessionContext)
  const { setModalHidden } = useContext(ModalContext)
  const { projectName, projectOwner } = useContext(ProjectContext)
  const [page, setPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const imagesPerPage = 500

  const fetchImages = () => {
    setIsLoading(true)
    fetch(api.getAllImages({ page, nPerPage: imagesPerPage }), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setImages(images ? [...images, ...data] : data)
        setSearchResults(searchResults ? [...searchResults, ...data] : data)
        setIsLoading(false)
        setIsFetching(false)
      })
  }

  useEffect(() => {
    ;/mobile/gi.test(navigator.userAgent) && setIsMobile(true)
    window.addEventListener("resize", () => {
      const width = window.innerWidth
      setIsMobile(width < 900)
    })

    fetchImages()

    return () =>
      window.removeEventListener("resize", () => {
        const width = window.innerWidth
        setIsMobile(width < 900)
      })
    // eslint-disable-next-line
  }, [])

  const localCloseHandler = () => {
    setModalHidden(true)
    closeHandler()
  }

  const addImageHandler =
    ({ image_name }) =>
    (e) => {
      fetch(
        api.addImageToProject({ image_name, projectName, projectOwner, email }),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then(({ status, msg }) => {
          if (status === 200) {
            setImageChoice(image_name)
            setModalHidden(true)
            setActiveModal(null)
          } else if (status === 406) {
            setStatus({
              visible: true,
              kind: "warning",
              message: msg,
            })
          } else {
            setStatus({
              visible: true,
              kind: "warning",
              message: msg,
            })
            setSession(null)
          }
        })
    }

  const filterByWord = (word) => (e) => {
    let results = [...images].filter(
      (a, b) =>
        (!a.isBack && a.metadata?.word === word) ||
        (!a.isBack && a.metadata?.keywords?.includes(word))
    )
    setSearchResults([...results])
  }

  const filterByKeyword = (e) => {
    let results = [...images]?.filter(
      (a, b) =>
        (!a.isBack &&
          new RegExp(`^${e.target.value}`, "gi").test(a.metadata?.word)) ||
        (!a.isBack &&
          a.metadata?.keywords?.find((k) =>
            new RegExp(`^${e.target.value}`, "gi").test(k)
          ))
    )
    setSearchResults([...results])
  }

  const renderSidebar = () => {
    const unique = {}
    return (
      <>
        <h1>Filter by Word</h1>
        <ul className="list-style--none pl-0">
          {images
            ?.filter(
              ({ metadata, isBack }) =>
                metadata &&
                !unique[metadata.word] &&
                !isBack &&
                (unique[metadata.word] = true)
            )
            .map(
              ({ metadata, isBack }, index) =>
                metadata?.word && (
                  <li key={`word-${index}`}>
                    <p
                      key={`word-p-${index}`}
                      className="faux-link paragraph paragraph--m"
                      onClick={(e) => {
                        filterByWord(metadata.word, isBack)(e)
                      }}
                    >
                      {metadata.word}
                    </p>
                  </li>
                )
            )}
        </ul>
      </>
    )
  }

  const resetSearch = () => setSearchResults(images)

  const galleryScrollListener = (e) => {
    const imageHeight = document.querySelector(".App-gallery--image-container")
        ?.parentElement?.offsetHeight,
      cols = Math.floor(e.target.offsetWidth / imageHeight),
      rows = Math.ceil((imagesPerPage * page) / cols),
      totalHeight = rows * imageHeight,
      scrollPosition = totalHeight - Math.floor(e.target.scrollTop),
      minOffsetHeight = e.target.offsetHeight - 1,
      maxOffsetHeight = e.target.offsetHeight + 1,
      scrollPositionIsAtTheBottom =
        scrollPosition >= minOffsetHeight && scrollPosition <= maxOffsetHeight

    if (scrollPositionIsAtTheBottom) {
      setPage(page + 1)
      setIsFetching(true)
    }
  }

  useEffect(() => {
    isFetching && fetchImages()
    // eslint-disable-next-line
  }, [isFetching])

  return (
    <Modal
      title="Image Gallery"
      closeHandler={localCloseHandler}
      dialogClassName="App-gallery--modal"
    >
      {useStatus.visible && (
        <div className="flex">
          <Message
            className="mb-24"
            kind={useStatus.kind}
            message={useStatus.message}
            state={[useStatus, setStatus]}
          ></Message>
        </div>
      )}
      <section className="overflow-y--auto">
        <section className="flex sm:flex-direction--column md:flex-direction--row align-items--center justify-content--space-between pb-24">
          <TextInput
            title="Search"
            label="Search &amp; Filter"
            type="text"
            placeholder="Type a word to begin searching..."
            wrapperClasses="flex sm:flex-direction--column align-items--center md:px-8 flex-grow-1 sm:w-full"
            labelClasses="md:mr-16 sm:mb-8"
            inputClasses="w-full md:mr-16 box-sizing--border-box"
            onKeyUp={filterByKeyword}
            disabled={!!!searchResults}
          />
          <Button
            className="sm:mt-16 sm:w-full box-sizing--border-box"
            onClick={resetSearch}
          >
            Reset
          </Button>
        </section>
        <section className="flex md:flex-direction--row sm:flex-direction--column">
          <div>
            <div className="App-gallery--sidebar px-16">
              {images && renderSidebar()}
            </div>
          </div>
          <div
            onScroll={galleryScrollListener}
            className="flex align-items--center flex-wrap justify-content--space-around w-full App-gallery--collection-window"
          >
            {searchResults &&
              searchResults.map(
                ({ image_name, isBack, canEdit, isPublic, isCover }, index) =>
                  !isBack &&
                  !isCover &&
                  isPublic && (
                    <div className="relative" key={`image-container-${index}`}>
                      <Image
                        key={`${image_name}-${index}`}
                        containerClass="App-gallery--image-container m-16"
                        className="App-gallery--image"
                        src={api.getImageBySize({
                          isPz: canEdit,
                          size: "small",
                          image_name,
                        })}
                      >
                        <div className="App-gallery--image-overlay"></div>
                        <div className="App-gallery--image-tools align-self--center">
                          <div className="flex flex-direction--row flex-wrap justify-content--space-evenly">
                            <PlusIcon
                              className="faux-link image-tool m-8"
                              size="xtra-large"
                              onClick={(e) => {
                                addImageHandler({ image_name })(e)
                              }}
                            />
                            {/* <HeartIcon id="heart-icon" className="faux-link image-tool m-8" size="large" /> */}
                          </div>
                        </div>
                      </Image>
                      {canEdit && (
                        <p className="App-gallery--image-badge">
                          Change my word!
                        </p>
                      )}
                    </div>
                  )
              )}
            {isLoading && (
              <div className="m-16">
                <Spinner />
              </div>
            )}
          </div>
        </section>
      </section>
    </Modal>
  )
}

export { ImageGalleryModal }
