import React from 'react'
import { IconContainer } from './IconContainer'

const ErrorIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size} >
      <path fill={fill} d="M50,3.5C24.3,3.5,3.5,24.3,3.5,50S24.3,96.5,50,96.5S96.5,75.7,96.5,50S75.7,3.5,50,3.5z M73.1,72.7L71.8,74
	c-0.7,0.7-1.6,1.1-2.6,1.1c-1.1,0-2.2-0.5-3.1-1.4l-16-16l-16,16c-0.9,0.9-2,1.4-3.1,1.4c-1,0-1.9-0.4-2.6-1.1l-1.4-1.4
	c-1.5-1.5-1.4-4.1,0.3-5.7L44.1,50L27.2,33.1c-1.7-1.7-1.8-4.2-0.3-5.8l1.4-1.4c0.7-0.7,1.6-1.1,2.6-1.1c1.1,0,2.3,0.5,3.1,1.4
	l16,16l16-16c0.9-0.9,2-1.4,3.1-1.4c1,0,1.9,0.4,2.6,1.1l1.4,1.4c1.5,1.5,1.4,4.1-0.3,5.8L55.9,50l16.9,16.9
	C74.5,68.6,74.7,71.2,73.1,72.7z"/>
    </IconContainer>
  )
}

export { ErrorIcon }