import React, { useState, useContext } from "react"
import {
  TextInput,
  IconButton,
  Toast,
  Spinner,
  ConfirmationModal,
  Button,
} from "../.."
import { SessionContext, ProjectContext } from "../../../App"
import { ModalContext } from "../../../Pages/ProjectWorkspace"
import { api } from "../../../utils/api"
import {
  AddUserIcon,
  SendEmailIcon,
  TrashIcon,
  SuccessIcon,
  RemoveUserIcon,
} from "../../Icons"
import "./ManageContributorField.css"
import { clsnx } from "@becks256/clsnx"

const ManageContributorField = ({
  index,
  item,
  setInviteSent,
}) => {
  //eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(item)
  const [contributorEmail, setContributorEmail] = useState(item.email || undefined)
  const [sendingInvite, setSendingInvite] = useState(false)
  const [displayToast, setDisplayToast] = useState({
    visible: false,
    message: "",
    kind: undefined,
  })
  const [activeModal, setActiveModal] = useState()

  const { session } = useContext(SessionContext)
  const { setModalHidden } = useContext(ModalContext)
  const { refreshGridData, projectName } = useContext(ProjectContext)

  const TOAST_DURATION = 5000

  const Modals = {
    REVOKE_ACCESS: 0,
    DELETE_CONTRIBUTOR: 1,
  }

  const sendInviteHandler = (e) => {
    const inviteStatus = e.currentTarget.getAttribute("data-status")
    const url = api.inviteUserToProject({
      inviteEmail: contributorEmail,
      projectName,
      ownerEmail: session.email,
    })
    setSendingInvite(true)
    fetch(`${inviteStatus ? `${url}?status=resend` : url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDisplayToast({
          visible: true,
          message: data.msg,
          kind: data.status !== 200 ? "error" : "success",
        })
        setSendingInvite(false)
      })
      .then(() => {
        setTimeout(() => {
          setDisplayToast({ visible: false, message: "" })
          setInviteSent(contributorEmail)
          refreshGridData()
        }, TOAST_DURATION)
      })
  }

  const revokeAccessHandler = (e) => {
    if (contributorEmail && contributorEmail !== session.email) {
      fetch(
        api.revokeUserAccess({
          email: contributorEmail,
          projectName,
          owner: session.email,
        }),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            setModalHidden(true)
            setActiveModal(null)
            setDisplayToast({
              visible: true,
              message: `${contributorEmail} no longer has access to this soulfolio`,
              kind: "success",
            })
            refreshGridData()
          }
        })
        .then(() => {
          setTimeout(() => {
            setDisplayToast({ visible: false, message: "" })
            setContributorEmail(undefined)
          }, TOAST_DURATION)
        })
    }
  }

  const deleteContributorAndRedistributeImagesHandler = () => {
    fetch(
      api.deleteContributor({
        email: contributorEmail,
        projectName,
        projectOwner: session.email,
        redist: true,
      }),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setModalHidden(true)
          setActiveModal(null)
          setDisplayToast({
            visible: true,
            message:
              "We've successfully deleted a contributor from this project and redistributed their tiles to the remaining contributors.",
            kind: "success",
          })
          refreshGridData()
        }
      })
      .then(() => {
        setTimeout(() => {
          setDisplayToast({ visible: false, message: "" })
        }, TOAST_DURATION)
      })
  }

  const deleteContributorAndImagesHandler = () => {
    fetch(
      api.deleteContributor({
        email: contributorEmail,
        projectName,
        projectOwner: session.email,
        redist: false,
      }),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setModalHidden(true)
          setActiveModal(null)
          setDisplayToast({
            visible: true,
            message:
              "We've successfully deleted a contributor from this project and reduced the number of tiles.",
            kind: "success",
          })
          refreshGridData()
        }
      })
      .then(() => {
        setTimeout(() => {
          setDisplayToast({ visible: false, message: "" })
        }, TOAST_DURATION)
      })
  }

  const inviteChangeHandler = (e) => {
    setContributorEmail(e.target.value)
  }

  const confirmDeleteContributor = (e) => {
    setActiveModal(Modals.DELETE_CONTRIBUTOR)
    setModalHidden(false)
  }

  const confirmRevokeAccess = (e) => {
    setActiveModal(Modals.REVOKE_ACCESS)
    setModalHidden(false)
  }

  const closeHandler = () => {
    setActiveModal(null)
    setModalHidden(true)
  }

  const WhichIcon =
    (data.email && !data.invite_accepted)
      ? SendEmailIcon
      : (data.email && data.invite_accepted)
        ? SuccessIcon
        : AddUserIcon

  return (
    <>
      {activeModal === Modals.DELETE_CONTRIBUTOR && (
        <ConfirmationModal
          title={"Delete Contributor Confirmation"}
          prompt={
            <>
              WARNING: Performing this action will completely remove this
              contributor from the project, reducing the total number of
              contributors by one.
              <p className="paragraph paragraph-m">
                You can choose below if you want to redistribute the tiles
                assigned to this contributor, or delete them. Deleting them will
                reduce the total number of tiles in your project, but no tiles
                assigned to current users will be affected.
              </p>
            </>
          }
          DeleteButton={() => (
            <Button
              className="m-16 font-size--12 bg-error--70"
              onClick={deleteContributorAndImagesHandler}
            >
              Delete
            </Button>
          )}
          CancelButton={() => (
            <Button
              className="m-16 font-size--12"
              onClick={deleteContributorAndRedistributeImagesHandler}
            >
              Redistribute
            </Button>
          )}
          closeHandler={closeHandler}
        />
      )}
      {activeModal === Modals.REVOKE_ACCESS && (
        <ConfirmationModal
          title={"Revoke Access Confirmation"}
          prompt={
            <>
              Are you sure you want to revoke{" "}
              <span className="text-decoration--underline">
                {contributorEmail}'s
              </span>{" "}
              access to this project?
              <p className="paragraph paragraph-m">
                Any images they added will remain in the project and the invitee
                who replaces them will inherit their work.
              </p>
            </>
          }
          DeleteButton={() => (
            <Button
              className="m-16 bg-error--70 font-size--12"
              onClick={revokeAccessHandler}
            >
              Revoke
            </Button>
          )}
          CancelButton={() => (
            <Button className="m-16 font-size--12" onClick={closeHandler}>
              Cancel
            </Button>
          )}
          closeHandler={closeHandler}
        />
      )}
      <section
        key={`text-input-container-${index}`}
        className="flex sm:flex-direction--column flex-direction--row justify-content--space-evenly sm:mt-24 box-sizing--border-box lg:w-50 w-full px-16"
      >
        {displayToast.visible && (
          <Toast kind={displayToast.kind} message={displayToast.message} />
        )}
        <TextInput
          key={`text-input-${index}`}
          id={`email_${index}`}
          type="text"
          wrapperClasses="flex sm:flex-direction--column flex-direction--row align-items--center pt-16 w-full"
          inputClasses="w-full box-sizing--border-box"
          labelClasses="md:mr-16 white-space--nowrap sm:mb-8 App-project-mgmt-label"
          label={`Contributor ${index + 1}:`}
          title={`email_${index}`}
          placeholder={
            data.invite_accepted
              ? contributorEmail
              : contributorEmail
              ? `${contributorEmail} has not accepted`
              : "Invite a user"
          }
          onChange={inviteChangeHandler}
        />
        <section
          key={`button-container-${index}`}
          className="flex flex-direction--row align-items--center pt-16 md:ml-16 sm:w-full justify-content--space-evenly"
        >
          <IconButton
            className={clsnx("m-8", {
              "App-special-button":
                (contributorEmail && !data.invite_accepted) ||
                (!contributorEmail && !data.invite_accepted),
              "App-positive-button success":
                contributorEmail && data.invite_accepted,
            })}
            data-status={contributorEmail && !data.invite_accepted ? 1 : 0}
            title={`${
              contributorEmail && !data.invite_accepted
                ? "Send Invite Email"
                : contributorEmail && data.invite_accepted
                ? "User Accepted"
                : "Invite New User"
            }`}
            disabled={data.invite_accepted || !contributorEmail}
            onClick={sendInviteHandler}
          >
            {sendingInvite ? (
              <Spinner size="tiny" text="" />
            ) : (
              <WhichIcon
                size="medium"
                className="box-sizing--border-box App-icon-button--icon"
              />
            )}
          </IconButton>
          <IconButton
            className="App-callout-button m-8"
            title="Revoke Access"
            disabled={!contributorEmail}
            onClick={confirmRevokeAccess}
          >
            <RemoveUserIcon
              size="medium"
              className="box-sizing--border-box App-icon-button--icon"
            />
          </IconButton>
          <IconButton
            className="App-negative-button m-8"
            title="Delete Contributor"
            onClick={confirmDeleteContributor}
          >
            <TrashIcon
              size="medium"
              className="box-sizing--border-box App-icon-button--icon"
            />
          </IconButton>
        </section>
      </section>
    </>
  )
}

export { ManageContributorField }
