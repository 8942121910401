import React from "react"
import "./Button.css"

/**
 *
 * @param {Object} props
 * @param {Object} props.children - the jsx to be wrapped by the component
 * @param {boolean} props.compact - specifies if the button should be styled as a compact button
 * @param {string} props.className - the class names to apply to the button
 * @returns a button
 */
const Button = ({ compact, className, children, border, ...props }) => {
  return (
    <>
      <button
        className={`App-button App-button--${
          compact ? "compact" : border ? "border" : "standard"
        } ${className || ""}`}
        {...props}
      >
        {children}
      </button>
    </>
  )
}

export { Button }
