import React, { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../../App"
import { api } from "../../../utils/api"
import { Image } from "../../../Components"

const MyUploadsPage = () => {
  const { session } = useContext(SessionContext)
  const [uploads, setUploads] = useState([])

  const { email, token } = session

  useEffect(() => {
    fetch(api.getImagesByUser({ user: email }), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(({ data }) => {
        console.log(data)
        setUploads(data)
      })
  }, [])

  return uploads.length ? (
    <div
      className="flex flex-wrap justify-content--center p-64"
      style={{ gap: "1rem" }}
    >
      {uploads.map(({ image_name }) => (
        <Image key={image_name} src={api.getImageBySize({ image_name })} />
      ))}
    </div>
  ) : (
    <p>No uploads found</p>
  )
}

export { MyUploadsPage }
