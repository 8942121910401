import React, { useState } from "react"
import { classname } from "../../utils/utils"
import "./Tabs.css"
/**
 *
 * @param {Array} tabs an array of objects containing a name and content, which should contain child objects to render as the tab content
 * @returns
 */

const Tabs = ({ tabs = [], defaultTab, status, tabStyle = "button" }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    defaultTab ? tabs.findIndex((i) => i.name === defaultTab) : 0
  )

  const tabClickHandler = (e) => {
    const name = e.currentTarget.getAttribute("name")
    setActiveTabIndex(tabs.findIndex((i) => i.name === name))
  }

    return (
      <section className="flex flex-direction--column">
        <section className={classname("mb-16",{
          "App-tabs--tab-bar": tabStyle === "button",
          "App-project-grid--tab-bar": tabStyle === "tab"
        })}>
          {tabs.map((tab, index) => (
            <span
              id={`tab-${index}`}
              key={`tab-${index}`}
              name={tab.name}
              onClick={tabClickHandler}
              className={classname({
                "App-tabs--tab": tabStyle === "button",
                "App-project-grid--tab": tabStyle === "tab",
                "active": tab.name === tabs[activeTabIndex].name
              })}
            >
              {tab.name}
            </span>
          ))}
        </section>
        <section>{tabs[activeTabIndex].content}</section>
      </section>
    )
}

export { Tabs }
