import React from 'react'
import { IconContainer } from './IconContainer'

const ConfigIcon = ({ fill, size, className, multiGear, ...props }) => { 
	return (
		<IconContainer {...props} className={className} size={size} >
			{multiGear
				? <>
					<path d="M76.3,95.3c0.9-0.1,1.5-0.9,1.5-1.8l-0.5-5.4c0-0.2,0.1-0.4,0.3-0.5c1.4-0.6,2.8-1.5,4-2.4c0.2-0.1,0.4-0.2,0.6,0l4.5,3
						c0.8,0.4,1.7,0.2,2.3-0.5l3.4-4.6c0.5-0.7,0.4-1.7-0.2-2.3l-4.2-3.5c-0.2-0.2-0.2-0.4-0.1-0.6c0.6-1.4,0.9-3,1.1-4.5
						c0-0.2,0.2-0.4,0.4-0.5l5.3-1.1c0.9-0.2,1.4-1.1,1.3-1.9l-0.8-5.7c-0.1-0.9-0.9-1.5-1.8-1.5l-5.4,0.5c-0.2,0-0.4-0.1-0.5-0.3
						c-0.6-1.4-1.5-2.8-2.4-4c-0.1-0.2-0.2-0.4,0-0.6l3-4.5c0.4-0.8,0.2-1.7-0.5-2.3L83,46.7c-0.7-0.5-1.7-0.4-2.3,0.2L77.2,51
						c-0.2,0.2-0.4,0.2-0.6,0.1c-1.4-0.6-3-0.9-4.5-1.1c-0.2,0-0.4-0.2-0.5-0.4l-1.1-5.3c-0.2-0.9-1.1-1.4-1.9-1.3l-5.7,0.8
						c-0.9,0.1-1.5,0.9-1.5,1.8l0.5,5.4c0,0.2-0.1,0.4-0.3,0.5c-1.4,0.6-2.8,1.5-4,2.4c-0.2,0.1-0.4,0.2-0.6,0l-4.5-3
						c-0.8-0.4-1.7-0.2-2.3,0.5l-3.4,4.6c-0.5,0.7-0.4,1.7,0.2,2.3l4.2,3.5c0.2,0.2,0.2,0.4,0.1,0.6c-0.6,1.4-0.9,3-1.1,4.5
						c0,0.2-0.2,0.4-0.4,0.5l-5.3,1.1c-0.9,0.2-1.4,1.1-1.3,1.9l0.8,5.7c0.1,0.9,0.9,1.5,1.8,1.5l5.4-0.5c0.2,0,0.4,0.1,0.5,0.3
						c0.6,1.4,1.5,2.8,2.4,4c0.1,0.2,0.2,0.4,0,0.6l-3,4.5c-0.4,0.8-0.2,1.7,0.5,2.3l4.6,3.4c0.7,0.5,1.7,0.4,2.3-0.2l3.5-4.2
						c0.2-0.2,0.4-0.2,0.6-0.1c1.4,0.6,3,0.9,4.5,1.1c0.2,0,0.4,0.2,0.5,0.4l1.1,5.3c0.2,0.9,1.1,1.4,1.9,1.3L76.3,95.3z M70.1,82
						c-5.8,0.3-11.2-3.7-12.6-9.4C55.6,65,61.1,57.5,69,57.2c5.8-0.3,11.2,3.7,12.6,9.4C83.5,74.2,78,81.6,70.1,82z"/>
					<path d="M43.9,4.1l-4.2,1.6c-0.6,0.3-1,0.9-0.8,1.6l1.3,4c0,0.2,0,0.4-0.2,0.5c-1,0.7-1.9,1.6-2.6,2.5c-0.1,0.1-0.3,0.2-0.5,0.1
						l-4-1.5c-0.7-0.2-1.4,0.1-1.6,0.8l-1.8,4.1c-0.3,0.6,0,1.4,0.6,1.7l3.8,1.9c0.2,0.1,0.2,0.3,0.2,0.5c-0.2,1.2-0.2,2.5-0.1,3.7
						c0,0.2-0.1,0.4-0.2,0.4l-3.9,1.8c-0.6,0.3-0.9,1.1-0.6,1.7l1.6,4.2c0.3,0.6,0.9,1,1.6,0.8l4-1.3c0.2,0,0.4,0,0.5,0.2
						c0.7,1,1.6,1.9,2.5,2.6c0.1,0.1,0.2,0.3,0.1,0.5l-1.5,4c-0.2,0.7,0.1,1.4,0.8,1.6l4.1,1.8c0.6,0.3,1.4,0,1.7-0.6l1.9-3.8
						c0.1-0.2,0.3-0.2,0.5-0.2c1.2,0.2,2.5,0.2,3.7,0.1c0.2,0,0.4,0.1,0.4,0.2l1.8,3.9c0.3,0.6,1.1,0.9,1.7,0.6l4.2-1.6
						c0.6-0.3,1-0.9,0.8-1.6l-1.3-4c0-0.2,0-0.4,0.2-0.5c1-0.7,1.9-1.6,2.6-2.5c0.1-0.1,0.3-0.2,0.5-0.1l4,1.5c0.7,0.2,1.4-0.1,1.6-0.8
						l1.8-4.1c0.3-0.6,0-1.4-0.6-1.7l-3.8-1.9c-0.2-0.1-0.2-0.3-0.2-0.5c0.2-1.2,0.2-2.5,0.1-3.7c0-0.2,0.1-0.4,0.2-0.4l3.9-1.8
						c0.6-0.3,0.9-1.1,0.6-1.7l-1.6-4.2c-0.3-0.6-0.9-1-1.6-0.8l-4,1.3c-0.2,0-0.4,0-0.5-0.2c-0.7-1-1.6-1.9-2.5-2.6
						c-0.1-0.1-0.2-0.3-0.1-0.5l1.5-4c0.2-0.7-0.1-1.4-0.8-1.6l-4.1-1.8c-0.6-0.3-1.4,0-1.7,0.6l-1.9,3.8c-0.1,0.2-0.3,0.2-0.5,0.2
						c-1.2-0.2-2.5-0.2-3.7-0.1c-0.2,0-0.4-0.1-0.4-0.2l-1.8-3.9C45.3,4.1,44.5,3.9,43.9,4.1z M53.2,17.3c3.2,1.8,4.7,5.7,3.6,9.2
						c-1.5,4.7-7,6.9-11.3,4.4c-3.2-1.8-4.7-5.7-3.6-9.2C43.4,17,48.8,14.9,53.2,17.3z"/>
					<path d="M16.4,40c-0.6,0.1-1,0.6-0.9,1.1l0.4,3.4c0,0.1-0.1,0.3-0.2,0.3c-0.9,0.4-1.8,1-2.5,1.6c-0.1,0.1-0.3,0.1-0.4,0l-2.9-1.9
						c-0.5-0.3-1.1-0.1-1.4,0.3l-2.1,3c-0.3,0.5-0.3,1.1,0.2,1.5L9,51.6c0.1,0.1,0.1,0.3,0.1,0.4c-0.3,0.9-0.6,1.9-0.7,2.9
						c0,0.1-0.1,0.3-0.3,0.3l-3.4,0.7c-0.5,0.2-0.9,0.7-0.8,1.2l0.6,3.6c0.1,0.6,0.6,1,1.1,0.9l3.4-0.4c0.1,0,0.3,0.1,0.3,0.2
						c0.4,0.9,1,1.8,1.6,2.5c0.1,0.1,0.1,0.3,0,0.4l-1.9,2.9c-0.3,0.5-0.1,1.1,0.3,1.4l3,2.1c0.5,0.3,1.1,0.3,1.5-0.2l2.2-2.7
						c0.1-0.1,0.3-0.1,0.4-0.1c0.9,0.3,1.9,0.6,2.9,0.7c0.1,0,0.3,0.1,0.3,0.3l0.7,3.4c0.2,0.5,0.7,0.9,1.2,0.8l3.6-0.6
						c0.6-0.1,1-0.6,0.9-1.1l-0.4-3.4c0-0.1,0.1-0.3,0.2-0.3c0.9-0.4,1.8-1,2.5-1.6c0.1-0.1,0.3-0.1,0.4,0l2.9,1.9
						c0.5,0.3,1.1,0.1,1.4-0.3l2.1-3c0.3-0.5,0.3-1.1-0.2-1.5L32.7,61c-0.1-0.1-0.1-0.3-0.1-0.4c0.3-0.9,0.6-1.9,0.7-2.9
						c0-0.1,0.1-0.3,0.3-0.3l3.4-0.7c0.5-0.2,0.9-0.7,0.8-1.2l-0.6-3.6c-0.1-0.6-0.6-1-1.1-0.9l-3.4,0.4c-0.1,0-0.3-0.1-0.3-0.2
						c-0.4-0.9-1-1.8-1.6-2.5c-0.1-0.1-0.1-0.3,0-0.4l1.9-2.9c0.3-0.5,0.1-1.1-0.3-1.4l-3-2.1c-0.5-0.3-1.1-0.3-1.5,0.2l-2.2,2.7
						c-0.1,0.1-0.3,0.1-0.4,0.1c-0.9-0.3-1.9-0.6-2.9-0.7c-0.1,0-0.3-0.1-0.3-0.3l-0.7-3.4c-0.2-0.5-0.7-0.9-1.2-0.8L16.4,40z M20.6,51.4
						c2.3-0.1,4.5,1.4,5,3.6c0.8,3-1.3,6-4.5,6.2c-2.3,0.1-4.5-1.4-5-3.6C15.3,54.5,17.4,51.6,20.6,51.4z"/>
					</> : <>
					<path d="M62.6,98.4c1.6-0.2,2.8-1.7,2.8-3.3l-1-10.2c0-0.4,0.2-0.8,0.6-1c2.7-1.2,5.3-2.7,7.6-4.5c0.3-0.3,0.8-0.3,1.2-0.1l8.5,5.7
					c1.4,0.8,3.3,0.5,4.3-0.9l6.5-8.7c1-1.3,0.8-3.2-0.4-4.3l-7.9-6.5c-0.3-0.3-0.4-0.8-0.3-1.2c1-2.7,1.8-5.6,2.2-8.6
					c0.1-0.4,0.4-0.8,0.8-0.9l10-2c1.6-0.4,2.6-2,2.4-3.6l-1.6-10.7c-0.2-1.6-1.7-2.8-3.3-2.8l-10.2,1c-0.4,0-0.8-0.2-1-0.6
					c-1.2-2.7-2.7-5.3-4.5-7.6C79,27,79,26.5,79.2,26.1l5.7-8.5c0.8-1.4,0.5-3.3-0.9-4.3l-8.7-6.5c-1.3-1-3.2-0.8-4.3,0.4l-6.5,7.9
					c-0.3,0.3-0.8,0.4-1.2,0.3c-2.7-1-5.6-1.8-8.6-2.2c-0.4-0.1-0.8-0.4-0.9-0.8l-2-10c-0.4-1.6-2-2.6-3.6-2.4L37.4,1.6
					c-1.6,0.2-2.8,1.7-2.8,3.3l1,10.2c0,0.4-0.2,0.8-0.6,1c-2.7,1.2-5.3,2.7-7.6,4.5C27,21,26.5,21,26.1,20.8l-8.5-5.7
					c-1.4-0.8-3.3-0.5-4.3,0.9l-6.5,8.7c-1,1.3-0.8,3.2,0.4,4.3l7.9,6.5c0.3,0.3,0.4,0.8,0.3,1.2c-1,2.7-1.8,5.6-2.2,8.6
					c-0.1,0.4-0.4,0.8-0.8,0.9l-10,2c-1.6,0.4-2.6,2-2.4,3.6l1.6,10.7c0.2,1.6,1.7,2.8,3.3,2.8l10.2-1c0.4,0,0.8,0.2,1,0.6
					c1.2,2.7,2.7,5.3,4.5,7.6c0.3,0.3,0.3,0.8,0.1,1.2l-5.7,8.5c-0.8,1.4-0.5,3.3,0.9,4.3l8.7,6.5c1.3,1,3.2,0.8,4.3-0.4l6.5-7.9
					c0.3-0.3,0.8-0.4,1.2-0.3c2.7,1,5.6,1.8,8.6,2.2c0.4,0.1,0.8,0.4,0.9,0.8l2,10c0.4,1.6,2,2.6,3.6,2.4L62.6,98.4z M51,73.4
					c-11,0.5-21-6.9-23.7-17.6C23.7,41.3,34.1,27.3,49,26.6c11-0.5,21,6.9,23.7,17.6C76.3,58.7,65.9,72.7,51,73.4z"/>
				</>
			}
		</IconContainer>
	)
}

export { ConfigIcon }