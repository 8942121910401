import React from "react"
import "./TextInput.css"

const TextInput = ({
  className,
  label,
  title,
  inputClasses,
  wrapperClasses,
  labelClasses,
  children,
  inline,
  inlineContainerClasses,
  wrapped = true,
  compact,
  ...props
}) => {
  const blockJSX = (
    <>
      {label && (
        <label className={labelClasses || ""} htmlFor={title}>
          {label}
        </label>
      )}
      <input
        {...props}
        id={
          props.id ||
          (label && label?.replace(/\s/g, "-").toLowerCase()) ||
          undefined
        }
        title={title}
        className={`App-text-field${compact ? " compact " : ""}${
          inputClasses ? ` ${inputClasses}` : ""
        }`}
        aria-label={title}
      />
      {children}
    </>
  )

  const inlineJSX = (
    <>
      {label && (
        <label className={labelClasses || ""} htmlFor={title}>
          {label}
        </label>
      )}
      <div
        className={
          inlineContainerClasses ||
          "flex flex-direction--row justify-content--space-between flex--grow"
        }
      >
        <input
          {...props}
          id={
            props.id ||
            (label && label?.replace(/\s/g, "-").toLowerCase()) ||
            undefined
          }
          title={title}
          className={`App-text-field ${inputClasses || ""}`}
          aria-label={title}
        />
        {children}
      </div>
    </>
  )
  return wrapped ? (
    <div className={wrapperClasses || ""}>{inline ? inlineJSX : blockJSX}</div>
  ) : inline ? (
    inlineJSX
  ) : (
    blockJSX
  )
}

export { TextInput }
