import React from 'react'
import { IconContainer } from './IconContainer'

const WarningIcon = (props) => {
  const { fill, size, className, ...other } = props
  return (
    <IconContainer {...other} className={className} size={size} >
      <circle fill={fill} cx="50" cy="50" r="46.5"/>
      <path fill="#555" d="M56,23.5c0-1.2-0.4-2.3-1.2-3c-0.7-0.7-1.5-1-2.5-1h-4.7c-2.1,0-3.7,1.8-3.7,4.1l1.9,32.7c0,1.2,0.5,2.3,1.2,3
        c0.6,0.6,1.5,1,2.5,1h0.9c2,0,3.7-1.8,3.7-4.1L56,23.5z"/>
      <circle fill="#555" cx="50" cy="75.5" r="5.1"/>
    </IconContainer>
  )
}

export { WarningIcon }